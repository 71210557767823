import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useAction, useTranslate } from "@helpers/hooks";
import { List, Divider, CourseButton } from "@components/common";
import { SectionSidebarItem } from "@components/feature";
import "./SectionSidebar.scss";

import { COURSE_SECTION_ROUTE } from "@utils/consts";

export default function SectionSidebar() {
  const { t } = useTranslate(),
    { course } = useSelector((state) => state.courses),
    { courseId } = useParams(),
    { lessonId } = useParams(),
    { sectionId } = useParams(),
    { fetchCourse } = useAction(),
    { pathname } = useLocation(),
    section = course?.sections?.find((section) => section.id == sectionId);

  useEffect(() => {
    fetchCourse(courseId);
  }, [courseId]);

  return (
    <aside className={"section__sidebar"}>
      <nav className={"section__sidebar__nav"}>
        <div className="section__sidebar__container">
          <h3 className="section__sidebar__title">{course && course.title}</h3>
          <CourseButton
            className={"section__sidebar__back-button"}
            route={COURSE_SECTION_ROUTE.replace(":courseId", courseId).replace(
              ":sectionId",
              sectionId
            )}
            icon={"pagination-arrow-left"}
            name={t("LMS.BACK_TO_COURSE_MATERIAL")}
          />
        </div>
        <Divider className={"section__sidebar__divider"} />
        {section && (
          <List
            className={"section__sidebar__list"}
            items={section.lessons}
            renderItem={(lesson) => (
              <li key={lesson.id} className={"sidebar__item"}>
                <SectionSidebarItem
                  lesson={lesson}
                  courseId={courseId}
                  sectionId={sectionId}
                  lessonId={lessonId}
                  pathname={pathname}
                />
              </li>
            )}
          />
        )}
      </nav>
    </aside>
  );
}
