import { Button, Icon, Image, Modal } from "@components/common";
import { accompaniment } from "@utils/consts";
import { useTranslate } from "@helpers/hooks";
import "./ProfileSupportModal.scss";

export default function ProfileSupportModal({ open, setOpen }) {
  const { t } = useTranslate();
  return (
    <Modal
      className={"support-modal"}
      width={1112}
      open={open}
      setOpen={setOpen}
      isClose={true}
      isStart={true}
    >
      <div className="support-modal__div-modal">
        <div className="support-modal__main">
          <div className="support-modal__div-1">
            <div className="support-modal__logo">
              <Image
                name="logo_stp_small_white.png"
                alt="Logo STP"
                className={"support-modal__logo-img"}
              />
              <Image
                name="support_lines.png"
                alt="Lines"
                className={"support-modal__lines"}
              />
            </div>
            <div className="support-modal__title">{t("SUPPORT.TITLE")}</div>
            <div className="support-modal__subtitle">
              {t("SUPPORT.DESCRIPTION")}
            </div>
          </div>
          <div className="support-modal__div-2">
            {accompaniment.map((item, index) => (
              <div
                key={index + "-" + item.id}
                className="support-modal__bullets"
              >
                {" "}
                <div>
                  <Icon name={"lock-white"} />
                </div>
                <div>{item.text}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="support-modal__buy">
          <div className="support-modal__buy-text">
          {t("SUPPORT.TEXT")}
          </div>
          <div className="support-modal__buy-price-info">
            <div className="support-modal__buy-info">всего за</div>
            <div className="support-modal__buy-price">5000 ₸ </div>
          </div>
          <div>
            <div className="support-modal__button-div">
              <Button
                text={"Получить полное сопровождение"}
                className="btn--primary support-modal__button"
              />
              <Image
                name="support_lines2.png"
                alt="Lines2"
                className={"support-modal__button-lines"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="support-modal__circle">
        <Image name={"half_circle.png"} alt="half circle" />
      </div>
      <div className="support-modal__stars">
        <Image name={"stars.png"} alt="stars" />
      </div>
    </Modal>
  );
}
