import { Icon, Image, NewModalLayout } from "@components/common";
import "./ChallengeCard.scss";
import { ChallengeModal } from "../ChallengeModal";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CHALLENGES_ROUTE_ONGOING } from "@utils/consts";

export default function ChallengeCard({ challenge, completed }) {
  const [isOpen, setIsOpen] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const history = useHistory();

  const handleChallengeRedirect = (id) => {
    history.push(CHALLENGES_ROUTE_ONGOING.replace(":challengeId", id));
  };

  useEffect(() => {
    if (challenge?.start_time) {
      const date = new Date(challenge.start_time);
      const options = {
        weekday: "long",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      };

      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
        date
      );

      setStartTime(formattedDate);
    }
  }, [challenge]);

  const hasEnded = new Date(challenge?.end_time).getTime() < Date.now();

  return (
    <>
      {isOpen && (
        <ChallengeModal
          setIsOpen={setIsOpen}
          challenge={challenge}
          nextClick={handleChallengeRedirect}
        />
      )}
      <div className="challenge__card">
        <div className="challenge__card__image">
          <Image
            src={challenge?.image}
            name={"illustration.png"}
            alt="Challenges"
            className="challenge__card__image__img"
          />
          {challenge?.completed && (
            <div className="challenge__card__image__overlay"></div>
          )}
        </div>
        <div className="challenge__card__info">
          <div className="challenge__card__time">
            <div className="challenge__card__type">
              {challenge.challenge_topic}
            </div>
            <div className="challenge__card__start">
              <Icon
                name={completed ? "challenged_completed" : "challenge_calendar"}
              />
              <div>{completed ? "Completed" : startTime}</div>
            </div>
          </div>
          <div className="challenge__card__topic">
            <div className="challenge__card__title">
              <div className="challenge__card__text">{challenge.title}</div>
              <div className="challenge__card__prize">
                <div>Prize: {challenge?.prize[0]}</div>
                <div>
                  {" "}
                  <Image
                    name="coin.png"
                    className={"challenge__card__prize__img"}
                  ></Image>
                </div>
              </div>
            </div>
            <div
              className="challenge__card__button"
              onClick={() => {
                !hasEnded ? setIsOpen(true) : handleChallengeRedirect(challenge?.id)
              }}
            >
              <Icon name="challenge_arrow" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
