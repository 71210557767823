import { Button, Icon, Image, Modal, NewModalLayout } from "@components/common";
import "./ChallengeModal.scss";
import { useState } from "react";
import { useEffect } from "react";
import ReactMarkdown from "react-markdown";
export default function ChallengeModal({
  isOpen,
  setIsOpen,
  challenge,
  nextClick,
}) {
  const [content, setContent] = useState([
    { name: "Start time", text: "" },
    { name: "Duration", text: "" },
    { name: "Topics", text: "" },
    {
      name: "Description",
      text: "",
    },
  ]);

  const [prizes, setPrizes] = useState([
    { place: "1 place", prize: "" },
    { place: "2 place", prize: "" },
    { place: "3 place", prize: "" },
    { place: "Participate", prize: "" },
  ]);

  const [timeLeft, setTimeLeft] = useState(null);
  const hasStarted = new Date(challenge?.start_time).getTime() < Date.now();
  const hasEnded = new Date(challenge?.end_time).getTime() < Date.now();

  function formatTimer(timerStr) {
    const [hours, minutes] = timerStr.split(":").map(Number);

    const formattedHours =
      hours > 0 ? `${hours} ${hours === 1 ? "hour" : "hours"}` : "";
    const formattedMinutes =
      minutes > 0 ? `${minutes} ${minutes === 1 ? "minute" : "minutes"}` : "";

    return `${formattedHours}${
      formattedHours && formattedMinutes ? " " : ""
    }${formattedMinutes}`;
  }

  useEffect(() => {
    if (challenge) {
      setContent([
        {
          name: "Start time",
          text: challenge?.start_time
            ? formatDate(challenge?.start_time, false)
            : "",
        },
        {
          name: "Duration",
          text: challenge?.timer ? formatTimer(challenge?.timer) : "",
        },
        { name: "Topics", text: challenge?.challenge_topic },
        {
          name: "Description",
          text: challenge?.description,
        },
      ]);

      if (challenge.prize) {
        setPrizes([
          { place: "1 place", prize: challenge.prize[0] || "0" },
          { place: "2 place", prize: challenge.prize[1] || "0" },
          { place: "3 place", prize: challenge.prize[2] || "0" },
          { place: "Participate", prize: challenge.prize[3] || "0" },
        ]);
      }
    }
  }, [challenge]);

  useEffect(() => {
    if (challenge?.start_time) {
      const calculateTimeLeft = () => {
        const startTime = new Date(challenge.start_time).getTime();
        const now = Date.now();
        const difference = startTime - now;

        if (difference > 0) {
          return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
          };
        } else {
          return 0;
        }
      };

      setTimeLeft(calculateTimeLeft());

      const timer = setInterval(() => {
        const newTimeLeft = calculateTimeLeft();
        setTimeLeft(newTimeLeft);

        if (!newTimeLeft) {
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [challenge, challenge?.start_time]);

  const formatTimeLeft = () => {
    if (!timeLeft) return "Time is up!";

    const { days, hours, minutes, seconds } = timeLeft;

    return (
      `${days ? `${days}d ` : ""}${hours ? `${hours}h ` : ""}${
        minutes ? `${minutes}m ` : ""
      }${seconds ? `${seconds}s` : ""}` || "0s"
    );
  };

  function formatDate(endTime, isEnd) {
    const date = new Date(endTime);
    if (isEnd) date.setMinutes(date.getMinutes() + 1);

    const options = {
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  }
  return (
    <NewModalLayout className="challenge__modal">
      <div className="challenge__modal__title">
        <div>{challenge?.title}</div>
        <div
          onClick={() => {
            setIsOpen(false);
          }}
          className="challenge__modal__click"
        >
          <Icon name={"big-close"} />
        </div>
      </div>
      {challenge?.completed ? (
        <div className="challenge__modal__results">
          <div>
            The challenge results will open on{" "}
            {formatDate(challenge?.end_time, true)}!{" "}
          </div>{" "}
          <div className="challenge__modal__no-results">
            <Button
              text={"OK"}
              className="challenge__modal__button__content"
              onClick={() => setIsOpen(false)}
            />
          </div>
        </div>
      ) : (
        <div className="challenge__modal__body">
          <div className="challenge__modal__content">
            <div className="challenge__modal__text">
              {content?.map((c, index) => {
                return (
                  <div key={index}>
                    <span className="challenge__modal__text__bold">
                      {c.name}:
                    </span>
                    <span className="challenge__modal__text-markdown">
                      <ReactMarkdown>{c.text}</ReactMarkdown>
                    </span>
                  </div>
                );
              })}
            </div>
            {hasStarted ? (
              <div className="challenge__modal__button">
                <Button
                  text={"Start weekly challenge"}
                  className="challenge__modal__button__content"
                  onClick={() => nextClick(challenge?.id)}
                />
              </div>
            ) : (
              <div className="challenge__modal__button-timer">
                Starts in {formatTimeLeft()}
              </div>
            )}
          </div>
          <div className="challenge__modal__prize">
            <div className="challenge__modal__prize__title">Prize</div>
            <div className="challenge__modal__prizes">
              {prizes?.map((p, index) => (
                <div key={index} className="challenge__modal__prizes__content">
                  <div>{p.place}</div>{" "}
                  <div className="challenge__modal__prizes__coins">
                    <span>{p.prize}</span>
                    <Image
                      name={"coin.png"}
                      className={"challenge__card__prize__img"}
                    ></Image>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </NewModalLayout>
  );
}
