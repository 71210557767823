import {Fragment} from "react";
import {useSelector} from "react-redux";
import {useFormik} from "formik";
import {useAction, useData, useTranslate} from "@helpers/hooks";
import {passwordSchema} from "@utils/schemas";
import {Button, Form, Input, ValidationError} from "@components/common";
import "../EmailModal.scss";

export default function EmailConfirm({setOpen, next}) {
    const {t} = useTranslate(),
        {updateProfile} = useAction(),
        {data, setValues} = useData();

    const onSubmit = async (values) => {
        setValues(values);
        const res = await updateProfile({email: data.email, password: values.password});
        if (!res.error) {
            handleReset(initialValues);
            next();
            resetForm();
        }
    };

    const {initialValues, values, errors, touched, handleChange, handleSubmit, handleBlur, isSubmitting, handleReset, resetForm} = useFormik({
        initialValues: {
            password: "",
            confirmPassword: ""
        },
        validateOnBlur: true,
        validationSchema: passwordSchema,
        onSubmit
    });

    const initialInputs = [
        {id: 1, label: t("SETTINGS.CURRENT_PASSWORD"), name: "password", value: values.password, maxLength: 20},
        {id: 2, label: t("SETTINGS.REPEAT_PASSWORD"), name: "confirmPassword", value: values.confirmPassword, maxLength: 20}
    ], buttons = [
        {id: 1, text: t("BUTTON.CANCEL"), className: "btn--link", type: "button", handleClick: () => setOpen(false)},
        {id: 2, text: t("BUTTON.APPLY"), className: "btn--primary btn--pill", type: "submit", loading: isSubmitting}
    ];
    return <Form onSubmit={handleSubmit}>
        {
            initialInputs.map((input) => <Fragment key={input.id}>
                <Input
                    className={"input--ghost password-modal__input"}
                    labelClassName={"label--ghost"}
                    name={input.name}
                    type={"password"}
                    label={input.label}
                    placeholder={t("FIELD.ENTER_PASSWORD")}
                    readOnly={input.readOnly}
                    maxLength={input.maxLength}
                    value={input.value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <ValidationError
                    errors={errors}
                    touched={touched}
                    name={input.name}
                />
            </Fragment>)
        }
        <div className={"email-modal__btn"}>
            {
                buttons.map((button) => (
                    <Button
                        key={button.id}
                        className={button.className}
                        type={button.type}
                        text={button.text}
                        width={143}
                        height={46}
                        loading={button.loading}
                        onClick={button.handleClick}
                    />
                ))
            }
        </div>
    </Form>;
};