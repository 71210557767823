import { NavLink } from "react-router-dom";
import { Icon } from "@components/common";
import classNames from "classnames";

export default function CourseSidebarAccordionNavLink({
  handleAccordionClick,
  route,
  courseId,
  isPathnameAccordion,
  icon,
  name,
  showArrow,
  isAccordionOpen,
}) {
  return (
    <NavLink
      onClick={handleAccordionClick}
      to={`${route.replace(":courseId", courseId)}`}
      className={classNames("sidebar__link-arrow", {
        "sidebar__link--selected": isPathnameAccordion,
      })}
    >
      <div className="sidebar__link">
        <div className="sidebar__icon">
          <Icon name={icon} />
        </div>
        <p
          className={classNames("sidebar__text", {
            "sidebar__text--selected": isPathnameAccordion,
          })}
        >
          {name}
        </p>
      </div>
      {showArrow && (
        <Icon
          className={"icon--dark"}
          name={isAccordionOpen ? "menu-arrow-up" : "menu-arrow-down"}
        />
      )}
    </NavLink>
  );
}
