import {createAsyncThunk} from "@reduxjs/toolkit";
import {QuestionnaireService} from "@api/services";

const fetchMyEducation = createAsyncThunk(
    "education/fetchMy",
    async (_, thunkAPI) => {
        try {
            return await QuestionnaireService.fetchMyEducation();
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const updateEducation = createAsyncThunk(
    "education/update",
    async (data, thunkAPI) => {
        try {
            return await QuestionnaireService.updateEducation(data);
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const fetchMyPrefer = createAsyncThunk(
    "prefer/fetchMy",
    async (_, thunkAPI) => {
        try {
            return await QuestionnaireService.fetchMyPrefer();
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const updatePrefer = createAsyncThunk(
    "prefer/update",
    async ({id, data}, thunkAPI) => {
        try {
            return await QuestionnaireService.updatePrefer(id, data);
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const fetchMyAdditionalInfo= createAsyncThunk(
    "additionalInfo/fetchMy",
    async (_, thunkAPI) => {
        try {
            return await QuestionnaireService.fetchMyAdditionalInfo();
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const updateAdditionalInfo = createAsyncThunk(
    "additionalInfo/update",
    async ({id, data}, thunkAPI) => {
        try {
            return await QuestionnaireService.updateAdditionalInfo(id, data);
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const createAdditionalInfo = createAsyncThunk(
    "additionalInfo/create",
    async (data, thunkAPI) => {
        try {
            return await QuestionnaireService.createAdditionalInfo(data);
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const deleteAdditionalInfo = createAsyncThunk(
    "additionalInfo/delete",
    async (id, thunkAPI) => {
        try {
            await QuestionnaireService.deleteAdditionalInfo(id)
            return id;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const fetchUniversitiesRecommend = createAsyncThunk(
    "universitiesRecommend/fetch",
    async (_, thunkAPI) => {
        try {
            return await QuestionnaireService.fetchUniversitiesRecommend();
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const updateUniversitiesRecommend = createAsyncThunk(
    "universitiesRecommend/update",
    async (_, thunkAPI) => {
        try {
            return await QuestionnaireService.updateUniversitiesRecommend();
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

export {
    createAdditionalInfo,
    deleteAdditionalInfo,
    fetchMyEducation,
    fetchMyPrefer,
    fetchMyAdditionalInfo,
    fetchUniversitiesRecommend,
    updateAdditionalInfo,
    updateEducation,
    updatePrefer,
    updateUniversitiesRecommend
};