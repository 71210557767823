import React from "react";
import { Button, CustomLink, Icon } from "@components/common";
import { useIsMobile, useTranslate } from "@helpers/hooks";
import { WHATSAPP_CHALLENGE } from "@utils/consts";
import { ChallengeTimer } from "@components/feature";
import "./ChallengeHeader.scss";

export default function ChallengeHeader({
  challenge,
  endTime,
  handleSubmit,
  isChallengeCompleted,
  handleRedirect,
  userResponses,
  questionsData,
  score = 0,
  challengeCompleted = null,
  finishChallenge = null,
}) {
  const { t } = useTranslate();
  const isMobile = useIsMobile();
  
  const scoreMax = 100;
  let totalScore = score;
  const totalQuestionsExcludingImages = questionsData?.filter(
    (question) => question?.type !== "question_images"
  ).length;

  const handleButtonClick = () => {
    isChallengeCompleted ? handleRedirect() : handleSubmit();
  };

  return (
    <header className="mock__header">
      <nav className="mock__header-nav">
        {isMobile && (
          <Icon name={"arrow-back"} handleClick={handleButtonClick} />
        )}
        <h3 className="mock__ongoing-title">{challenge && challenge.title}</h3>
        {!isChallengeCompleted && (
          <ChallengeTimer
            endTime={endTime}
            handleSubmit={handleSubmit}
            completed={challengeCompleted}
            finishChallenge={finishChallenge}
          />
        )}

        <div className="mock__indicators">
          {!isMobile && totalScore !== undefined && (
            <div className="mock__progress">
              {!isChallengeCompleted
                ? `${userResponses?.length} / ${totalQuestionsExcludingImages}`
                : `${t("PROFILE.POINT_EMPTY")} ${totalScore}/${scoreMax}`}
            </div>
          )}

          {isChallengeCompleted && (
            <CustomLink
              className={"ongoing__button"}
              isExternal={true}
              newTab={true}
              to={WHATSAPP_CHALLENGE}
            >
              {t("MOCK.CONTACT")}
            </CustomLink>
          )}

          {!isMobile && (
            <div className="mock__buttons">
              <Button
                className="btn--primary mock__btn"
                text={t(
                  isChallengeCompleted
                    ? "MOCK.FINISH_REVIEW"
                    : "MOCK.SUBMIT_EXAM"
                )}
                onClick={handleButtonClick}
              />
            </div>
          )}
        </div>
      </nav>
    </header>
  );
}
