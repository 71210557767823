import { useMemo, useState, memo } from "react";
import { Icon, Image, List, Toggle } from "@components/common";
import { useSelector } from "react-redux";
import { useAction, useOutsideClick, useTranslate } from "@helpers/hooks";
import { useHistory } from "react-router-dom";
import { SETTINGS_ROUTE, STP_OPEN_SIDE } from "@utils/consts";
import { logout } from "@utils/functions";
import classNames from "classnames";
import { HeaderCoins } from "@components/feature";
import "./HeaderProfile.scss";

export default memo(function HeaderProfile({ handleFeedback, handleCoins }) {
  const { t } = useTranslate();

  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const { profile } = useSelector((state) => state.profile);
  const { isAdminMode } = useSelector((state) => state.client);
  const { setAdminMode } = useAction();

  const isTeacher = profile?.is_teacher;

  const history = useHistory();

  const turnOffAdminMode = () => {
    setAdminMode(false);
  };

  const turnOnAdminMode = () => {
    setAdminMode(true);
  };

  const onToggleDropdown = (e) => {
    e.stopPropagation();
    setIsProfileOpen((isProfileOpen) => !isProfileOpen);
  };

  const ref = useOutsideClick(() => setIsProfileOpen(false));

  const links = useMemo(
    () => [
      {
        id: 4,
        name: t("HEADER.PROFILE.FEEDBACK"),
        image: "lamp_feedback.png",
        isExternal: true,
        handleClick: () => handleFeedback(true),
      },
      {
        id: 5,
        name: t("HEADER.PROFILE.COINS"),
        icon: "coin-menu",
        isExternal: true,
        handleClick: handleCoins,
      },
      {
        id: 1,
        name: t("HEADER.PROFILE.MY_PROFILE"),
        icon: "settings-menu",
        handleClick: () => history.push(SETTINGS_ROUTE),
      },
      {
        id: 2,
        name: t("HEADER.PROFILE.HELP"),
        icon: "help-menu",
        handleClick: () => {
          window.open(`${STP_OPEN_SIDE}/faq/`, "_blank");
        },
      },
      {
        id: 3,
        name: t("HEADER.PROFILE.LOGOUT"),
        icon: "exit",
        isExternal: true,
        handleClick: logout,
      },
      ...(isTeacher
        ? [
            {
              id: 6,
              name: t("HEADER.PROFILE.ADMIN"),
              isToggle: true,
            },
          ]
        : []),
    ],
    [t, isTeacher]
  );

  return (
    <div className={"header__settings"} onClick={onToggleDropdown} ref={ref}>
      <div className="header__title">
        <p className="header__title-name">{profile?.name}</p>
        <HeaderCoins />
      </div>
      {profile.profile_photo ? (
        <Image src={profile.profile_photo} className="header__photo" />
      ) : (
        <div className="header__photo">
          <p className="header__photo--placeholder">
            {profile.name && profile.name[0]}
            {profile.surname && profile.surname[0]}
          </p>
        </div>
      )}
      <div className={"details__title"}>
        {isProfileOpen && (
          <List
            className={"details__dropdown"}
            items={links}
            renderItem={(link) => (
              <li
                key={link.id}
                className={classNames("details__item", {
                  "toggle-item": link.isToggle,
                })}
              >
                {link.icon && <Icon name={link.icon} />}
                {link.image && (
                  <Image name={link.image} className="details__image" />
                )}
                <p className="details__link" onClick={link.handleClick}>
                  {link.name}
                </p>
                {link.isToggle && isTeacher && (
                  <div className="header__admin-toggle">
                    <Toggle
                      onLeftClick={turnOffAdminMode}
                      onRightClick={turnOnAdminMode}
                      initialToggle={isAdminMode}
                    />
                  </div>
                )}
              </li>
            )}
          />
        )}
      </div>
    </div>
  );
});
