import { useMemo } from "react";
import { Button, NewModalLayout, PageTitle, List, Input, Dropdown, Form, ValidationError } from "@components/common";
import { useTranslate, useIsMobile, useAction } from "@helpers/hooks";
import {useSelector} from "react-redux";
import {useFormik} from "formik";
import { collectInfoSchema } from "@utils/schemas";
import { STUDY_LEVELS, GENDERS, GRADUATION_YEARS, CURRENT_STUDY_YEARS, IELTS_SCORES } from "@utils/consts";
import "./DashboardCollectInfoModal.scss";

export default function DashboardCollectInfoModal() {
  const { t } = useTranslate();
  const isMobile = useIsMobile();
  const { profile } = useSelector((state) => state.profile);
  const { updateProfile, updateEducation } = useAction();
  const onSubmit = async (values) => {
    try {
        const updateProfileRes = await updateProfile({
          name: values.name,
          surname: values.surname,
          gender: values.gender,
        });
        if (!!updateProfileRes.error) {
            return;
        }
        await updateEducation({
            study_level: values.study_level,
            study_place: values.study_place,
            current_study_year: values.current_study_year,
            graduation_year: values.graduation_year,
            // sat_math_score: values.sat_math_score,
            // sat_verbal_score: values.sat_verbal_score,
            // ielts_score: values.ielts_score
        });
    } catch (e) {
        console.log(e.message);
    }
  };
  const {values, errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting} = useFormik({
    // Need initial values for all fields to do validation on button click when all fields are not touched
    // Default values must be of the same type as the fields in the schema, but have invalid values, e.g. "" or -1
    initialValues: {
      name: profile.name || "",
      surname: profile.surname || "",
      gender: "",
      study_level: "",
      study_place: "",
      current_study_year: -1,
      graduation_year: -1,
    },
    validationSchema: collectInfoSchema(t),
    validateOnBlur: true,
    onSubmit
  });
  const INITIAL_ITEMS = useMemo(() => [
    {
      id: 0,
      label: `${t("PROFILE.NAME")}*`,
      placeholder: t("PROFILE.NAME"),
      field: "input",
      name: "name",
      type: "text",
    },
    {
      id: 1,
      label: `${t("PROFILE.SURNAME")}*`,
      placeholder: t("PROFILE.SURNAME"),
      field: "input",
      name: "surname",
      type: "text",
    },
    {
      id: 2,
      label: `${t("PROFILE.GENDER")}*`,
      placeholder: t("PROFILE.GENDER"),
      field: "select",
      options: GENDERS(t),
      name: "gender",
    },
    {
      id: 3,
      label: `${t("PROFILE.EDUCATIONS.CURRENT_STUDY_LEVEL")}*`,
      placeholder: t("PROFILE.EDUCATIONS.CURRENT_STUDY_LEVEL_SHORT"),
      field: "select",
      options: STUDY_LEVELS(t),
      name: "study_level",
    },
    {
      id: 4,
      label: `${t("PROFILE.EDUCATIONS.STUDY_PLACE")}*`,
      placeholder: t("PROFILE.EDUCATIONS.STUDY_PLACE"),
      field: "input",
      name: "study_place",
      type: "text",
    },
    {
      id: 5,
      label: `${t("PROFILE.EDUCATIONS.CURRENT_STUDY_YEAR")}*`,
      placeholder: t("PROFILE.EDUCATIONS.CURRENT_STUDY_YEAR_SHORT"),
      field: "select",
      options: CURRENT_STUDY_YEARS,
      name: "current_study_year",
    },
    {
      id: 6,
      label: `${t("PROFILE.EDUCATIONS.GRADUATION_YEAR")}*`,
      placeholder: t("PROFILE.EDUCATIONS.GRADUATION_YEAR"),
      field: "select",
      options: GRADUATION_YEARS,
      name: "graduation_year",
    },
    // {
    //   id: 7,
    //   label: t("PROFILE.EDUCATIONS.SAT_MATH_SCORE"),
    //   placeholder: t("PROFILE.EDUCATIONS.SAT_MATH_SCORE"),
    //   field: "input",
    //   name: "sat_math_score",
    //   type: "number",
    //   step: 10,
    //   min: 0,
    //   max: 800,
    // },
    // {
    //   id: 8,
    //   label: t("PROFILE.EDUCATIONS.SAT_VERBAL_SCORE"),
    //   placeholder: t("PROFILE.EDUCATIONS.SAT_VERBAL_SCORE"),
    //   field: "input",
    //   name: "sat_verbal_score",
    //   type: "number",
    //   step: 10,
    //   min: 0,
    //   max: 800,
    // },
    // {
    //   id: 9,
    //   label: t("PROFILE.EDUCATIONS.IELTS_SCORE"),
    //   placeholder: t("PROFILE.EDUCATIONS.IELTS_SCORE"),
    //   field: "select",
    //   options: IELTS_SCORES,
    //   name: "ielts_score",
    // }
  ], [t]);

  return (
    <NewModalLayout
      className={`${isMobile ? `collect-info__pop-up-mobile` : `pop-up collect-info__pop-up`}`}
    >
      <Form onSubmit={handleSubmit}>
        <div className="collect-info__header">
          <PageTitle title={t("PROFILE.ENTER_COLLECT_INFO")} />
        </div>
        <div className={isMobile ? "collect-info__group-mobile" : "collect-info__group"}>
          <div className={isMobile ? "collect-info__inputs-mobile" : "collect-info__inputs"}>
            <div className={"collect-info-fields"}>
              <List
                className={isMobile ? "collect-info-fields__list-mobile" :"collect-info-fields__list"}
                items={INITIAL_ITEMS}
                renderItem={(item) => (
                  item.field === "select" ? (<li key={item.id}>
                    <Dropdown
                      key={item.id}
                      className="dropdown--ghost"
                      labelClassName={"label--ghost"}
                      label={item.label}
                      options={item.options}
                      name={item.name}
                      placeholder={item.placeholder}
                      value={values[item.name]}
                      handleChange={(e) => {
                        // quick hack
                        const event = {
                          target: {
                            name: item.name,
                            value: e.value,
                          },
                        };
                        handleChange(event)
                      }}
                      onBlur={(e) => {
                        const event = {
                          target: {
                            name: item.name,
                            value: e.target.value,
                          },
                        };
                        handleBlur(event);
                      }}
                    />
                    <ValidationError
                      errors={errors}
                      touched={touched}
                      name={item.name}
                    />
                  </li>) : (<li key={item.id}>
                    <Input
                      key={item.id}
                      className={"input--ghost"}
                      labelClassName={"label--ghost"}
                      label={item.label}
                      name={item.name}
                      type={item.type}
                      placeholder={item.placeholder}
                      value={values[item.name]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      step={item.step}
                      min={item.min}
                      max={item.max}
                    />
                    <ValidationError
                      errors={errors}
                      touched={touched}
                      name={item.name}
                    />
                  </li>)
                )}
              />
            </div>
          </div>
        </div>
        <Button
          type="submit"
          loading={isSubmitting}
          text={t("BUTTON.SAVE")}
          className="btn--secondary btn--pill btn__profile"
        />
      </Form>
    </NewModalLayout>
  );
}
