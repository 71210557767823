import { useTranslate } from "@helpers/hooks";
import {
  Button,
  Checkbox,
  Dropdown,
  Icon,
  Input,
  Label,
  Radio,
  RadioWithInput,
  Select,
  SelectChips,
} from "@components/common";
import classNames from "classnames";
import "./FormItem.scss";
import { useState } from "react";
import { useEffect } from "react";
import { DtPicker } from "react-calendar-datetime-picker";
import "react-calendar-datetime-picker/dist/style.css";

export default function FormItem({
  item,
  handleChange,
  disabled,
  isDisabled,
  wordcount = 0,
}) {
  const { t } = useTranslate();
  const [date, setDate] = useState(new Date());
  const [calendarOpen, setCalendarOpen] = useState(false);

  useEffect(() => {
    if (item.field === "calendar") {
      handleChange(date);
      setCalendarOpen(false);
    }
  }, [date]);

  if (item.field === "chips") {
    return <SelectChips item={item} handleChange={handleChange} />;
  }

  if (item.field === "select") {
    return (
      <li key={item.id} className={"education__item"}>
        <Select
          className={"education__select select--border select--small"}
          labelClassName={"label--ghost"}
          label={t(item.label)}
          placeholder={t(item.placeholder)}
          defaultValue={item.defaultValue}
          options={item.options}
          handleChange={(e) => handleChange(e, item.field, item.name)}
        />
      </li>
    );
  }

  if (item.field === "radio") {
    return (
      <li key={item.id} className={"radio__body"}>
        <Label className={"label_v2"} htmlFor={""} label={t(item.label)} />
        <p className={"radio__helper"}>{t(item.placeholder)}</p>
        <div className={"radio__options"}>
          {item.options.map((option) => (
            <Radio
              key={option.id}
              id={option.id}
              name={option.name}
              label={t(option.label)}
              checked={item?.value === option?.value}
              value={option?.value}
              onChange={(e) => handleChange(e, item.field, item.name)}
            />
          ))}
        </div>
      </li>
    );
  }

  if (item.field === "dropdown") {
    return (
      <li key={item.id} className={""}>
        <Dropdown
          className={`${item.isChance && "dropdown__chance"} dropdown`}
          label={t(item.label)}
          labelClassName={"label__v2"}
          defaultValue={item.defaultValue}
          handleChange={(e) =>
            handleChange(e, item.field, item.name, item.options)
          }
          name={item.name}
          options={item.options}
          icon={item.icon}
          method={item.method}
          isSearch={item.isSearch}
        />
      </li>
    );
  }

  if (item.field === "radio-with-input") {
    return (
      <li key={item.id} className={"radio__body"}>
        <p className={"radio__helper"}>{t(item.placeholder)}</p>
        <div className={"radio-with-input__options"}>
          {item.options.map((option) => {
            return (
              <RadioWithInput
                key={option.id}
                id={option.id}
                name={option.name}
                label={t(option.label)}
                checked={
                  item.subname
                    ? !!item?.value.find(
                        (el) => el.exam_type === option.value
                      ) || false
                    : option.value === option.value
                }
                value={option.value}
                onChange={(e) =>
                  handleChange(
                    e,
                    item.field,
                    item.name,
                    item.options,
                    item.subname
                  )
                }
                input_type={option.input_type}
                input_name={option.input_name}
                input_placeholder={option.input_placeholder || ""}
                input_value={option.input_value}
                inputonchange={option.inputonchange}
                input_min={option.input_min}
                input_max={option.input_max}
                input_step={option.input_step}
                input_onkeypress={option.input_onkeypress}
                input_validaterange={option.input_validaterange}
                input_maxlength={option.input_maxlength}
              />
            );
          })}
        </div>
      </li>
    );
  }

  // quiz components

  if (item.field === "radio-quiz") {
    return (
      <li key={item.id} className={"radio__body"}>
        <Label className={"label__v3"} htmlFor={""} label={t(item.label)} />
        <div className={"radio__options-quiz"}>
          {item.options.map((option) => (
            <Radio
              labelClassName={`radio__text-quiz`}
              key={option}
              id={`${item.id} ${option}`}
              name={item.name}
              label={option}
              checked={item?.value === option}
              value={option}
              onChange={handleChange}
              disabled={disabled}
              isCorrect={option === item?.correctAnswer}
            />
          ))}
        </div>
      </li>
    );
  }

  if (item.field === "truefalse-quiz") {
    return (
      <li key={item.id} className={"truefalse__body"}>
        <Label className={"label__v3"} htmlFor={""} label={t(item.label)} />
        <p className={"truefalse__helper"}>{t(item.placeholder)}</p>
        <div className={"radio__options-quiz"}>
          {item.options.map((option, index) => (
            <Radio
              labelClassName={`radio__text-quiz-results`}
              key={index}
              id={`${item.id}-${index}`}
              name={item.id}
              label={option ? "True" : "False"}
              checked={item?.value === option}
              value={option}
              onChange={handleChange}
              disabled={disabled}
              isCorrect={option === item?.correctAnswer}
            />
          ))}
        </div>
      </li>
    );
  }

  if (item.field === "checkbox-quiz") {
    return (
      <li key={item.id} className={"radio__body"}>
        <Label className={"label__v3"} htmlFor={""} label={t(item.label)} />
        <p className={"radio__helper"}>{t(item.placeholder)}</p>
        <div className={"radio__options-quiz"}>
          {item?.options?.map((option, index) => (
            <Checkbox
              labelClassName={`checkbox__text-quiz`}
              key={option}
              id={`${item.id} ${option}`}
              name={option}
              label={option}
              checked={
                Array.isArray(item?.value) && item?.value?.includes(option)
              }
              value={option.value}
              onChange={() => handleChange(option)}
              disabled={disabled}
              isCorrect={item?.correctAnswer?.includes(option)}
            />
          ))}
        </div>
      </li>
    );
  }

  if (item.field === "text-quiz") {
    return (
      <li key={item.id} className={"text__body"}>
        <div className="text__body-wrapper">
          <div className="text__body-label">
            <Label className={"label__v3"} htmlFor={""} label={t(item.label)} />
            {Array.isArray(item.correctAnswer) &&
              item.correctAnswer.some(
                (answer) => answer.trim().toLowerCase() === item.value.trim().toLowerCase()
                ) && ( <Icon name="correct_circle" className="correct-answer" />
            )}
          </div>
          <Input
            inputClassName="input__field-quiz"
            className={item.boxWidth && "input-box"}
            labelClassName={"label__v2"}
            type="text"
            name={item.name}
            placeholder={t(item.placeholder)}
            value={item?.value ?? ""}
            onChange={(e) => handleChange(e, item.field, item.name)}
            isTextArea={item.isTextArea}
            disabled={disabled}
          />
        </div>
        {item.correctAnswer && (
          <p className="correct-answer-text">
            {t("MOCK.CORRECT_ANSWER")}
            {item?.correctAnswer[0]}
          </p>
        )}
      </li>
    );
  }

  if (item.field === "long-text-quiz") {
    return (
      <li key={item.id} className={"text__body"}>
        <Label className={"label__v3"} htmlFor={""} label={t(item.label)} />
        <Input
          inputClassName="input__field-quiz-long"
          labelClassName={"label__v2"}
          type="text"
          name={item.name}
          placeholder={t(item.placeholder)}
          value={item?.value ?? ""}
          onChange={(e) => handleChange(e, item.field, item.name)}
          isLongText={true}
          isTextArea={true}
          disabled={disabled}
          symbol={wordcount}
        />
      </li>
    );
  }

  if (item.field === "table-quiz") {
    return (
      <li key={item.id} className={"table__body"}>
        <Label
          className={"table__row-label"}
          htmlFor={""}
          label={t(item.label)}
        />
        <div className={"table__options"}>
          {item.options.map((option) => (
            <Radio
              labelClassName={`radio__text-quiz`}
              key={option}
              id={`${item.id} ${option}`}
              name={item.name}
              checked={item?.value === option}
              value={option}
              onChange={handleChange}
              disabled={disabled}
              isCorrect={option === item?.correctAnswer}
            />
          ))}
        </div>
      </li>
    );
  }

  if (item.field === "calendar") {
    return (
      <div className="calendar__date">
        <div className="calendar__date--text">Date</div>
        <div className="calendar__div">
          {" "}
          <div className="calendar__calendar">
            <DtPicker
              onChange={setDate}
              withTime={true}
              showTimeInput={true}
              placeholder="Select"
              inputClass={"calendar__input"}
              yearListStyle={"grid"}
              daysClass={"calendar__day"}
              type="single"
              nextMonthBtnTitle="nextt"
              headerClass={"calendar__header"}
              calenderModalClass={"calendar__modal"}
            />
          </div>
        </div>
      </div>
    );
  }

  if (item.field === "radio-with-input") {
    // write your code here
  }

  // quiz components

  return (
    <li key={item.id} className={""}>
      <Input
        inputClassName={item.inputClassName}
        className={`${item.className} ${item.boxWidth && "input-box"}`}
        labelClassName={classNames("label__v2", item.labelClassName)}
        definition={item.definition}
        type={item.type}
        label={t(item.label)}
        name={item.name}
        placeholder={t(item.placeholder)}
        min={item.min}
        max={item.max}
        step={item.step}
        maxLength={item.maxLength}
        disabled={isDisabled || item.isDisabled}
        value={item?.value ?? ""}
        onChange={(e) => handleChange(e, item.field, item.name)}
        onKeyPress={item.onKeyPress}
        validateRange={item.validateRange}
        isTextArea={item.isTextArea}
      />
    </li>
  );
}
