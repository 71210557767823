import { useRef } from "react";
import { useAction, useIsMobile, useTranslate } from "@helpers/hooks";
import { useSelector } from "react-redux";
import { Button, Icon } from "@components/common";
import "./UploadProfilePhoto.scss";

const UploadProfilePhoto = ({}) => {
  const isMobile = useIsMobile();
  const { profile } = useSelector((state) => state.profile);
  const { t } = useTranslate(),
    { updateProfile } = useAction(),
    fileInputRef = useRef(null);

  const handleClickChangePhoto = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("profile_photo", file);
    await updateProfile(formData);
  };

  const handleDeletePhoto = async () => {
    await updateProfile({ profile_photo: null });
  };

  return (
    <div className="profile_avatar">
      {isMobile && <label className="label__text">Фото профиля</label>}
      <div className="profile_avatar_edit">
        {profile.profile_photo ? (
          <img className="avatar" src={profile.profile_photo} alt="avatar" />
        ) : (
          <div className="profile_avatar_placeholder_wrapper">
            <p className="profile_avatar_placeholder_text">
              {profile.name && profile.name[0]}
              {profile.surname && profile.surname[0]}
            </p>
          </div>
        )}
        <div className="profile_avatar_buttons">
          <label htmlFor="file-input">
            <input
              ref={fileInputRef}
              id="file-input"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
            <Button
              onClick={handleClickChangePhoto}
              className="btn--secondary btn--pill"
              text={t(`PROFILE.${profile.profile_photo ? "CHANGE" : "UPLOAD"}_PHOTO`)}
            />
          </label>
          {profile.profile_photo &&
            (isMobile ? (
              <Icon
                className={"profile_avatar-delete"}
                name={"delete-trash"}
                handleClick={handleDeletePhoto}
              />
            ) : (
              <Button
                className="btn--danger btn--pill"
                text={t("PROFILE.DELETE_PHOTO")}
                onClick={handleDeletePhoto}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default UploadProfilePhoto;
