import React, { useMemo, memo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslate } from "@helpers/hooks";
import { Icon, List } from "@components/common";
import {
  CoinHistory,
  Feedback,
  HeaderLanguageToggle,
  HeaderProfile,
  LmsBadgeHeader,
  TourGuide,
} from "@components/feature";
import "./HeaderMenu.scss";
import { HeaderNotifications } from "../HeaderNotifications";

export default memo(function HeaderMenu() {
  const [isGuideActive, setIsGuideActive] = useState(false);
  const [isFeedbackActive, setIsFeedbackActive] = useState(false);
  const [isCoinsActive, setIsCoinsActive] = useState(false);

  const { t } = useTranslate();
  const { pathname } = useLocation();

  const handleCoins = () => {
    setIsCoinsActive(!isCoinsActive);
  };

  const handleFeedback = () => {
    setIsFeedbackActive(!isFeedbackActive);
  };

  const handleGuide = () => {
    setIsGuideActive(!isGuideActive);
  };

  const navs = useMemo(() => {
    const items = [
      {
        id: 2,
        component: <Icon name="info_line" handleClick={handleGuide} />,
        condition: !pathname.includes("section"),
      },
      { id: 7, component: <HeaderNotifications />, condition: true },
      { id: 3, component: <HeaderLanguageToggle />, condition: true },
      {
        id: 4,
        component: <LmsBadgeHeader />,
        condition: pathname.includes("courses"),
      },
      {
        id: 5,
        component: (
          <HeaderProfile
            handleFeedback={handleFeedback}
            handleCoins={handleCoins}
          />
        ),
        condition: true,
      },
    ];

    return items.filter((item) => item.condition);
  }, [pathname, t]);

  return (
    <>
      {isGuideActive && <TourGuide />}
      {isFeedbackActive && <Feedback handleClose={handleFeedback} />}
      {isCoinsActive && <CoinHistory handleClose={handleCoins} />}

      <List
        className={"header__menu"}
        items={navs}
        renderItem={(nav) => (
          <li key={nav.id} className={"menu__item"}>
            {nav.component}
          </li>
        )}
      />
    </>
  );
});
