import React from "react";
import "./FixedBanner.scss";
import { useSelector } from "react-redux";
import { BtnLoader, Button, Icon } from "@components/common";
import { CountdownTimer } from "../CountdownTimer";
import { trackAmplitude } from "@utils/functions";
import { useAction, useIsMobile } from "@helpers/hooks";
import { MockFormModal } from "../MockExam";
import { useState } from "react";

const BANNER_THEMES = {
  kaspi: {
    mainClass: "fixed__banner kaspi-banner",
    containerClass: "fixed__banner-container kaspi-container",
    contentClass: "fixed__banner-content kaspi-content",
    imageClass: "fixed__banner-image-circle kaspi-image",
    titleClass: "fixed__banner-title kaspi-title",
    buttonClass: "btn--announce btn--kaspi",
  },
  laura: {
    mainClass: "fixed__banner laura-banner",
    containerClass: "fixed__banner-container laura-container",
    contentClass: "fixed__banner-content laura-content",
    imageClass: "fixed__banner-image-circle laura-image",
    titleClass: "fixed__banner-title laura-title",
    buttonClass: "btn--announce btn--laura",
  },
  halloween: {
    mainClass: "fixed__banner halloween-banner",
    containerClass: "fixed__banner-container halloween-container",
    contentClass: "fixed__banner-content halloween-content",
    imageClass: "fixed__banner-image-circle halloween-image",
    titleClass: "fixed__banner-title halloween-title",
    buttonClass: "btn--announce btn--halloween",
  },
  black: {
    mainClass: "fixed__banner black-banner",
    containerClass: "fixed__banner-container black-container",
    contentClass: "fixed__banner-content black-content",
    imageClass: "fixed__banner-image-circle black-image",
    titleClass: "fixed__banner-title black-title",
    buttonClass: "btn--announce btn--black",
  },
};

export default function FixedBanner({ onClose }) {
  const { announces, isLoading } = useSelector((state) => state.directory);
  const announce = announces?.[0];
  const isMobile = useIsMobile();
  const [formData, setFormData] = useState({ first_name: "", phone: "" });
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const { mocksFormCreate } = useAction();
  const [showSuccess, setShowSuccess] = useState(false);

  const theme = announce?.theme_name || "laura";


  const currentTheme = BANNER_THEMES[theme] || BANNER_THEMES.default;

  const handleClick = () => {
    trackAmplitude("announce_more_click", {
      description: "Пользователь кликнул анонс",
      announce_name: announce?.title,
    });

    if (announce?.button_link) {
      window.location.href = announce.button_link;
    } else {
      setIsFormModalOpen(!isFormModalOpen);
    }
  };


  const handleClose = () => {
    setIsFormModalOpen(!isFormModalOpen);
  };

  if (isLoading) {
    return <BtnLoader />;
  }

  return (
    <div className={currentTheme.mainClass}>
      {isFormModalOpen && (
        <MockFormModal
          isMobile={isMobile}
          onClose={handleClose}
          theme={theme}
        />
      )}

      <div className={currentTheme.containerClass}>
        <div className={currentTheme.contentClass}>
          {announce?.image1 && (
            <img
              className="fixed__banner-image-circle"
              src={announce?.image1}
            />
          )}

          <h4 className={currentTheme.titleClass}>{announce?.title}</h4>
        </div>
        <div className="fixed__banner-buttons">
          {new Date() < new Date(announce?.end_date) && !isMobile && (
            <CountdownTimer
              endDate={announce?.end_date}
              className="announce__timer"
            />
          )}
          <Button
            onClick={handleClick}
            className={currentTheme.buttonClass}
            text={announce?.button_link_text}
          />
          {announce?.image2 && (
            <img
              className="fixed__banner-image-circle"
              src={announce?.image2}
            />
          )}
        </div>
      </div>
    </div>
  );
}
