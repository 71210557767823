const EDUCATION_OLYMPIADS = [
    {id: 1, name: "Olympiad 1", value: 1},
    {id: 2, name: "Olympiad 2", value: 2},
    {id: 3, name: "Olympiad 3", value: 3},
    {id: 4, name: "Olympiad 4", value: 4}
];

const EDUCATION_INFOS = [
    {id: 1, name: "BUTTON.ADD_OLYMPIAD", label: "QUESTIONNAIRE.INFOS.LABEL_1", field: "select", options: EDUCATION_OLYMPIADS},
    {id: 2, name: "BUTTON.ADD_SPORT_ACHIEVEMENT", label: "QUESTIONNAIRE.INFOS.LABEL_2", field: "input", placeholder: "FIELD.ENTER_TEXT"},
    {id: 3, name: "BUTTON.ADD_CREATIVE_ACHIEVEMENT", label: "QUESTIONNAIRE.INFOS.LABEL_3", field: "input", placeholder: "FIELD.ENTER_TEXT"},
    {id: 4, name: "BUTTON.ADD_POST", label: "QUESTIONNAIRE.INFOS.LABEL_4", field: "input", placeholder: "FIELD.ENTER_TEXT"},
    {id: 5, name: "BUTTON.ADD_EXCHANGE_PROGRAM", label: "QUESTIONNAIRE.INFOS.LABEL_5", field: "input", placeholder: "FIELD.ENTER_TEXT"},
    {id: 6, name: "BUTTON.ADD_ACTIVITY", label: "QUESTIONNAIRE.INFOS.LABEL_6", field: "input", placeholder: "FIELD.ENTER_TEXT"},
    {id: 7, name: "BUTTON.ADD_PROJECT", label: "QUESTIONNAIRE.INFOS.LABEL_7", field: "input", placeholder: "FIELD.ENTER_TEXT"}
];

// Applies to both school and university
const CURRENT_STUDY_YEARS = [
    {id: 1, name: "1", value: 1},
    {id: 2, name: "2", value: 2},
    {id: 3, name: "3", value: 3},
    {id: 4, name: "4", value: 4},
    {id: 5, name: "5", value: 5},
    {id: 6, name: "6", value: 6},
    {id: 7, name: "7", value: 7},
    {id: 8, name: "8", value: 8},
    {id: 9, name: "9", value: 9},
    {id: 10, name: "10", value: 10},
    {id: 11, name: "11", value: 11},
    {id: 12, name: "12", value: 12}
]

export {
    EDUCATION_OLYMPIADS,
    EDUCATION_INFOS,
    CURRENT_STUDY_YEARS
};
