import { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import classNames from 'classnames';
import { useAction, useTranslate } from '@helpers/hooks';
import { numberFormatWithDash } from '@utils/functions';
import { Button, FormItem, Icon, List } from '@components/common';
import './WelcomeQuestionnaireStep.scss';

const WelcomeQuestionnaireStep = ({step, next, previous, setIsQuestionnaireOpen}) => {
    const { t } = useTranslate();
    const {updateEducation, fetchProgramSuccessRate, fetchUniversities, fetchSimplePrograms} = useAction();
    const { resultsDirectory } = useSelector(state => state.university);
    const { results } = useSelector(state => state.program);
    const [ielts, setIelts] = useState("");
    const [toefl, setToefl] = useState("");
    // TODO: Collect SAT Math and SAT Verbal scores separately
    const [sat, setSat] = useState("");
    const [act, setAct] = useState("");
    const [gpa, setGpa] = useState("");
    const [universitySelected, setUniversitySelected] = useState(false);
    const [values, setValues] = useState({
        lang_tests: [],
        gpa_score: "",
        program: "",
    });
    const [universityOption, setUniversityOption] = useState("");

    useEffect(() => {
        if (universitySelected) {
            fetchSimplePrograms({university: universitySelected})
        }
    }, [universitySelected])

    useEffect(() => {
        if (toefl) {
            setValues(prev => ({...prev, lang_tests: [{exam_type: 2, points: +toefl}]}))
        }
    }, [toefl])

    useEffect(() => {
        if (ielts) {
            setValues(prev => ({...prev, lang_tests: [{exam_type: 1, points: +ielts}]}))
        }
    }, [ielts])

    useEffect(() => {
        if (sat) {
            setValues(prev => ({...prev, lang_tests: [prev.lang_tests[0], {exam_type: 4, points: +sat}]}))
        }
    }, [sat])

    useEffect(() => {
        if (act) {
            setValues(prev => ({...prev, lang_tests: [prev.lang_tests[0], {exam_type: 5, points: +act}]}))
        }
    }, [act])

    useEffect(() => {
        if (gpa) {
            setValues(prev => ({...prev, gpa_score: +gpa}))
        }
    }, [gpa])

    const handleGoNext = () => {
        next();
    }

    const handleGoBack = () => {
        previous();
    }

    const handleClose = () => {
        setIsQuestionnaireOpen(false);
    }

    const handleSubmit = async () => {
        await updateEducation(values);
        await fetchProgramSuccessRate(values.program);
        next();
    }

    const disabledCondition = () => {
        switch(step) {
            case 2:
                // Check if TOEFL and IELTS scores are within their valid ranges
                return values.lang_tests.length === 0 || 
                       (values.lang_tests.some(test => test.exam_type === 2) && (toefl < 1 || toefl > 120)) || 
                       (values.lang_tests.some(test => test.exam_type === 1) && (ielts < 1 || ielts > 9));
            case 3:
                // Check if SAT and ACT scores are within their valid ranges
                return values.lang_tests.length < 2 || 
                       (values.lang_tests.some(test => test.exam_type === 4) && (sat < 400 || sat > 1600)) || 
                       (values.lang_tests.some(test => test.exam_type === 5) && (act < 1 || act > 36));
            case 4:
                // Check if GPA is within its valid range
                return gpa < 1 || gpa > 4;
            case 5:
                return values.program === "";
            default:
                return true;
        }
    }
    

    const fillerStyles = {
        width: `${(step - 1) * 20}%`,
    }

    const questions = useMemo(() => [
        {
            id: 2,
            question: t("WELCOME_QUESTIONNAIRE.LABEL_5"),
            field: "radio-with-input",
            definition: "WELCOME_QUESTIONNAIRE.DEFINITION_1",
            options: [
                {
                    id: 2,
                    name: "lang_tests",
                    label: "TOEFL",
                    value: 2,
                    input_type: "number",
                    input_name: "toefl",
                    input_placeholder: t("WELCOME_QUESTIONNAIRE.TOTAL_SCORE"),
                    input_value: toefl,
                    inputonchange: (e) => setToefl(e.target.value),
                    input_step: "1",
                    input_min: 1,
                    input_maxlength: 3,
                    input_max: 120,
                    input_onkeypress: numberFormatWithDash,
                    input_validaterange: true
                },
                {
                    id: 1,
                    name: "lang_tests",
                    label: "IELTS",
                    value: 1,
                    input_type: "number",
                    input_name: "ielts",
                    input_placeholder: t("WELCOME_QUESTIONNAIRE.TOTAL_SCORE"),
                    input_value: ielts,
                    inputonchange: (e) => setIelts(e.target.value),
                    input_step: 0.5,
                    input_min: 1,
                    input_maxlength: 3,
                    input_max: 9,
                    input_onkeypress: numberFormatWithDash,
                    input_validaterange: true
                },
            ],
            name: "lang_tests",
            subname: "english_proficiency",
            value: values.lang_tests,
        },
        {
            id: 3,
            question: t("WELCOME_QUESTIONNAIRE.LABEL_6"),
            field: "radio-with-input",
            definition: "WELCOME_QUESTIONNAIRE.DEFINITION_1",
            options: [
                {
                    id: 4,
                    name: "lang_tests",
                    label: "SAT",
                    value: 4,
                    input_type: "number",
                    input_name: "sat",
                    input_placeholder: t("WELCOME_QUESTIONNAIRE.TOTAL_SCORE"),
                    input_value: sat,
                    inputonchange: (e) => setSat(e.target.value),
                    input_step: "1",
                    input_min: 400,
                    input_maxlength: 4,
                    input_max: 1600,
                    input_onkeypress: numberFormatWithDash,
                    input_validaterange: true,
                },
                {
                    id: 5,
                    name: "lang_tests",
                    label: "ACT",
                    value: 5,
                    input_type: "number",
                    input_name: "act",
                    input_placeholder: t("WELCOME_QUESTIONNAIRE.TOTAL_SCORE"),
                    input_value: act,
                    inputonchange: (e) => setAct(e.target.value),
                    input_step: "1",
                    input_min: 1,
                    input_maxlength: 2,
                    input_max: 36,
                    input_onkeypress: numberFormatWithDash,
                    input_validaterange: true
                },
            ],
            name: "lang_tests",
            subname: "math_level",
            value: values.lang_tests,
        },
        {
            id: 4,
            question: t("WELCOME_QUESTIONNAIRE.LABEL_7"),
            field: "radio-with-input",
            options: [
                {
                    id: 6,
                    name: "gpa_score",
                    label: "GPA",
                    value: 6,
                    input_type: "number",
                    input_name: "gpa",
                    input_placeholder: t("WELCOME_QUESTIONNAIRE.TOTAL_SCORE"),
                    input_value: gpa,
                    inputonchange: (e) => setGpa(e.target.value),
                    input_step: "0.01",
                    input_min: 1,
                    input_maxlength: 3,
                    input_max: 4,
                    input_onkeypress: numberFormatWithDash,
                    input_validaterange: true
                },
            ],
            name: "gpa_score",
            value: values.gpa_score,
        },
        {
            id: 5,
            question: t("WELCOME_QUESTIONNAIRE.LABEL_8"),
            field: "dropdown",
            label: t("WELCOME_QUESTIONNAIRE.LABEL_9"),
            name: "university",
            isOptions: true,
            options: resultsDirectory,
            defaultValue: universityOption,
            isSearch: true,
            method: ({search}) => fetchUniversities({search}),
            showExtra: universitySelected,
            extraFields: [
                {
                    id: 1,
                    field: "dropdown",
                    label: t("WELCOME_QUESTIONNAIRE.LABEL_8"),
                    name: "program",            
                    options: results,
                    isSearch: true,
                    method: ({search}) => fetchSimplePrograms({search, university: universitySelected}),
                }
            ]
        },
    ], [t, values.lang_tests, values.gpa_score, values.program,
        universitySelected, resultsDirectory, results, fetchSimplePrograms, toefl, ielts, sat, act, gpa, universityOption])

    const handleChange = (e, field, name, options, subname) => {
        if (field === "dropdown") {
            if (name === "university") {
                setUniversityOption(e);
                setUniversitySelected(e.value);
            }
        }

        if (field === "radio-with-input") {
            if (subname === "english_proficiency") {
                setValues({...values, [name]: [{exam_type: +e.target.value, points: ""}]})
            }
            if (subname === "math_level") {
                setValues(prev => ({...prev, [name]: [prev.lang_tests[0], {exam_type: +e.target.value, points: ""}]}))
            }
            if (name === "gpa_score") {
                setValues(prev => ({...prev, gpa_score: ""}))
            }
        }
    }

    const handleChangeExtra = (e, field, name) => {
        if (field === "dropdown") {
            if (name === "program") {
                setValues({...values, [name]: e.value})
            }
        }
    }
    
    return (
        <div className="welcome-q-step">
            <Icon name="close-rounded" className="lms-badge-profile__close" handleClick={handleClose}/>
            <div className="welcome-q-step__progress">
                <div className="welcome-q-step__filler" style={fillerStyles}></div>
            </div>
            <List
                className="welcome-q-step__content"
                items={questions}
                renderItem={(item) => {
                    if (item.id === step) {
                        return (
                            <div className="welcome-q-step__question" key={item.id}>
                                <div className="welcome-q-step__question__area">
                                    <h1 className="welcome-q-step__question__title">{item.question}</h1>
                                    <span>
                                    {
                                        item.definition && 
                                        <div className="welcome-q-step__question__info">
                                            <a data-tooltip-id="my-tooltip" data-tooltip-content={t(item.definition)}>
                                                <Icon name="help-info" />
                                            </a>
                                            <ReactTooltip 
                                                id="my-tooltip"
                                                style={{width: "500px"}}
                                            />
                                        </div>
                                        
                                    }
                                    </span>
                                </div>
                                <div className="welcome-q-step__input-group">
                                    <FormItem item={item} handleChange={handleChange}/>
                                   { item.isOptions ?
                                   <>
                                    {
                                        results?.length>0 && item.extraFields && item.showExtra && item.extraFields.map((extraItem) => 
                                            <FormItem key={extraItem.id} item={extraItem} handleChange={handleChangeExtra}/>    
                                        )
                                    }
                                    </>
                                    :
                                    <>
                                    {
                                        item.extraFields && item.showExtra && item.extraFields.map((extraItem) => 
                                            <FormItem key={extraItem.id} item={extraItem} handleChange={handleChangeExtra}/>    
                                        )
                                    }
                                     </>
                                    }
                                </div>
                            </div>
                        )
                    }
                }}
            />
            <div className="welcome-q-step__buttons">
                <Button
                    disabled={disabledCondition()}
                    className={classNames("btn--secondary", "welcome-q-step__buttons__next")}
                    text={step < 5 ? t("WELCOME_QUESTIONNAIRE.NEXT") : t("WELCOME_QUESTIONNAIRE.SUBMIT")}
                    onClick={step < 5 ? handleGoNext : handleSubmit} 
                />
                <Button
                    className={classNames("btn--teritary welcome-q-step__buttons__back")}
                    text={t("WELCOME_QUESTIONNAIRE.BACK")}
                    onClick={handleGoBack} 
                />
            </div>
        </div>
    );
}

export default WelcomeQuestionnaireStep;
