import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAction, useTranslate } from "@helpers/hooks";
import { BtnLoader, Button, Icon, NewModalLayout } from "@components/common";
import FeedbackQuestionItem from "./FeedbackQuestionItem/FeedbackQuestionItem";
import "./Feedback.scss";

const Feedback = ({ handleClose }) => {
  const [submitted, setSubmitted] = useState(false);
  const [userAnswers, setUserAnswers] = useState({});
  const { feedback, isLoading } = useSelector((state) => state.profile);
  const { fetchFeedback, submitFeedback } = useAction();
  const { t } = useTranslate();
  useEffect(() => {
    fetchFeedback();
  }, []);

  const handleAnswerChange = (questionId, answer) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formattedAnswers = Object.entries(userAnswers).map(
      ([questionId, answer]) => ({
        question: parseInt(questionId, 10),
        answer,
      })
    );
    submitFeedback({ id: currentFeedback.id, data: formattedAnswers });
    setSubmitted(true);
  };

  if (isLoading) {
    return <BtnLoader className={"btn-loader-bg-large"} />;
  }

  if (!feedback || feedback.length === 0) return null;

  const currentFeedback = feedback[0];

  return (
    <NewModalLayout className="feedback_modal">
      {submitted ? (
        <div className="feedback-container__success">
          <Icon name="form_success" className="feedback-container__icon" />
          <h4>{t("FEEDBACK.SUCCESS")}</h4>
          <p>{t("FEEDBACK.SUCCESS_SUBTEXT")}</p>
          <Button
              className="btn--primary btn--pill"
            onClick={handleClose}
              text={t("FEEDBACK.CLOSE")}
            />
        </div>
      ) : (
        <div className="feedback-container">
          <div className="feedback-container__header">
            <h2>{currentFeedback.title}</h2>
            <Icon name="close-rounded" handleClick={handleClose} />
          </div>

          <form onSubmit={handleSubmit}>
            {currentFeedback.questions.map((question, index) => (
              <FeedbackQuestionItem
                key={index}
                question={question}
                index={index}
                userAnswers={userAnswers}
                handleAnswerChange={handleAnswerChange}
              />
            ))}

            <Button
              className="btn--primary btn--pill"
              type="submit"
              text={t("FEEDBACK.SEND")}
            />
          </form>
        </div>
      )}
    </NewModalLayout>
  );
};

export default Feedback;
