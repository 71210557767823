import { List } from "@components/common";
import { CatalogEmpty } from "@components/feature";
import React from "react";
import { DashboardBar } from "../DashboardBar";
import { useTranslate } from "@helpers/hooks";
import "./DashboardBarsContent.scss";

export default function DashboardBarsContent({ uniData, myEducation }) {

  function extractFirstNumber(str) {
    return parseInt(str?.split(" - ")[0], 10);
  }

  function getRealScoreForExam(examName, lang_tests) {
    if (examName === "SAT") {
        const satMath = lang_tests?.find((test) => test?.exam_name === "SAT Math");
        const satVerbal = lang_tests?.find((test) => test?.exam_name === "SAT Verbal");
        const satCombined = lang_tests?.find((test) => test?.exam_name === "SAT");

        if (!satMath && !satVerbal && !satCombined) {
            return null;
        }

        // If both Math and Verbal are missing, use the combined (to be deprecated) SAT score
        if (!satMath && !satVerbal && satCombined) {
            return satCombined.points;
        }

        const satMathPoints = satMath ? satMath.points : 0;
        const satVerbalPoints = satVerbal ? satVerbal.points : 0;

        return satMathPoints + satVerbalPoints;
    } else {
        const exam = lang_tests?.find((test) => test?.exam_name === examName);
        return exam ? exam.points : null;
    }
  }

  function scoreToPercentage(score, examType) {
    let maxScore;

    switch (examType) {
      case "IELTS":
        maxScore = 9.0;
        break;
      case "SAT":
        maxScore = 1600;
        break;
      case "ACT":
        maxScore = 36;
        break;
      case "TOEFL":
        maxScore = 120;
        break;
      default:
        return 0;
    }

    return Math.round((score / maxScore) * 100);
  }
  const { t } = useTranslate();
  function extractExamData(uniData, myEducation) {
    return {

      GPA: {
        real: Math.round(uniData?.gpa_percent),
        desired: scoreToPercentage(parseInt(uniData?.university[0].gpa_min), "GPA") || 75,
        scoreReal: myEducation?.gpa_score,
        scoreDesired: Number(uniData?.university[0].gpa_min) || 3.0,
      },
      SAT: {
        real: Math.round(uniData?.sat_percent),
        desired: scoreToPercentage(extractFirstNumber(uniData?.university[0].SAT_range), "SAT") || 81,
        scoreReal: getRealScoreForExam("SAT", myEducation?.lang_tests),
        scoreDesired: extractFirstNumber(uniData?.university[0].SAT_range) || 1300,
      },

      ACT: {
        real: Math.round(uniData?.act_percent),
        desired: scoreToPercentage(extractFirstNumber(uniData?.university[0].ACT_range), "ACT") || 78,
        scoreReal: getRealScoreForExam("ACT", myEducation?.lang_tests),
        scoreDesired: extractFirstNumber(uniData?.university[0].ACT_range) || 28,
      },

      IELTS: {
        real: Math.round(uniData?.ielts_percent),
        desired: scoreToPercentage(parseInt(uniData?.university[0].ielts_min), "IELTS") || 67,
        scoreReal: getRealScoreForExam("IELTS", myEducation?.lang_tests),
        scoreDesired: Number(uniData?.university[0]?.ielts_min) || 6,
      },

      TOEFL: {
        real: Math.round(uniData?.toefl_percent),
        desired: scoreToPercentage(extractFirstNumber(uniData?.university[0].TOEFL_range), "TOEFL") || 83,
        scoreReal: getRealScoreForExam("TOEFL", myEducation?.lang_tests),
        scoreDesired: extractFirstNumber(uniData?.university[0].TOEFL_range) || 100,
      },
    };
  }

  const COLORS = [
    {
      id: 1,
      className: "box__current",
      text: t("DASHBOARD.CHANCES.CURRENT"),
    },
    {
      id: 2,
      className: "box__minimum",
      text: t("DASHBOARD.CHANCES.MINIMUM"),
    },
    {
      id: 3,
      className: "box__success",
      text: t("DASHBOARD.CHANCES.SUCCESS"),
    },
  ];

  const exams = extractExamData(uniData, myEducation);


  return (
    <div className="dashboard__bars">
      {uniData && myEducation ? (
        <>
          <div className="dashboard__bars-group">
            {Object.entries(exams).map(([exam, data]) => (
              <DashboardBar key={exam} exam={exam} data={data} />
            ))}
          </div>

          <List
            className="bar__group"
            items={COLORS}
            renderItem={(item) => (
              <li key={item.id} className="bar__legend">
                <div className={`${item.className} bar__box`} />
                <p className="box__text">{item.text}</p>
              </li>
            )}
          />
        </>
      ) : (
        <CatalogEmpty
          className={"catalog__chance"}
          imageClassName={"dashboard__bars-empty catalog__chance-image"}
          isTextEmpty={true}
        />
      )}
    </div>
  );
}
