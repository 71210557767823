import React from "react";
import { BtnLoader } from "@components/common";
import { useTranslate } from "@helpers/hooks";
import "./AdminMockNuetTable.scss";

export default function AdminMockNuetTable({ mockNuetGrades, isLoading }) {
  const { t } = useTranslate();

  return (
    <div className="mock-admin">
      {mockNuetGrades?.length > 0 ? (
        <div className="mock-table">
          <div className="mock-table__header">
            <div className="mock-table__cell--student">Student</div>
            <div className="mock-table__cell">Math</div>
            <div className="mock-table__cell">Crit</div>
            <div className="mock-table__cell">Total</div>
            <div className="mock-table__cell">Exam started at</div>
          </div>
          {mockNuetGrades.map((grade) => {
            const totalScore =
              (grade.math_score || 0) + (grade.crit_score || 0);

            return (
              <div key={grade.user_id} className="mock-table__row">
                <div className="mock-table__cell--student">
                  <img
                    src={grade.user_image}
                    alt={grade.user_name}
                    className="mock-table__user-image"
                  />
                  <span>{grade.user_name}</span>
                </div>
                <div className="mock-table__cell">{grade.math_score}</div>
                <div className="mock-table__cell">{grade.crit_score}</div>
                <div className="mock-table__cell">{totalScore}</div>
                <div className="mock-table__cell">
                  {new Date(grade.created_at).toLocaleString()}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        isLoading && <BtnLoader className="btn-loader-bg-large" />
      )}
    </div>
  );
}
