import React, { useEffect, useState, useRef } from "react";
import { formatTime } from "@utils/functions";
import { useSelector } from "react-redux";
import { notify } from "@utils/functions";
import { useTranslate } from "@helpers/hooks";
import { toast } from "react-toastify";
import {DirectoryService } from "@api/services";
import "./MockTimer.scss";

const WARNING_TIME = 60000;

const MockTimer = React.memo(function MockTimer({handleSubmit, closeModal}) {
  const {
    stage,
    // NUET
    mathEndTime,
    critEndTime,
    // SAT
    verbalOneEndTime,
    verbalTwoEndTime,
    mathOneEndTime,
    mathTwoEndTime,
    // IELTS
    readingEndTime,
    listeningEndTime,
    speakingEndTime,
    writingEndTime,
  } = useSelector((state) => state.mocks);
  const [timeLeft, setTimeLeft] = useState(null);
  const { t } = useTranslate();
  const timeOffset = useRef(null);  // if the user's local time is incorrect, we need to adjust it

  const hasSubmittedRefs = useRef({
    math: false,
    crit: false,
    module1: false,
    module2: false,
    module3: false,
    module4: false,
    listening: false,
    reading: false,
    writing: false,
    speaking: false,
  });

  useEffect(() => {
    const fetchTime = async () => {
      const res = await DirectoryService.fetchCurrentTime();
      timeOffset.current = Date.parse(res.current_time) - new Date().getTime();
    };
    fetchTime();
  }, []);

  useEffect(() => {
    let timer;

    const endTimeMappings = {
      // NUET
      math: mathEndTime,
      crit: critEndTime,
      // SAT
      module1: verbalOneEndTime,
      module2: verbalTwoEndTime,
      module3: mathOneEndTime,
      module4: mathTwoEndTime,
      // IELTS
      listening: listeningEndTime,
      reading: readingEndTime,
      writing: writingEndTime,
      speaking: speakingEndTime,
    };

    if (stage !== "finished") {
      const endTime = endTimeMappings[stage];
      const end = Date.parse(endTime);

      const updateRemainingTime = () => {
        if (timeOffset.current === null) return;
        
        const now = new Date().getTime() + timeOffset.current;
        const distance = end - now;
        if (distance <= 0 && !hasSubmittedRefs.current[stage]) {
          clearInterval(timer);

          hasSubmittedRefs.current[stage] = true;

          // close modal, if it's open, to avoid that user presses submit button
          // This is important, because there can be cases when user has opened the modal,
          // but auto-submit happens at the same time.
          // If we don't close the modal, user will be able to submit while being in the next section.
          closeModal();

          // The caveat with closeModal is that under the current code user will be able to re-open the modal,
          // if the auto-submit takes too long.
          // Thus, at least notify him that auto-submit is in progress.
          const inProgressToastId = notify(t("MOCK.SUBMIT_IN_PROGRESS"), {
            type: 'info',
            autoClose: false,
            hideProgressBar: true,
          });

          let dontCloseToastId;
          const timeoutId = setTimeout(() => {
            dontCloseToastId = notify(t("MOCK.DONT_CLOSE_PAGE"), {
              type: 'info',
              autoClose: false,
              hideProgressBar: true,
            });
          }, 2000);

          // This can fail, if the user has already started submitting manually.
          // That's why we don't display any error messages, hoping that manual submission will succeed.
          handleSubmit().finally(() => {
            clearTimeout(timeoutId);
            toast.dismiss(inProgressToastId);
            if (dontCloseToastId) {
              toast.dismiss(dontCloseToastId);
            }
            closeModal(); // close again to avoid that user presses submit button in the next section
          });
        } else {
          setTimeLeft(Math.max(distance, 0));
        }
      };

      updateRemainingTime();
      timer = setInterval(updateRemainingTime, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [stage, handleSubmit]);

  return (
    <div>
      <p
        className={`timer__numbers ${
          stage === "finished"
            ? "timer__blue"
            : timeLeft <= WARNING_TIME
            ? "timer__red"
            : "timer__green"
        } ${timeLeft === null ? "timer__loading" : ""}`}
      >
        {stage === "finished" ? "Review" : timeLeft === null ? "" : formatTime(timeLeft)}
      </p>
    </div>
  );
});

export default MockTimer;
