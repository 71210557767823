import React from "react";
import { useAction, useTranslate } from "@helpers/hooks";
import { useState } from "react";
import { Button, Icon, Input } from "@components/common";
import "./AmoForm.scss";

export default function AmoForm({ onClose, buttonClass }) {
  const [showSuccess, setShowSuccess] = useState(false);
  const [formData, setFormData] = useState({ first_name: "", phone: "" });
  const { mocksFormCreate } = useAction();
  const { t } = useTranslate();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    mocksFormCreate(formData);
    setShowSuccess(true);

    setTimeout(() => {
      setShowSuccess(false);
      onClose();
    }, 2000);
  };

  return (
    <div>
      {showSuccess ? (
        <div className="form_success">
          <Icon name="form_success" className="form_success__icon" />
          <h4 className="form_success__title">{t("SUPPORT.REQUEST_SUCCESS")}</h4>
          <p className="form_success__text">
            {t("SUPPORT.REQUEST_SUCCESS_TEXT")}
          </p>
        </div>
      ) : (
        <>
          <form className="form" onSubmit={handleFormSubmit}>
            <Input
              label={t("SUPPORT.REQUEST_NAME")}
              placeholder={t("SUPPORT.REQUEST_NAME")}
              inputClassName="input__border"
              name="first_name"
              value={formData.first_name}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, first_name: e.target.value }))
              }
            />
            <Input
              label={t("SUPPORT.REQUEST_PHONE")}
              placeholder={t("SUPPORT.REQUEST_PHONE")}
              inputClassName="input__border"
              name="phone"
              value={formData.phone}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, phone: e.target.value }))
              }
            />
            <Button
              className={`btn--primary btn--pill ${buttonClass}`}
              type="submit"
              text={t("SUPPORT.REQUEST_BUTTON")}
            />
          </form>
        </>
      )}
    </div>
  );
}
