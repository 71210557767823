import {axiosRequest} from "@api/xhr";

export default class QuestionnaireService {
    static async fetchMyEducation() {
        return await axiosRequest.get("auth/education/");
    };

    static async updateEducation(data) {
        return await axiosRequest.patch(`auth/education/`, data);
    };

    static async fetchMyPrefer() {
        return await axiosRequest.get("auth/user_prefer/my/");
    };

    static async updatePrefer(id, data) {
        return await axiosRequest.patch(`auth/user_prefer/${id}/`, data);
    };

    static async fetchMyAdditionalInfo() {
        return await axiosRequest.get("auth/additional_info/");
    };

    static async updateAdditionalInfo(id, data) {
        return await axiosRequest.patch(`auth/additional_info/${id}/`, data);
    };

    static async createAdditionalInfo(data) {
        return await axiosRequest.post(`auth/additional_info/`, data);
    };

    static async deleteAdditionalInfo(id) {
        return await axiosRequest.delete(`auth/additional_info/${id}/`);
    };

    static async fetchUniversitiesRecommend() {
        return await axiosRequest.get("auth/university_recommend/");
    }

    static async updateUniversitiesRecommend() {
        return await axiosRequest.get(`auth/university_recommend/update_relations/`);
    }
}