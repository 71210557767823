import { BtnLoader } from '@components/common';
import { useTranslate } from '@helpers/hooks';
import { useEffect } from 'react';
import { PROFILE_ROUTE } from '@utils/consts';
import './WelcomeQuestionnaireEnd.scss';

const WelcomeQuestionnaireEnd = () => {
    const {t} = useTranslate();

    useEffect(() => {
        setTimeout(() => {
            // TODO: After the page has its own url, just refresh the page
            window.location.href=PROFILE_ROUTE
        }, 3000)
    }, [])

    return (
        <div className="welcome-q-end">
            <p className="welcome-q-end__title">{t("WELCOME_QUESTIONNAIRE.LOADER")}</p>
            <div className='welcome-q-end__parent-loader'>
                <BtnLoader className={"btn-loader--blue-large"}/>
            </div>
        </div>
    );
}

export default WelcomeQuestionnaireEnd;
