import {createAsyncThunk} from "@reduxjs/toolkit";
import {DirectoryService, UniversityService} from "@api/services";
import {parseDirectories} from "@utils/functions";

const fetchRegions = createAsyncThunk(
    "regions/fetchAll",
    async (params, thunkAPI) => {
        try {
            const {results} = await DirectoryService.fetchRegions(params);
            return parseDirectories(results);
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const fetchCountries = createAsyncThunk(
    "countries/fetchAll",
    async (params, thunkAPI) => {
        try {
            const results = await DirectoryService.fetchCountries(params);
            return {
                results: parseDirectories(results)
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const fetchCountry = createAsyncThunk(
    "countries/fetchOne",
    async (id, thunkAPI) => {
        try {
            const res = await DirectoryService.fetchCountry(id);
            return res;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const fetchCities = createAsyncThunk(
    "cities/fetchAll",
    async (params, thunkAPI) => {
        try {
            const {results, count} = await DirectoryService.fetchCities(params);
            return {
                count,
                results: parseDirectories(results), 
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const fetchCity = createAsyncThunk(
    "cities/fetchOne",
    async (id, thunkAPI) => {
        try {
            const res = await DirectoryService.fetchCity(id);
            return res;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const fetchSubjects = createAsyncThunk(
    "subjects/fetchAll",
    async (params, thunkAPI) => {
        try {
            const results = await DirectoryService.fetchSubjects(params);
            return parseDirectories(results)
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const fetchDegrees = createAsyncThunk(
    "degrees/fetchAll",
    async (params, thunkAPI) => {
        try {
            const {results} = await UniversityService.fetchUniversities(params);
             return results;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const fetchMajors = createAsyncThunk(
    "majors/fetchAll",
    async (params, thunkAPI) => {
        try {
            const {results} = await DirectoryService.fetchMajors(params);
             return parseDirectories(results);
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const fetchMoreInfos = createAsyncThunk(
    "moreInfos/fetchAll",
    async (params, thunkAPI) => {
        try {
            const {results} = await DirectoryService.fetchMoreInfos(params);
             return parseDirectories(results);
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const fetchLanguages = createAsyncThunk(
    "languages/fetchAll",
    async (_, thunkAPI) => {
        try {
            return await DirectoryService.fetchLanguages();
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const fetchExamTypes = createAsyncThunk(
    "examTypes/fetchAll",
    async (params, thunkAPI) => {
        try {
            return await DirectoryService.fetchExamTypes(params);
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const fetchScholarship = createAsyncThunk(
    "scholarships/fetchOne",
    async (id, thunkAPI) => {
        try {
            return await DirectoryService.fetchScholarship(id);
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const fetchDirectoryUniversities = createAsyncThunk(
    "directoryUniversities/fetchAll",
    async (params, thunkAPI) => {
        try {
            const res = await UniversityService.fetchUniversities(params);
            return parseDirectories(res.results);
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const fetchCourseBannerButtons = createAsyncThunk(
    "courseBannerButtons/fetchAll",
    async (_, thunkAPI) => {
        try {
            return await DirectoryService.fetchCourseBannerButtons();
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const fetchMockBannerButtons = createAsyncThunk(
    "mockBannerButtons/fetchAll",
    async (_, thunkAPI) => {
        try {
            return await DirectoryService.fetchMockBannerButtons();
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

const fetchAnnounces = createAsyncThunk(
    "announces/fetchAll",
    async (_, thunkAPI) => {
        try {
            return await DirectoryService.fetchAnnounces();
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

export {
    fetchRegions, fetchCountries, fetchCities,
    fetchSubjects, fetchDegrees, fetchMajors,
    fetchMoreInfos, fetchLanguages, fetchExamTypes,
    fetchCountry, fetchCity, fetchScholarship,
    fetchDirectoryUniversities, fetchCourseBannerButtons, fetchMockBannerButtons, fetchAnnounces
};
