import { createSlice } from "@reduxjs/toolkit";
import { current } from "immer";
import {
  fetchCourses,
  fetchMyCourses,
  fetchCourse,
  fetchLesson,
  changeLessonStatus,
  submitQuizResults,
  deleteQuizResults,
  createNote,
  getLessonNotes,
  deleteNote,
  editNote,
  fetchWorkshop,
  fetchWorkshops,
  fetchSquidRanking,
  postAttendanceQuizCode,
  fetchGrades,
  fetchMockGrades,
  fetchNotes,
  fetchCourseNotes,
  fetchBadge,
  fetchBadgeCategories,
  createBadgeCategory,
  getPaymentLinks,
  fetchBadgeRating,
  updateAliveStatus,
  setToZero,
  kaspiCreate,
  kaspiMockCreate,
  mocksFormCreate,
  fetchGradesAdmin,
  fetchAdminCourses,
  fetchAdminSections,
  fetchAdminLessons,
  fetchUsersByCourseAdmin,
  giveAccessMockAdmin,
  setUsersByCoursePageChange,
  setUsersByCoursePageSizeChange,
  createCourseReview,
  fetchCourseReviews,
} from "@store/actions/courseAction";
import { notify } from "@utils/functions";

const initialState = {
  courses: [],
  course: null,
  lesson: null,
  isLoading: false,
  isCourseLoading: false,
  isSectionLoading: false,
  isLessonLoading: false,
  error: null,
  isResultLoading: false,
  isSquidRankingLoading: false,
  isBtnLoading: false,
  isQuizLoading: false,
  isNoteLoading: false,
  isNotesLoading: false,
  isWorkshopLoading: false,
  isWorkshopsLoading: false,
  isAttendanceQuizCodeLoading: false,
  isGradesLoading: false,
  isMockGradesLoading: false,
  isCourseNotesLoading: false,
  isMockExamLoading: false,
  notes: [],
  courseNotes: [],
  allNotes: [],
  workshop: null,
  workshops: [],
  attendanceQuizCodeResponse: null,
  isSuccessCode: false,
  grades: [],
  mockExams: [],
  mockExam: null,
  mockListening: null,
  mockReading: null,
  mockWriting: null,
  mockSpeaking: null,
  mockGrades: [],
  isBadgeLoading: false,
  badge: null,
  badgeCategories: [],
  badgeRating: null,
  isBadgeRatingLoading: false,
  isBadgeCategoriesLoading: false,
  isBadgeCategoryCreating: false,
  badgeCategorySelected: null,
  paymentLinks: null,
  squidRanking: null,
  kaspiPayment: null,
  isKaspiPaymentLoading: false,
  kaspiMockPayment: null,
  isKaspiMockPaymentLoading: false,
  mocksForm: null,
  notifier: false,
  gradesAdmin: [],
  isGradesAdminLoading: false,
  lessons: [],
  sections: [],
  course_users: [],
  totalCount: 0,
  pageSize: 10,
  currentPage: 1,
  offset: 0,
  error: null,
  isCourseUsersAdminLoading: false,
  reviews: [],
  isReviewLoading: false,
  myCourses: [],
};

export const courseSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    setCourseNotifier: (state, action) => {
      state.notifier = action.payload;
    },
  },
  extraReducers: {
    [fetchCourses.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchCourses.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.courses = action.payload;
    },
    [fetchCourses.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [fetchMyCourses.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchMyCourses.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.myCourses = action.payload;
    },
    [fetchMyCourses.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [fetchCourse.pending.type]: (state) => {
      state.isCourseLoading = true;
    },
    [fetchCourse.fulfilled.type]: (state, action) => {
      state.isCourseLoading = false;
      state.error = null;
      state.course = {
        ...action.payload,
        sections:
          action.payload && action.payload.sections
            ? action.payload.sections.map((section) => ({
                id: section.id,
                title: section.title,
                description: section.description,
                lessons: section.lessons,
                completed: section.completed,
              }))
            : [],
      };
      const course_purchase = JSON.parse(
        localStorage.getItem("course_purchase")
      );
      if (
        course_purchase &&
        course_purchase.course_id === action.payload.id &&
        course_purchase.enrolled !== action.payload.enrolled
      ) {
        notify("Вы успешно приобрели курс", "success");
        state.notifier = true;
        localStorage.removeItem("course_purchase");
      }
    },
    [fetchCourse.rejected.type]: (state, action) => {
      state.isCourseLoading = false;
      state.error = action.payload;
    },
    [fetchLesson.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchLesson.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.lesson = action.payload;
    },
    [fetchLesson.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // Change completed status of lesson
    [changeLessonStatus.pending.type]: (state) => {
      state.isBtnLoading = true;
    },

    [changeLessonStatus.fulfilled.type]: (state, action) => {
      state.isBtnLoading = false;
      state.error = null;
      state.lesson.completed = true;
      notify("You earned 10 STP Coins!", "success");

      for (let j = 0; j < state.course.sections.length; j++) {
        for (let k = 0; k < state.course.sections[j].lessons.length; k++) {
          if (
            state.course.sections[j].lessons[k].id === action.payload.lesson_id
          ) {
            state.course.sections[j].lessons[k].completed = true;
            return;
          }
        }
      }
    },

    [changeLessonStatus.rejected.type]: (state, action) => {
      state.isBtnLoading = false;
      state.error = action.payload;
    },

    [submitQuizResults.pending.type]: (state) => {
      state.isQuizLoading = true;
    },
    [submitQuizResults.fulfilled.type]: (state, action) => {
      state.isQuizLoading = false;
      state.error = null;

      const response = action.payload;
      const lesson = JSON.parse(JSON.stringify(current(state).lesson));
      const contentQuiz = lesson.content[0].content_quiz;
      const questions = contentQuiz.questions;

      response.question_data.forEach((questionData) => {
        const questionIndex = questions.findIndex(
          (question) => question.id === +questionData.question_id
        );
        if (questions[questionIndex].question_one_choice) {
          questions[questionIndex].question_one_choice.is_correct =
            questionData.is_correct;
          questions[questionIndex].question_one_choice.correct_answer =
            questionData.correct_answer;
          questions[questionIndex].question_one_choice.response =
            questionData.response;
        }
        if (questions[questionIndex].question_multiple_choice) {
          questions[questionIndex].question_multiple_choice.is_correct =
            questionData.is_correct;
          questions[questionIndex].question_multiple_choice.correct_answer =
            questionData.correct_answer;
          questions[questionIndex].question_multiple_choice.response =
            questionData.response;
        }
        if (questions[questionIndex].question_insert_text) {
          questions[questionIndex].question_insert_text.is_correct =
            questionData.is_correct;
          questions[questionIndex].question_insert_text.correct_answer =
            questionData.correct_answer;
          questions[questionIndex].question_insert_text.response =
            questionData.response;
        }
        if (questions[questionIndex].question_true_false) {
          questions[questionIndex].question_true_false.is_correct =
            questionData.is_correct;
          questions[questionIndex].question_true_false.correct_answer =
            questionData.correct_answer;
          questions[questionIndex].question_true_false.response =
            questionData.response;
        }
        if (questions[questionIndex].question_practice) {
          questions[questionIndex].question_practice.is_correct =
            questionData.is_correct;
          questions[questionIndex].question_practice.correct_answer =
            questionData.correct_answer;
          questions[questionIndex].question_practice.response =
            questionData.response;
        }
        if (questions[questionIndex].speaking_question) {
          questions[questionIndex].question_speaking.is_correct =
            questionData.is_correct;
          questions[questionIndex].question_speaking.correct_answer =
            questionData.correct_answer;
          questions[questionIndex].question_speaking.response =
            questionData.response;
        }
      });

      contentQuiz.completed = response.completed;
      contentQuiz.correct_answers = response.correct_answers;
      contentQuiz.questions = questions;
      contentQuiz.score = response.score;

      lesson.content[0].content_quiz = contentQuiz;
      state.lesson = lesson;
    },
    [submitQuizResults.rejected.type]: (state, action) => {
      state.isQuizLoading = false;
      state.error = action.payload;
    },
    [deleteQuizResults.pending.type]: (state) => {
      state.isResultLoading = true;
    },
    [deleteQuizResults.fulfilled.type]: (state, action) => {
      state.isResultLoading = false;
      state.error = null;

      const lesson = JSON.parse(JSON.stringify(current(state).lesson));
      const contentQuiz = lesson.content[0].content_quiz;

      const questions = contentQuiz.questions;
      questions.forEach((question) => {
        delete question.question_one_choice?.is_correct;
        delete question.question_multiple_choice?.is_correct;
        delete question.question_insert_text?.is_correct;
        delete question.question_true_false?.is_correct;
        delete question.question_practice?.is_correct;
        delete question.question_speaking?.is_correct;

        delete question.question_one_choice?.correct_answer;
        delete question.question_multiple_choice?.correct_answer;
        delete question.question_insert_text?.correct_answer;
        delete question.question_true_false?.correct_answer;
        delete question.question_practice?.correct_answer;
        delete question.question_speaking?.correct_answer;

        delete question.question_one_choice?.response;
        delete question.question_multiple_choice?.response;
        delete question.question_insert_text?.response;
        delete question.question_true_false?.response;
        delete question.question_practice?.response;
        delete question.question_speaking?.response;
      });

      contentQuiz.completed = false;
      contentQuiz.correct_answers = 0;
      contentQuiz.questions = questions;

      lesson.content[0].content_quiz = contentQuiz;
      state.lesson = lesson;
    },
    [deleteQuizResults.rejected.type]: (state, action) => {
      state.isResultLoading = false;
      state.error = action.payload;
    },

    // Notes
    [createNote.pending.type]: (state) => {
      state.isNoteLoading = false;
      // state.notes[action.payload.question_id] = action.payload;
    },
    [createNote.fulfilled.type]: (state, action) => {
      state.isNoteLoading = false;
      state.error = null;
    },
    [createNote.rejected.type]: (state, action) => {
      state.isNoteLoading = false;
      state.error = action.payload;
    },

    [getLessonNotes.pending.type]: (state) => {
      state.isNoteLoading = false;
    },
    [getLessonNotes.fulfilled.type]: (state, action) => {
      state.isNoteLoading = false;
      state.error = null;
      state.notes = action.payload.results.sort(
        (a, b) => a.timestamp - b.timestamp
      );
    },
    [getLessonNotes.rejected.type]: (state, action) => {
      state.isNoteLoading = false;
      state.error = action.payload;
    },

    [deleteNote.pending.type]: (state) => {
      state.isNoteLoading = true;
    },
    [deleteNote.fulfilled.type]: (state, action) => {
      state.isNoteLoading = false;
      state.error = null;
      state.notes = state.notes.filter((note) => note.id !== action.payload);
      state.courseNotes = state.courseNotes.filter(
        (note) => note.id !== action.payload
      );
    },
    [deleteNote.rejected.type]: (state, action) => {
      state.isNoteLoading = false;
      state.error = action.payload;
    },

    [editNote.pending.type]: (state) => {
      state.isNoteLoading = false;
    },
    [editNote.fulfilled.type]: (state, action) => {
      state.isNoteLoading = false;
      state.error = null;
    },
    [editNote.rejected.type]: (state, action) => {
      state.isNoteLoading = false;
      state.error = action.payload;
    },
    [fetchWorkshop.pending.type]: (state) => {
      state.isWorkshopLoading = true;
    },
    [fetchWorkshop.fulfilled.type]: (state, action) => {
      state.isWorkshopLoading = false;
      state.error = null;
      state.workshop = action.payload;
    },
    [fetchWorkshop.rejected.type]: (state, action) => {
      state.isWorkshopLoading = false;
      state.error = action.payload;
    },
    [fetchWorkshops.pending.type]: (state) => {
      state.isWorkshopsLoading = true;
    },
    [fetchWorkshops.fulfilled.type]: (state, action) => {
      state.isWorkshopsLoading = false;
      state.error = null;
      state.workshops = action.payload.results;
    },
    [fetchWorkshops.rejected.type]: (state, action) => {
      state.isWorkshopsLoading = false;
      state.error = action.payload;
    },
    [postAttendanceQuizCode.pending.type]: (state) => {
      state.isAttendanceQuizCodeLoading = true;
    },
    [postAttendanceQuizCode.fulfilled.type]: (state, action) => {
      state.isAttendanceQuizCodeLoading = false;
      state.error = null;
      state.attendanceQuizCode = action.payload.res;
      for (let i = 0; i < state.course.workshops.length; i++) {
        if (state.course.workshops[i].id === action.payload.workshopId) {
          state.course.workshops[i].completed = true;
          notify(action.payload.res.message, "success");
          state.isSuccessCode = true;
          return;
        }
      }
    },
    [postAttendanceQuizCode.rejected.type]: (state, action) => {
      state.isAttendanceQuizCodeLoading = false;
      state.error = action.payload;
      notify(action.payload.message, "error");
    },
    [fetchGrades.pending.type]: (state) => {
      state.isGradesLoading = true;
    },
    [fetchGrades.fulfilled.type]: (state, action) => {
      state.isGradesLoading = false;
      state.error = null;
      state.grades = action.payload.results;
    },
    [fetchGrades.rejected.type]: (state, action) => {
      state.isGradesLoading = false;
      state.error = action.payload;
    },
    [fetchMockGrades.pending.type]: (state) => {
      state.isMockGradesLoading = true;
    },
    [fetchMockGrades.fulfilled.type]: (state, action) => {
      state.isMockGradesLoading = false;
      state.error = null;
      state.mockGrades = action.payload;
    },
    [fetchMockGrades.rejected.type]: (state, action) => {
      state.isMockGradesLoading = false;
      state.error = action.payload;
    },
    [fetchNotes.pending.type]: (state) => {
      state.isNotesLoading = true;
    },
    [fetchNotes.fulfilled.type]: (state, action) => {
      state.isNotesLoading = false;
      state.error = null;
      state.allNotes = action.payload.results;
    },
    [fetchNotes.rejected.type]: (state, action) => {
      state.isNotesLoading = false;
      state.error = action.payload;
    },
    [fetchCourseNotes.pending.type]: (state) => {
      state.isCourseNotesLoading = true;
    },
    [fetchCourseNotes.fulfilled.type]: (state, action) => {
      state.isCourseNotesLoading = false;
      state.error = null;
      state.courseNotes = action.payload.results;
    },
    [fetchCourseNotes.rejected.type]: (state, action) => {
      state.isCourseNotesLoading = false;
      state.error = action.payload;
    },

    [fetchBadge.pending.type]: (state) => {
      state.isBadgeLoading = true;
    },
    [fetchBadge.fulfilled.type]: (state, action) => {
      state.isBadgeLoading = false;
      state.error = null;
      state.badge = action.payload;
    },
    [fetchBadge.rejected.type]: (state, action) => {
      state.isBadgeLoading = false;
      state.error = action.payload;
    },
    [fetchBadgeCategories.pending.type]: (state) => {
      state.isBadgeCategoriesLoading = true;
    },
    [fetchBadgeCategories.fulfilled.type]: (state, action) => {
      state.isBadgeCategoriesLoading = false;
      state.error = null;
      state.badgeCategories = action.payload.results;
    },
    [fetchBadgeCategories.rejected.type]: (state, action) => {
      state.isBadgeCategoriesLoading = false;
      state.error = action.payload;
    },
    [createBadgeCategory.pending.type]: (state) => {
      state.isBadgeCategoryCreating = true;
    },
    [createBadgeCategory.fulfilled.type]: (state, action) => {
      state.isBadgeCategoryCreating = false;
      state.error = null;
      state.badgeCategorySelected = action.payload;
    },
    [createBadgeCategory.rejected.type]: (state, action) => {
      state.isBadgeCategoryCreating = false;
      state.error = action.payload;
    },

    [fetchBadgeRating.pending.type]: (state) => {
      state.isBadgeRatingLoading = true;
    },
    [fetchBadgeRating.fulfilled.type]: (state, action) => {
      state.isBadgeRatingLoading = false;
      state.error = null;
      state.badgeRating = action.payload.results;
    },
    [fetchBadgeRating.rejected.type]: (state, action) => {
      state.isBadgeRatingLoading = false;
      state.error = action.payload;
    },

    [updateAliveStatus.pending.type]: (state) => {
      state.isBadgeRatingLoading = true;
    },
    [updateAliveStatus.fulfilled.type]: (state, action) => {
      state.isBadgeRatingLoading = false;
      state.error = null;
    },
    [updateAliveStatus.rejected.type]: (state, action) => {
      state.isBadgeRatingLoading = false;
    },

    [setToZero.pending.type]: (state) => {
      state.isBadgeRatingLoading = true;
    },
    [setToZero.fulfilled.type]: (state, action) => {
      state.isBadgeRatingLoading = false;
      state.error = null;
    },
    [setToZero.rejected.type]: (state, action) => {
      state.isBadgeRatingLoading = false;
    },

    [getPaymentLinks.pending.type]: (state) => {
      // state.isBadgeCategoryCreating = true;
    },
    [getPaymentLinks.fulfilled.type]: (state, action) => {
      // state.isBadgeCategoryCreating = false;
      state.error = null;
      state.paymentLinks = action.payload;
    },
    [getPaymentLinks.rejected.type]: (state, action) => {
      // state.isBadgeCategoryCreating = false;
      state.error = action.payload;
    },
    [fetchSquidRanking.pending.type]: (state) => {
      state.isSquidRankingLoading = true;
    },
    [fetchSquidRanking.fulfilled.type]: (state, action) => {
      state.isSquidRankingLoading = false;
      state.error = null;
      state.squidRanking = action.payload.results;
    },
    [fetchSquidRanking.rejected.type]: (state, action) => {
      state.isSquidRankingLoading = false;
      state.error = action.payload;
    },
    [kaspiCreate.pending.type]: (state) => {
      state.isKaspiPaymentLoading = true;
    },
    [kaspiCreate.fulfilled.type]: (state, action) => {
      state.isKaspiPaymentLoading = false;
      state.error = null;
      state.kaspiPayment = action.payload;
    },
    [kaspiCreate.rejected.type]: (state, action) => {
      state.isKaspiPaymentLoading = false;
      state.error = action.payload;
    },
    [kaspiMockCreate.pending.type]: (state) => {
      state.isKaspiPaymentLoading = true;
    },
    [kaspiMockCreate.fulfilled.type]: (state, action) => {
      state.isKaspiPaymentLoading = false;
      state.error = null;
      state.kaspiPayment = action.payload;
    },
    [kaspiMockCreate.rejected.type]: (state, action) => {
      state.isKaspiPaymentLoading = false;
      state.error = action.payload;
    },

    [mocksFormCreate.pending.type]: (state) => {
      state.isLoading = true;
    },
    [mocksFormCreate.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.mocksForm = action.payload;
    },
    [mocksFormCreate.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [fetchGradesAdmin.pending.type]: (state) => {
      state.isGradesAdminLoading = true;
    },
    [fetchGradesAdmin.fulfilled.type]: (state, action) => {
      state.isGradesAdminLoading = false;
      state.error = null;
      state.gradesAdmin = action.payload.results;
    },
    [fetchGradesAdmin.rejected.type]: (state, action) => {
      state.isGradesAdminLoading = false;
      state.error = action.payload;
    },

    [fetchAdminCourses.pending.type]: (state) => {
      state.isCourseLoading = true;
    },
    [fetchAdminCourses.fulfilled.type]: (state, action) => {
      state.isCourseLoading = false;
      state.error = null;
      state.courses = action.payload.results;
    },
    [fetchAdminCourses.rejected.type]: (state, action) => {
      state.isCourseLoading = false;
      state.error = action.payload;
    },

    [fetchAdminSections.pending.type]: (state) => {
      state.isSectionLoading = true;
    },
    [fetchAdminSections.fulfilled.type]: (state, action) => {
      state.isSectionLoading = false;
      state.error = null;
      state.sections = action.payload;
    },
    [fetchAdminSections.rejected.type]: (state, action) => {
      state.isSectionLoading = false;
      state.error = action.payload.results;
    },

    [fetchAdminLessons.pending.type]: (state) => {
      state.isLessonLoading = true;
    },
    [fetchAdminLessons.fulfilled.type]: (state, action) => {
      state.isLessonLoading = false;
      state.error = null;
      state.lessons = action.payload;
    },
    [fetchAdminLessons.rejected.type]: (state, action) => {
      state.isLessonLoading = false;
      state.error = action.payload;
    },

    [fetchUsersByCourseAdmin.pending.type]: (state) => {
      state.isCourseUsersAdminLoading = true;
    },
    [fetchUsersByCourseAdmin.fulfilled.type]: (state, action) => {
      state.isCourseUsersAdminLoading = false;
      state.totalCount = action.payload.count;
      state.course_users = action.payload.results;
      state.error = null;
    },
    [fetchUsersByCourseAdmin.rejected.type]: (state, action) => {
      state.isCourseUsersAdminLoadingg = false;
      state.error = action.payload;
    },
    [giveAccessMockAdmin.pending.type]: (state) => {
      state.isCourseUsersAdminLoading = true;
    },
    [giveAccessMockAdmin.fulfilled.type]: (state, action) => {
      state.isCourseUsersAdminLoading = false;
      state.error = null;
    },
    [giveAccessMockAdmin.rejected.type]: (state, action) => {
      state.isCourseUsersAdminLoading = false;
      state.error = action.payload;
    },
    [setUsersByCoursePageChange.fulfilled.type]: (state, action) => {
      state.currentPage = action.payload;
      state.offset = (state.currentPage - 1) * state.pageSize;
    },
    [setUsersByCoursePageSizeChange.fulfilled.type]: (state, action) => {
      state.pageSize = action.payload;
    },
    [createCourseReview.pending.type]: (state) => {
      state.isReviewLoading = true;
    },
    [createCourseReview.fulfilled.type]: (state, action) => {
      state.isReviewLoading = false;
      state.reviews.reviews = [...state.reviews.reviews, action.payload];
      state.reviews.is_sent = true;
      state.error = null;
    },
    [createCourseReview.rejected.type]: (state, action) => {
      state.isReviewLoading = false;
      state.error = action.payload;
    },

    [fetchCourseReviews.pending.type]: (state) => {
      state.isReviewLoading = true;
    },
    [fetchCourseReviews.fulfilled.type]: (state, action) => {
      state.isReviewLoading = false;
      state.error = null;
      state.reviews = action.payload;
    },
    [fetchCourseReviews.rejected.type]: (state, action) => {
      state.isReviewLoading = false;
      state.error = action.payload;
    },
  },
});

export const { setCourseNotifier } = courseSlice.actions;

export default courseSlice.reducer;
