import { CourseSidebarAccordionNavLink, CourseSidebarAccordionList } from "@components/common";
import { COURSE_ROUTE, COURSE_ONBOARDING_ROUTE } from "@utils/consts";
import "./CourseSidebarAccordion.scss"
export default function CourseSidebarAccordion({
  route,
  icon,
  name,
  courseId,
  isPathnameAccordion,
  isPathnameWorkshop,
  isAccordionOpen,
  setIsAccordionOpen,
  course,
  pathname,
  value,
  mockExams
}) {
  const getListItems = (value) => {
    if (!course || !course.sections || !mockExams) return [];
    switch (value) {
      case "course-material":
        return course.sections ?? [];
      case "workshop":
        return course.workshops ?? [];
        case "mock":
          return mockExams ?? [];
      default:
        return [];
    }
  };

  const items = getListItems(value);
  // courses always have at least 1 item (guide)
  const hasItems = value !== "workshop" || items.length > 0;

  const handleAccordionClick = (e) => {
    setIsAccordionOpen((prevState) => !prevState);
    if (isPathnameAccordion) {
      e.preventDefault();
    }
  };

  const isCurrentPath = (value, id) => {
    switch (value) {
      case "course-material":
        return `${COURSE_ROUTE.replace(":courseId", courseId)}/sections/${id}`;
      case "workshop":
        return `${COURSE_ROUTE.replace(":courseId", courseId)}/workshops/${id}`;
      default:
        return "";
    }
  };
  
  return (
    <>
      <CourseSidebarAccordionNavLink 
        handleAccordionClick={handleAccordionClick}
        route={route}
        courseId={courseId}
        isPathnameAccordion={isPathnameAccordion}
        icon={icon}
        name={name}
        showArrow={hasItems}
        isAccordionOpen={isAccordionOpen}
      />
      {hasItems && isAccordionOpen && (
        <CourseSidebarAccordionList
          items={items}
          value={value}
          courseId={courseId}
          isPathnameWorkshop={isPathnameWorkshop}
          isCurrentPath={isCurrentPath}
          course={course}
          pathname={pathname}
        />
      )}
    </>
  );
}
