import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { useAction, useTranslate } from "@helpers/hooks";
import { COURSE_ROUTE } from "@utils/consts";
import { currentWorkshop, formatData, getIntro } from "@utils/functions";
import { CustomLink, List } from "@components/common";
import { useIsMobile } from "@helpers/hooks";
import "./Workshops.scss";
import ReactMarkdown from "react-markdown";

export default function Workshops() {
  const { t } = useTranslate(),
    isMobile = useIsMobile(),
    { courseId } = useParams(),
    { fetchWorkshops } = useAction(),
    { workshops } = useSelector((state) => state.courses),
    [currentWorkshopId, setCurrentWorkshopId] = useState(null),
    [upcomingWorkshops, setUpcomingWorkshops] = useState([]),
    intro = getIntro(courseId);

  useEffect(() => {
    fetchWorkshops(courseId);
  }, []);

  useEffect(() => {
    if (workshops) {
      const negativeTimeDifferences = currentWorkshop(workshops);
      if (negativeTimeDifferences && negativeTimeDifferences.length > 0) {
        setCurrentWorkshopId(negativeTimeDifferences[0].id);
        setUpcomingWorkshops(negativeTimeDifferences ?? []);
      }
    }
  }, [workshops]);

  return (
    <div>
      <div key={intro.id} className="step">
        <div className="step__subtitle-group">
          <h4 className={`${isMobile && "step__title-mobile"} step__title`}>{t(intro.workshops.title)}</h4>
          <p className="step__description">{t(intro.workshops.description)}</p>
         {intro.workshops.time && <ReactMarkdown className="step__description">{t(intro.workshops.time)}</ReactMarkdown>}
        </div>
        {!currentWorkshopId ? (
          <div className="workshop__first">
            <p className="workshop__text">{t("LMS.NO_UPCOMING_WORKSHOPS")}</p>
          </div>
        ) : (
          <List
            className={"workshop"}
            items={upcomingWorkshops}
            renderItem={(item) => (
              <li key={item.id} className={classNames(item.id === +currentWorkshopId && "order-first")}>
                <CustomLink isExternal={true} newTab={true} to={`${COURSE_ROUTE.replace(":courseId", courseId)}/workshops/${item.id}`}>
                  {item.id === +currentWorkshopId ? (
                    <div className="workshop__first">
                      <p className="workshop__label">{t("LMS.UPCOMING")}</p>
                      <p className="workshop__text">
                        {formatData(item?.datetime, 2)}, {item.title}
                      </p>
                    </div>
                  ) : (
                    <div className="workshop__default">
                      <div className="workshop__divider" />
                      <p className="workshop__text">
                        {formatData(item?.datetime, 2)}, {item.title}
                      </p>
                    </div>
                  )}
                </CustomLink>
              </li>
            )}
          />
        )}
      </div>
    </div>
  );
}
