import { Icon } from "@components/common";
import { useTranslate } from "@helpers/hooks";
import classNames from "classnames";
import "./DropdownOption.scss";

export default function DropdownOption({
  showOptions,
  selectedOption,
  placeholder,
  showOptionsHandler,
  icon,
}) {
  const { t } = useTranslate();
  return (
    <div
      className={classNames("dropdown-option", { active: showOptions })}
      onClick={showOptionsHandler}
    >
      <div className={"dropdown-option__name"}>
        {icon && <Icon name={icon} />}
        {selectedOption.name !== undefined &&
        selectedOption.value !== undefined &&
        selectedOption.value !== null ? (
          <p className={"dropdown-option__text"}>{t(selectedOption.name)}</p>
        ) : (
          <p className={"dropdown-option__text-placeholder"}>{placeholder}</p>
        )}
      </div>
      <Icon name={showOptions ? "arrow-up" : "arrow-down"} />
    </div>
  );
}
