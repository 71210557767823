import './HeroProgressBar.scss';

const HeroProgressBar = ({completed}) => {
    
  const fillerStyles = {
    width: `${completed}%`,
  }

  return (
    <div className="hero-progress">
        <div className='hero-progress__container'>
            <div className="hero-progress__filler" style={fillerStyles}></div>
        </div>
    </div>
  );
};

export default HeroProgressBar;