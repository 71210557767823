import { useAction, useTranslate } from "@helpers/hooks";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "./CoinHistory.scss";
import {
  BtnLoader,
  Icon,
  List,
  NewModalLayout,
} from "@components/common";
import { formatData } from "@utils/functions";

export default function CoinHistory({ handleClose }) {
  const { t } = useTranslate();
  const { fetchCoinsHistory } = useAction();
  const { history, isLoading } = useSelector((state) => state.coins);

  useEffect(() => fetchCoinsHistory(), []);

  if (isLoading) {
    return <BtnLoader className={"btn-loader-bg-large"} />;
  }
  
  return (
    <NewModalLayout className={"coins__modal"}>
      <div className="coins__title">
        <h3 className="">{t("LMS.RANKING.HISTORY")}</h3>
        <Icon
          name="close-rounded"
          className="lms-badge-profile__close"
          handleClick={handleClose}
        />
      </div>
      <h3></h3>
      <div>
        <List
          className={"coin__history-list"}
          items={history}
          renderItem={(item) => (
            <li key={item.date_time} className="coin__history-item">
              <h4>{item.record}</h4>
              <p>{formatData(item.date_time, 4)}</p>
            </li>
          )}
        />
      </div>
    </NewModalLayout>
  );
}
