import supportBg from "../../../../assets/support_bg.png"; // Adjust the path based on your project structure
import { Button, Icon, Image, NewModalLayout } from "@components/common";
import { accompaniment } from "@utils/consts";
import { useState } from "react";
import { AmoForm } from "@components/feature/AmoForm";
import { useTranslate } from "@helpers/hooks";
import "./DashboardSupport.scss";

export default function DashboardSupport() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslate();

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      className="support__div"
      style={{
        backgroundImage: `url(${supportBg})`,
      }}
    >
      <div className="support__main">
        <div className="support__div-1">
          <div className="support__logo">
            <Image
              name="logo_stp_small.png"
              alt="Logo STP"
              className={"support__logo-img"}
            />
            <Image
              name="support_lines.png"
              alt="Lines"
              className={"support__lines"}
            />
          </div>
          <div className="support__title">{t("SUPPORT.TITLE")}</div>
          <div className="support__subtitle">{t("SUPPORT.DESCRIPTION")}</div>
        </div>
        <div className="support__div-2">
          {accompaniment.map((item, index) => (
            <div key={index + "-" + item.id} className="support__bullets">
              {" "}
              <div>
                <Icon name={"lock"} />
              </div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="support__line" />
      <div className="support__buy">
        <div className="support__buy-text">{t("SUPPORT.TEXT")}</div>
        <div>
          <div className="support__button-div">
            <Button
              text={"Получить полное сопровождение"}
              className="btn--primary support__button"
              onClick={() => setIsModalOpen(true)}
            />
            <Image
              name="support_lines2.png"
              alt="Lines2"
              className={"support__button-lines"}
            />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <NewModalLayout>
          <div className="update-modal">
            <div className="update-modal__content">
              <h2 className="update-modal__title">
                {t("SUPPORT.REQUEST_TITLE")}
              </h2>
              <p className="update-modal__subtitle">
                {t("SUPPORT.REQUEST_DESCRIPTION")}
              </p>
              <Icon
                name="close-rounded"
                className="update-modal__close"
                handleClick={handleClose}
              />
              <AmoForm onClose={handleClose} buttonClass="btn--blue" />
            </div>
          </div>
        </NewModalLayout>
      )}
    </div>
  );
}
