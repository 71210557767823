import { BtnLoader } from "@components/common";
import { useAction, useIsMobile, useTranslate } from "@helpers/hooks";
import { formatData } from "@utils/functions";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "./DashboardPerformance.scss";

export default function DashboardPerformance() {
  const { t } = useTranslate();
  const { fetchPerformance } = useAction();
  const { performance, isPerformanceLoading } = useSelector(
    (state) => state.profile
  );

  const isMobile = useIsMobile();

  useEffect(() => {
    fetchPerformance();
  }, []);

  return (
    <div className="performance">
      {!isMobile && (
        <h2 className="dashboard__subheading">{t("PERFORMANCE.TITLE")}</h2>
      )}
      {isPerformanceLoading ? (
        <BtnLoader className={"btn-loader--primary"} />
      ) : (
        performance && (
          <div className="performance__table">
            {!isMobile && <div className="performance__header">
              <div className="performance__row">
                <div className="performance__cell">{t("PERFORMANCE.COMMENT")}</div>
                <div className="performance__cell">{t("PERFORMANCE.DATE")}</div>
              </div>
            </div>}
            <div className="performance__body">
              {performance.map((item) => (
                <div className="performance__row" key={item.id}>
                  <div className="performance__cell">{item?.comment}</div>
                  <div className="performance__cell performance__cell--date">
                    {formatData(item?.datetime, 2)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      )}
    </div>
  );
}
