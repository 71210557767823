import { createSlice } from "@reduxjs/toolkit";
import {
  getUsers,
  getUser,
  getUsersIeltsGrades,
  getUsersNuetGrades,
  getUsersSatGrades,
  postUserPerformance,
  setUsersByPageChange,
  setUsersByPageSizeChange,
} from "@store/actions/adminAction";

const initialState = {
  isLoading: false,
  error: null,
  users: [],
  user: null,
  ieltsGrades: [],
  nuetGrades: [],
  satGrades: [],
  totalCount: 0,
  pageSize: 10,
  currentPage: 1,
  offset: 0,
  error: null,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: {
    [postUserPerformance.pending.type]: (state) => {
      state.isLoading = true;
    },
    [postUserPerformance.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
    },
    [postUserPerformance.rejected.type]: (state, action) => {
      state.isLoading = false;
    },
    [getUsers.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getUsers.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.totalCount = action.payload.count;
      state.users = action.payload.results;
      state.error = null;
    },
    [getUsers.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getUser.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getUser.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.error = null;
    },
    [getUser.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getUsersIeltsGrades.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getUsersIeltsGrades.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.ieltsGrades = action.payload;
      state.error = null;
    },
    [getUsersIeltsGrades.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getUsersNuetGrades.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getUsersNuetGrades.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.nuetGrades = action.payload;
      state.error = null;
    },
    [getUsersNuetGrades.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getUsersSatGrades.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getUsersSatGrades.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.satGrades = action.payload;
      state.error = null;
    },
    [getUsersSatGrades.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [setUsersByPageChange.fulfilled.type]: (state, action) => {
      state.currentPage = action.payload;
      state.offset = (state.currentPage - 1) * state.pageSize;
    },
    [setUsersByPageSizeChange.fulfilled.type]: (state, action) => {
      state.pageSize = action.payload;
    },
  },
});

export default adminSlice.reducer;
