import React from "react";
import { Button, NewModalLayout } from "@components/common";
import { useIsMobile, useTranslate } from "@helpers/hooks";
import { useSelector } from "react-redux";

export default function MockWarningModal({ handleModal, handleButtonAnyway }) {
  const isMobile = useIsMobile();
  const { t } = useTranslate();
  const { isLoading } = useSelector((state) => state.mocks);
  return (
    <NewModalLayout
      className={`pop-up ${
        isMobile ? `tariff__pop-up-mobile` : `tariff__pop-up`
      }`}
    >
      <div className="tariff__title-button">
        <h3 className="">{t("MOCK.LATER_TITLE")}</h3>
      </div>

      <p>{t("MOCK.LATER_WARNING")}</p>

      <div className="popup__buttons">
        <Button
          className="button-secondary popup__button"
          text={t("MOCK.CANCEL")}
          onClick={() => handleModal()}
        />

        <Button
          className="button-primary popup__button"
          text={t("MOCK.SUBMIT_ANYWAY")}
          onClick={() => handleButtonAnyway()}
          loading={isLoading}
        />
      </div>
    </NewModalLayout>
  );
}
