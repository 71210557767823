import React from "react";
import { BtnLoader, Pagination } from "@components/common";
import { useAction, useTranslate } from "@helpers/hooks";
import "./AdminMockSatTable.scss";
import { useSelector } from "react-redux";

export default function AdminMockSatTable({ mockGrades, isLoading }) {
  const { t } = useTranslate();
  const { setSatGradesPageChange, setSatGradesPageSizeChange } = useAction();
  const { satGradesTotalCount, satGradesPageSize, satGradesCurrentPage } =
    useSelector((state) => state.mocks);

  return (
    <div className="mock-admin">
      {mockGrades?.length > 0 ? (
        <div>
          {" "}
          <div className="mock-table">
            <div className="mock-table__header">
              <div className="mock-table__cell--student">Student</div>
              <div className="mock-table__cell">Math </div>
              <div className="mock-table__cell">Verbal </div>
              <div className="mock-table__cell">Total</div>
              <div className="mock-table__cell">Exam started at</div>
            </div>
            {mockGrades.map((grade) => {
              const totalScore =
                grade.total_math_score + grade.total_verbal_score;
              return (
                <div key={grade.user_id} className="mock-table__row">
                  <div className="mock-table__cell--student">
                    <img
                      src={grade.user_image}
                      alt={grade.user_name}
                      className="mock-table__user-image"
                    />
                    <span>{grade.user_name}</span>
                  </div>
                  <div className="mock-table__cell">
                    {grade.total_math_score}
                  </div>
                  <div className="mock-table__cell">
                    {grade.total_verbal_score}
                  </div>
                  <div className="mock-table__cell">{totalScore}</div>
                  <div className="mock-table__cell">
                    {new Date(grade.created_at).toLocaleString()}
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <Pagination
              totalCount={satGradesTotalCount}
              pageSize={satGradesPageSize}
              currentPage={satGradesCurrentPage}
              className={"university-list__pagination"}
              siblingCount={0}
              onPageChange={(page) => setSatGradesPageChange(page)}
              onPageSizeChange={(pageSize) =>
                setSatGradesPageSizeChange(pageSize)
              }
            />
          </div>
          <div className="mock-table__space" />
        </div>
      ) : (
        isLoading && <BtnLoader className="btn-loader-bg-large" />
      )}
    </div>
  );
}
