const getCourseLevelTabs = (courses, TABS) => {
  const availableTabs = new Set([TABS.ALL]);

  courses.forEach((course) => {
    switch (course.level) {
      case "beginner":
        availableTabs.add(TABS.BEGINNER);
        break;
      case "intermediate":
        availableTabs.add(TABS.INTERMEDIATE);
        break;
      case "upper_intermediate":
        availableTabs.add(TABS.UPPER_INTERMEDIATE);
        break;
      default:
        break;
    }
  });

  const tabOrder = [
    TABS.ALL,
    TABS.BEGINNER,
    TABS.INTERMEDIATE,
    TABS.UPPER_INTERMEDIATE,
  ];

  return tabOrder.filter((tab) => availableTabs.has(tab));
};

export default getCourseLevelTabs;
