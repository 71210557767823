export * from "./activities";
export * from "./degrees";
export * from "./educations";
export * from "./essays";
export * from "./genders";
export * from "./graduationYears";
export * from "./ieltsScores";
export * from "./links";
export * from "./locales";
export * from "./majors";
export * from "./olympiads";
export * from "./orders";
export * from "./preferences";
export * from "./resources";
export * from "./routes";
export * from "./selects";
export * from "./status";
export * from "./studyLevels";
export * from "./mockSections";
export * from "./contacts";
export * from "./quotes";
export * from "./texts";
export * from "./mockTypes";
