import { Icon } from "@components/common";

const SidebarListItemIcon = ({
  id,
  value,
  isFirstItem,
  courseRoute,
  isPathnameWorkshop,
  isCurrentPath,
  attendance,
  completed,
  pathname,
}) => {
  return (
    <>
      {isFirstItem ? (
        <div className="sidebar__dropdown-item_circle">
          {pathname === courseRoute ? (
            <Icon name={"intro-filled"} className="icon--completed-smaller" />
          ) : (
            <Icon name={"intro"} className="icon--completed-smaller" />
          )}
        </div>
      ) : isPathnameWorkshop ? (
        completed || (attendance && attendance[0]?.attended) ? (
          <div className="sidebar__dropdown-item_circle-completed">
            <Icon name={"completed-section"} className="icon--completed-smaller" />
          </div>
        ) : (
          <Icon className={pathname === isCurrentPath(value, id) ? "sidebar__dropdown-item_circle-active" : "sidebar__dropdown-item_circle"} />
        )
      ) : 
        completed || (attendance && attendance[0]?.attended) ? (
          <div className="sidebar__dropdown-item_circle-completed">
            <Icon name={"completed-section"} className="icon--completed-smaller" />
          </div>
        ) : (
          <Icon className={pathname === isCurrentPath(value, id) ? "sidebar__dropdown-item_circle-active" : "sidebar__dropdown-item_circle"} />
        )
   
      }
    </>
  );
};

export default SidebarListItemIcon;
