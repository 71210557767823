import { PROFILE_ROUTE } from "@utils/consts";
import { useEffect } from "react";
import { Route } from "react-router-dom/cjs/react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

export default function ProtectedRoute({
  component: Component,
  isTeacher,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isTeacher ? <Component {...props} /> : <Redirect to={PROFILE_ROUTE} />
      }
    />
  );
}
