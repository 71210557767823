// People would probably write with comma, which is string in JS, not number
const IELTS_SCORES = [
    {id: 17, name: "9,0", value: 9},
    {id: 16, name: "8,5", value: 8.5},
    {id: 15, name: "8,0", value: 8},
    {id: 14, name: "7,5", value: 7.5},
    {id: 13, name: "7,0", value: 7},
    {id: 12, name: "6,5", value: 6.5},
    {id: 11, name: "6,0", value: 6},
    {id: 10, name: "5,5", value: 5.5},
    {id: 9, name: "5,0", value: 5},
    {id: 8, name: "4,5", value: 4.5},
    {id: 7, name: "4,0", value: 4},
    {id: 6, name: "3,5", value: 3.5},
    {id: 5, name: "3,0", value: 3},
    {id: 4, name: "2,5", value: 2.5},
    {id: 3, name: "2,0", value: 2},
    {id: 2, name: "1,5", value: 1.5},
    {id: 1, name: "1,0", value: 1},
];

export {IELTS_SCORES};
