// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add_modal_content {
  min-width: 360px;
  padding: 20px !important;
}

@media (max-width: 768px) {
  .add_modal_content {
    min-width: 200px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/feature/MockExam/MockModals/MockFormModal/MockFormModal.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,wBAAA;AACF;;AAEA;EACE;IACE,gBAAA;EACF;AACF","sourcesContent":[".add_modal_content {\n  min-width: 360px;\n  padding: 20px !important;\n}\n\n@media (max-width: 768px) {\n  .add_modal_content {\n    min-width: 200px;\n    \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
