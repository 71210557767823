import { useEffect, useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CourseCard, UniversityDetailsTabs } from "@components/feature";
import { PaymentService } from "@api/services";
import { List, BtnLoader, PageTitle, Search } from "@components/common";
import { useIsMobile, useTranslate, useWindowSize } from "@helpers/hooks";
import { getAvailableTabs, getCourseLevelTabs } from "@utils/functions";
import "./CourseContent.scss";
import { useSelector } from "react-redux";

export default function CourseContent({ courses }) {
  const TABS = {
    MY: "LMS.COURSE.TABS.MY",
    ALL: "LMS.COURSE.TABS.ALL",
    IELTS: "LMS.COURSE.TABS.IELTS",
    SAT: "LMS.COURSE.TABS.SAT",
    NUET: "LMS.COURSE.TABS.NUET",
    ENGLISH: "LMS.COURSE.TABS.ENGLISH",
    PROF_ORIENT: "LMS.COURSE.TABS.PROF_ORIENT",
    UNIVER_ADMISSION: "LMS.COURSE.TABS.UNIVER_ADMISSION",
  };

  const LEVEL_TABS = {
    ALL: "LMS.COURSE.LEVEL.ALL",
    BEGINNER: "LMS.COURSE.LEVEL.BEGINNER",
    INTERMEDIATE: "LMS.COURSE.LEVEL.INTERMEDIATE",
    UPPER_INTERMEDIATE: "LMS.COURSE.LEVEL.UPPER_INTERMEDIATE",
  };

  const { isLoading } = useSelector((state) => state.courses);

  const [searchInput, setSearchInput] = useState("");
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isPriceLoading, setIsPriceLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(TABS.ALL);
  const [currentLevelTab, setCurrentLevelTab] = useState(LEVEL_TABS.ALL);

  const { t } = useTranslate();
  const location = useLocation();
  const history = useHistory();
  const isMobile = useIsMobile();
  const [filterShow, setFilterShow] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const cost = queryParams.get("OutSum");
    const invoiceId = queryParams.get("InvId");
    const signature = queryParams.get("SignatureValue");

    if (!cost || !invoiceId || !signature) return;

    const data = {
      cost: cost,
      invoice_id: invoiceId,
      signature: signature,
    };

    const postPayment = async () => {
      try {
        await PaymentService.postPaymentSuccess(0, data);
        history.push("/courses");
      } catch (error) {}
    };
    postPayment();
  }, [location.search, history]);

  useEffect(() => {
    if (!isMobile) {
      setFilterShow(false);
    }
  }, [isMobile]);

  const availableTabs = useMemo(
    () => getAvailableTabs(courses, TABS),
    [courses]
  );

  const courseLevelTabs = useMemo(
    () => getCourseLevelTabs(courses, LEVEL_TABS),
    [courses]
  );

  const filteredCourses = useMemo(() => {
    return courses.filter((course) => {
      const titleMatches = course.title
        .toLowerCase()
        .includes(searchInput.toLowerCase());
      const authorMatches =
        !selectedAuthor ||
        selectedAuthor === "All" ||
        course.author.name === selectedAuthor;
      const courseMatches =
        !selectedCategory ||
        selectedCategory === "All" ||
        course.status === selectedCategory;
      const tabMatches =
        currentTab === TABS.ALL
          ? true
          : currentTab === TABS.IELTS
          ? course.type === "ielts"
          : currentTab === TABS.SAT
          ? course.type === "sat"
          : currentTab === TABS.NUET
          ? course.type === "nuet"
          : currentTab === TABS.ENGLISH
          ? course.type === "english"
          : currentTab === TABS.PROF_ORIENT
          ? course.type === "prof_orient"
          : currentTab === TABS.UNIVER_ADMISSION
          ? course.type === "univer_admission"
          : currentTab === TABS.MY
          ? course.enrolled
          : false;

      const levelTabMatches =
        currentLevelTab === LEVEL_TABS.ALL
          ? true
          : currentLevelTab === LEVEL_TABS.BEGINNER
          ? course.level === "beginner"
          : currentLevelTab === LEVEL_TABS.INTERMEDIATE
          ? course.level === "intermediate"
          : currentLevelTab === LEVEL_TABS.UPPER_INTERMEDIATE
          ? course.level === "upper_intermediate"
          : false;

      return (
        titleMatches &&
        authorMatches &&
        courseMatches &&
        tabMatches &&
        levelTabMatches
      );
    });
  }, [
    courses,
    searchInput,
    selectedAuthor,
    selectedCategory,
    currentTab,
    currentLevelTab,
  ]);

  return (
    <div className="courses__group">
      <PageTitle title={t("LMS.TITLE")} />
      <Search
        className={isMobile ? "courses_mobile__search" : "courses__search"}
        label={t("LMS.COURSE.SEARCH_COURSE")}
        placeholder={t("FIELD.START_SEARCH")}
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
      <UniversityDetailsTabs
        classNameTabs={"course-content__tabs first-uni-tabs"}
        classNameTab={"course-content__tab"}
        tabs={availableTabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      <UniversityDetailsTabs
        classNameTabs={"course-content__tabs second-uni-tabs"}
        classNameTab={"course-content__tab"}
        tabs={courseLevelTabs}
        currentTab={currentLevelTab}
        setCurrentTab={setCurrentLevelTab}
      />
      {isLoading ? (
        <BtnLoader className={"btn-loader-bg-large "} />
      ) : (
        filteredCourses.length > 0 && (
          <List
            className={isMobile ? "courses_mobile__list" : "courses__list"}
            items={filteredCourses}
            renderItem={(course) => (
              <CourseCard
                key={course.id}
                course={course}
                setIsPriceLoading={setIsPriceLoading}
              />
            )}
          />
        )
      )}

      {isPriceLoading && <BtnLoader className="btn-loader-bg-large" />}
    </div>
  );
}
