import { axiosRequest } from "@api/xhr";

export default class AdminService {
  static async postUserPerformance(data) {
    return await axiosRequest.post(
      "lms_admin/users/performance-comment/create/",
      data
    );
  }

  static async getUsers(data) {
    return await axiosRequest.get("lms_admin/users/", data);
  }

  static async getUser(id) {
    return await axiosRequest.get(`lms_admin/users/${id}/`);
  }

  static async getUsersIeltsGrades(id) {
    return await axiosRequest.get(`lms_admin/users/grades/ielts-mock/${id}/`);
  }

  static async getUsersNuetGrades(id) {
    return await axiosRequest.get(`lms_admin/users/grades/nuet-mock/${id}/`);
  }

  static async getUsersSatGrades(id) {
    return await axiosRequest.get(`lms_admin/users/grades/sat-mock/${id}/`);
  }
}
