import React, { useEffect } from "react";
import { YMInitializer } from "react-yandex-metrika";
import ym from "react-yandex-metrika";
import { useHistory } from "react-router-dom";
import { env } from "../../../configs";
import Cookies from "js-cookie";

const YandexMetrikaContainer = () => {
  const history = useHistory();
  const YM_COUNTER_ID = env === "production" ? 97886547 : 97836876;

  const decodeToken = (token) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => `%${("00" + c.charCodeAt(0).toString(16)).slice(-2)}`)
          .join("")
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error("Invalid token:", error);
      return null;
    }
  };

  const sendYandexHit = (url, userId) => {
    if (userId) {
      ym("hit", url, { params: { user_id: userId } }); 
    } else {
      ym("hit", url);
    }
  };

  useEffect(() => {
    const token = Cookies.get("access_token"); 
    const decodedToken = token ? decodeToken(token) : null;
    const userId = decodedToken ? decodedToken.user_id : undefined;

    const hit = (url) => {
      sendYandexHit(url, userId);
    };

    const unlisten = history.listen((location) => {
      hit(location.pathname + location.search);
    });

    hit(window.location.pathname + window.location.search);

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <YMInitializer
      accounts={[YM_COUNTER_ID]}
      options={{
        defer: true,
        webvisor: true,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
      }}
      version="2"
    />
  );
};

export default YandexMetrikaContainer;
