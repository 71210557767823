import {Fragment} from "react";
import {useSelector} from "react-redux";
import {useFormik} from "formik";
import {useAction, useTranslate} from "@helpers/hooks";
import {updatePasswordSchema} from "@utils/schemas";
import {Button, Form, Input, ValidationError} from "@components/common";
import "../PasswordModal.scss";

export default function PasswordChange({setOpen, next}) {
    const {t} = useTranslate(),
        {profile} = useSelector(state => state.profile),
        {updateProfile} = useAction();

    const onSubmit = async (values) => {
        try {
            const res = await updateProfile({
                password: values.currentPassword,
                new_password: values.password,
                new_password_confirm: values.confirmPassword
            });
            if (!res.error) {
                next();
                handleReset(initialValues);
                resetForm();
            }
        } catch (e) {
            console.log(e.message);
        }
    };

    const {initialValues, values, errors, touched, handleSubmit, handleChange, handleBlur, handleReset, resetForm, isSubmitting} = useFormik({
        initialValues: {
            currentPassword: "",
            password: "",
            confirmPassword: ""
        },
        validationSchema: updatePasswordSchema,
        validateOnBlur: true,
        onSubmit
    });

    const initialInputs = [
        {id: 1, label: t("SETTINGS.CURRENT_PASSWORD"), name: "currentPassword", value: values.currentPassword, maxLength: 20},
        {id: 2, label: t("SETTINGS.NEW_PASSWORD"), name: "password", value: values.password, maxLength: 20},
        {id: 3, label: t("SETTINGS.REPEAT_PASSWORD"), name: "confirmPassword", value: values.confirmPassword, maxLength: 20}
    ], buttons = [
        {id: 1, text: t("BUTTON.CANCEL"), className: "btn--link", type: "button", handleClick: () => setOpen(false)},
        {id: 2, text: t("BUTTON.APPLY"), className: "btn--primary btn--pill", type: "submit", loading: isSubmitting}
    ];
    return (
        <Form onSubmit={handleSubmit}>
            {
                initialInputs.map((input) => <Fragment key={input.id}>
                    <Input
                        key={input.id}
                        className={"input--ghost password-modal__input"}
                        labelClassName={"label--ghost"}
                        label={input.label}
                        name={input.name}
                        type={"password"}
                        placeholder={t("FIELD.ENTER_PASSWORD")}
                        maxLength={input.maxLength}
                        value={input.value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <ValidationError
                        errors={errors}
                        touched={touched}
                        name={input.name}
                    />
                </Fragment>)
            }
            <div className={"password-modal__btn"}>
                {
                    buttons.map((button) => (
                        <Button
                            key={button.id}
                            className={button.className}
                            type={button.type}
                            text={button.text}
                            width={143}
                            height={46}
                            loading={button.loading}
                            onClick={button.handleClick}
                        />
                    ))
                }
            </div>
        </Form>
    );
};