export const accompaniment = [
  {
    id: 1,
    text: "Определение ваших интересов, сильных и слабых сторон",
  },
  {
    id: 2,
    text: "Анализ и подготовка внешкольной деятельности и достижений",
  },
  {
    id: 3,
    text: "Персональный подбор вузов, стипендий и программ",
  },
  {
    id: 4,
    text: "Составление стратегии поступления",
  },
  {
    id: 5,
    text: "Заполнение всех заявок",
  },
  {
    id: 6,
    text: "Подготовка документов",
  },
  {
    id: 7,
    text: "Помощь с мотивационными и рекомендательными письмами",
  },
  {
    id: 8,
    text: "Ведение переговоров с вузами",
  },
  {
    id: 9,
    text: "Подготовка к интервью",
  },
  {
    id: 10,
    text: "Постоянная связь с менторами и кураторами",
  },
];
