import { List } from "@components/common";
import { SidebarListItem } from '@components/common';
import { useTranslate } from "@helpers/hooks";
import { COURSE_ONBOARDING_ROUTE } from "@utils/consts";

const CourseSidebarAccordionList = ({ items, value, courseId, isPathnameWorkshop, isCurrentPath, course, pathname }) => {
  const  { t } = useTranslate(),
        courseRoute = `${COURSE_ONBOARDING_ROUTE.replace(":courseId", courseId)}`;
  return (
    <div className={"sidebar__accordion"}>
      {course && (
        <List
          className={"sidebar__dropdown-list"}
          firstItem={
            !isPathnameWorkshop && (
              <SidebarListItem
                title={t("LMS.ACCORDION.TITLE")}
                value={value}
                courseRoute={courseRoute}
                pathname={pathname}
                isFirstItem={true}
                isPathnameWorkshop={isPathnameWorkshop}
              />
            )
          }
          items={items}
          renderItem={({ id, title, completed, attendance }) => {
          return (
            <SidebarListItem
              key={id}
              id={id}
              title={title}
              value={value}
              courseRoute={courseRoute}
              attendance={attendance} 
              pathname={pathname}
              completed={completed}
              isFirstItem={false}
              isCurrentPath={isCurrentPath}
              isPathnameWorkshop={isPathnameWorkshop}
              />
            )
          }}
        />
      )}
        </div>
  );
};
export default CourseSidebarAccordionList;