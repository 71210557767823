import React, { useState } from "react";
import "./UniAgentBanner.scss";
import {
  Button,
  NewModalLayout,
  AutoComplete,
  Icon,
  List,
  Image,
} from "@components/common";
import { useAction, useTranslate } from "@helpers/hooks";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { KASPI_PAYMENT_ROUTE } from "@utils/consts";
import { notify } from "@utils/functions";

export default function UniAgentBanner() {
  const { t } = useTranslate();
  const [isModal, setIsModal] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [isClear, setIsClear] = useState(false);

  const { directoryUniversities } = useSelector((state) => state.directory);
  const { fetchDirectoryUniversities, uniAgentKaspiCreate } = useAction();

  const POINTS = [
    t("UNI_AGENT.POINT_1"),
    t("UNI_AGENT.POINT_2"),
    t("UNI_AGENT.POINT_3"),
    t("UNI_AGENT.POINT_4"),
  ];

  const history = useHistory();

  const handleRedirectToKaspi = () => {
    history.push(
      KASPI_PAYMENT_ROUTE.replace(":type", "accompaniment")
        .replace(":courseId", 0)
        .replace(":packageId", 0)
        .replace(":courseName", t("UNI_AGENT.TITLE"))
        .replace(":packageType", 0)
        .replace(":price", 24990)
    );
  };

  const handleFilter = (option, name) => {
    setSelectedFilters((prev) => ({ ...prev, [name]: option }));
  };

  const handleClear = (name) => {
    setSelectedFilters((prev) => ({ ...prev, [name]: [] }));
  };

  const handleSubmit = async () => {
    const selectedUniversities = selectedFilters.universities || [];
    if (selectedUniversities.length < 1 || selectedUniversities.length > 5) {
      notify("Вы можете выбрать от 1 до 5 университетов", "error"); // TODO: Translate this
      return;
    }
    const data = {
      universities: selectedUniversities.map((uni) => uni.value),
    };

    await uniAgentKaspiCreate(data);
    handleRedirectToKaspi();
  };

  return (
    <div className="agent__banner">
      <div className="agent__content">
        <h2 className="agent__heading">{t("UNI_AGENT.TITLE")}</h2>

        <List
          items={POINTS}
          className="agent__points"
          renderItem={(item, index) => (
            <li key={index} className="agent__point">
              {item}
            </li>
          )}
        />

        <Button
          text={t("UNI_AGENT.BUTTON")}
          className="agent__banner-btn btn--pill"
          onClick={() => setIsModal(true)}
        />

        {isModal && (
          <NewModalLayout>
            <div className="agent__modal">
              {" "}
              <div className="agent__modal-header">
                <h2 className="agent__modal-heading">{t("UNI_AGENT.TITLE")}</h2>
                <Icon
                  name="modal-close"
                  handleClick={() => setIsModal(false)}
                />
              </div>
              <p className="agent__description">
                {t("UNI_AGENT.TITLE_DESCRIPTION")}
              </p>
              <div className="agent__modal-universities">
                <AutoComplete
                  label="Выберите университеты"
                  name="universities"
                  placeholder={t("FIELD.NOT_CHOSEN")}
                  disabled={false}
                  isClear={isClear}
                  setIsClear={setIsClear}
                  suggestions={directoryUniversities}
                  method={fetchDirectoryUniversities}
                  handleChange={(option) =>
                    handleFilter(option, "universities")
                  }
                  handleClear={handleClear}
                  selectedSuggestion={selectedFilters.universities || []}
                  setSelectedFilters={setSelectedFilters}
                  selectedFilters={selectedFilters}
                  isOne={false}
                />
              </div>
              <div className="agent__modal-footer">
                <Button
                  text="Отправить"
                  className="btn--primary btn--pill"
                  onClick={handleSubmit}
                  disabled={
                    !selectedFilters.universities ||
                    selectedFilters.universities.length < 1
                  }
                />
              </div>
            </div>
          </NewModalLayout>
        )}
      </div>
      <Image
        name="support_bell.png"
        alt="support bell"
        className={"agent__banner-bell"}
      />
    </div>
  );
}
