const STUDY_LEVELS = (t) => [
    {id: 0, name: t("common:EDUCATIONS_STATUS.SCHOOL"), value: "school"},
    {id: 1, name: t("common:EDUCATIONS_STATUS.BACHELORS"), value: "bachelor"},
    {id: 2, name: t("common:EDUCATIONS_STATUS.MASTERS"), value: "magistracy"},
    {id: 3, name: t("common:EDUCATIONS_STATUS.PHD"), value: "doctor"},
    {id: 4, name: t("common:EDUCATIONS_STATUS.DIFFERENT"), value: "al_studies"},
    {id: 5, name: t("common:EDUCATIONS_STATUS.DONT_KNOW"), value: "dont_know"}
];

// TODO: Find more elegant way to do this rather than passing t and than re-creating STUDY_LEVELS
const STUDY_LEVELS_VALUE_TO_NAME_MAP = (t) => STUDY_LEVELS(t).reduce((acc, curr) => {
    acc[curr.value] = curr.name;
    return acc;
}, {});

export {STUDY_LEVELS, STUDY_LEVELS_VALUE_TO_NAME_MAP};
