const GRADUATION_YEARS = [
    {id: 0, name: "2020", value: 2020},
    {id: 1, name: "2021", value: 2021},
    {id: 2, name: "2022", value: 2022},
    {id: 3, name: "2023", value: 2023},
    {id: 4, name: "2024", value: 2024},
    {id: 5, name: "2025", value: 2025},
    {id: 6, name: "2026", value: 2026},
    {id: 7, name: "2027", value: 2027},
    {id: 8, name: "2028", value: 2028},
    {id: 9, name: "2029", value: 2029},
    {id: 10, name: "2030", value: 2030},
    {id: 11, name: "2031", value: 2031},
    {id: 12, name: "2032", value: 2032},
    {id: 13, name: "2033", value: 2033},
    {id: 14, name: "2034", value: 2034},
    {id: 15, name: "2035", value: 2035},
    {id: 16, name: "2036", value: 2036},
    {id: 17, name: "2037", value: 2037},
    {id: 18, name: "2038", value: 2038},
    {id: 19, name: "2039", value: 2039},
    {id: 20, name: "2040", value: 2040},
]

export {GRADUATION_YEARS};

