// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reviews__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reviews__modal-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.reviews__modal-container .star-rating svg {
  width: 40px;
  height: 40px;
}
.reviews__modal-input {
  width: 100% !important;
}
.reviews__modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reviews__modal-title {
  font-size: 24px;
  font-weight: 700;
}
.reviews__modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.reviews__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
@media (max-width: 768px) {
  .reviews__list {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 50px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Courses/CourseReviewsContent/CourseReviewsContent.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAFJ;AAMI;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAJN;AAMQ;EACE,WAAA;EACA,YAAA;AAJV;AASI;EACE,sBAAA;AAPN;AAUI;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AARN;AAWI;EACE,eAAA;EACA,gBAAA;AATN;AAYI;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAVN;AAcE;EAEE,aAAA;EACA,qCAAA;EACA,SAAA;AAbJ;AAeI;EANF;IAOI,qCAAA;IACA,mBAAA;EAZJ;AACF","sourcesContent":["@import \"@styles/variables\";\n\n.reviews {\n  &__header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n\n  &__modal {\n    &-container {\n      display: flex;\n      flex-direction: column;\n      gap: 20px;\n     .star-rating {\n        svg {\n          width: 40px;\n          height: 40px;\n        }\n      }\n    }\n\n    &-input {\n      width: 100% !important;\n    }\n\n    &-header {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n    }\n\n    &-title {\n      font-size: 24px;\n      font-weight: 700;\n    }\n\n    &-content {\n      display: flex;\n      flex-direction: column;\n      gap: 20px;\n    }\n  }\n\n  &__list {\n\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 20px;\n\n    @media (max-width: 768px) {\n      grid-template-columns: repeat(1, 1fr);\n      margin-bottom: 50px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
