import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { ProfileShort } from "@components/feature/Profile/ProfileSummary/ProfileShort";
import { AcademicResults } from "@components/feature/Profile/ProfileSummary/AcademicResults";
import {
  DashboardChanceContent,
  DashboardMobile,
  DashboardMockSecton,
  DashboardProftest,
  DashboardProftestV2,
  DashboardSkeleton,
  DashboardWritingBuilder,
  ProfileEdit,
  TourGuide,
  UniAgentBanner,
  UniversityDetailsTabs,
  WelcomeQuestionnaire,
} from "@components/feature";

import { DashboardCourses, DashboardPerformance } from "@components/feature";
import { useAction, useIsMobile, useTranslate } from "@helpers/hooks";
import { trackAmplitude } from "@utils/functions";
import "./DashboardContentV2.scss";
import { Button, PageTitle } from "@components/common";
import React from "react";
import { COOKIES_DOMAIN } from "@utils/consts";
import { DashboardSupport } from "../DashboardSupport";
import { ProfileSupport } from "@components/feature/Profile/ProfileSummary/ProfileSupport";
import { ProfileSupportModal } from "@components/feature/Profile/ProfileSummary/ProfileSupportModal";

const TABS = {
  DASHBOARD: "Dashboard",
  TEST: "Career test",
  PROFILE: "Profile",
  PERFORMANCE: "Performance",
  SUPPORT: "Full accompaniment",
};

export default function DashboardContentV2() {
  const defaultProgram = 148333;
  const isMobile = useIsMobile();
  const { t } = useTranslate();
  const [open, setOpen] = useState(false);

  const [isQuestionnaireOpen, setIsQuestionnaireOpen] = useState(false);
  const { successRate, isProgramLoading } = useSelector(
    (state) => state.program
  );
  const { myEducation, myAdditionalInfo } = useSelector(
    (state) => state.questionnaire
  );
  const { profile, proftest } = useSelector((state) => state.profile);
  const isQuestionnaireCompleted = myEducation?.lang_tests?.length !== 0;

  const {
    fetchProgramSuccessRate,
    fetchDirectoryUniversities,
    fetchSimplePrograms,
    fetchMyEducation,
    fetchMyPrefer,
    fetchMyAdditionalInfo,
    fetchCountries,
    fetchSubjects,
    updateEducation,
    submitProftest,
    fetchProftestResults,
  } = useAction();

  const [isShowEdit, setIsShowEdit] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    university: [],
    program: [],
  });

  const [selectedTab, setSelectedTab] = useState(TABS.DASHBOARD);

  useEffect(() => {
    fetchMyEducation();
    startTour();
    handleProftest();
  }, []);

  useEffect(() => {
    trackAmplitude("profile_page_visit", {
      description: 'Пользователь зашел на страницу "Профиль пользователя"',
    });
  }, []);

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (myEducation && myEducation.program) {
      fetchProgramSuccessRate(myEducation.program);
    } else if (myEducation && myEducation.program === null) {
      fetchProgramSuccessRate(defaultProgram);
    }
  }, [myEducation?.program]);

  useEffect(() => {
    if (successRate) {
      setSelectedFilters(transformSuccessRateToFilters(successRate));
    }
  }, [successRate]);

  useEffect(() => {
    if (selectedFilters?.university[0]?.value && !isProgramLoading) {
      fetchSimplePrograms({ university: selectedFilters.university[0].value });
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (
      selectedFilters.university.length > 0 &&
      selectedFilters.program.length > 0
    ) {
      updateEducation({ program: selectedFilters.program[0].value });
    }
  }, [selectedFilters.program]);

  async function handleProftest() {
    const profanswers = Cookies.get("profanswers");
    if (profanswers) {
      await submitProftest(profanswers);
      Cookies.remove("profanswers", { domain: COOKIES_DOMAIN, path: "/" });
    } else {
      fetchProftestResults();
    }
  }

  function transformSuccessRateToFilters(rate) {
    return {
      university: [
        { name: rate?.university[0]?.title, value: rate?.university[0]?.id },
      ],
      program: [{ name: rate?.program_name, value: rate?.program }],
    };
  }

  useEffect(() => {
    if (isShowEdit) {
      fetchCountries();
    }
  }, [isShowEdit]);

  function fetchInitialData() {
    fetchMyEducation();
    fetchMyAdditionalInfo();
    fetchMyPrefer();
    // fetchCountries();
    fetchSubjects();
    fetchDirectoryUniversities();
  }

  function startTour() {}

  return (
    <>
      <ProfileSupportModal open={open} setOpen={setOpen} />
      <div className={"dashboard"}>
        <UniversityDetailsTabs
          classNameTabs="dashboard__tabs"
          classNameTab="university-details__tab"
          tabs={Object.values(TABS)}
          currentTab={selectedTab}
          setCurrentTab={setSelectedTab}
        />

        {selectedTab === TABS.TEST && (
          <section className="dashboard__section-row">
            <DashboardProftestV2 proftest={proftest} />
          </section>
        )}

        {selectedTab === TABS.DASHBOARD && (
          <section className="dashboard__section-dashboard">
            <div>
              <UniAgentBanner />
            </div>
            {/* <div>
              <ProfileSupport
                handleClick={() => setSelectedTab(TABS.SUPPORT)}
              />
            </div> */}
            <div className="dashboard__grid-top">
              <DashboardWritingBuilder />
              <DashboardMockSecton />
            </div>
            <DashboardCourses />
          </section>
        )}

        <section className="dashboard__section">
          {profile?.is_new_user && <TourGuide />}
          {myEducation && profile && myAdditionalInfo && (
            <>
              {isMobile && <PageTitle isLogo isLanguage />}
              {/* {isMobile && <DashboardMobile />} */}
              <div
                className={
                  isMobile
                    ? "dashboard__section-two-mobile"
                    : "dashboard__section-two"
                }
              >
                {selectedTab === TABS.PROFILE && (
                  <section className="dashboard__section-column-full">
                    {" "}
                    <section className="dashboard__section-row">
                      <div className="dashboard__section-column">
                        <ProfileShort handleEdit={() => setIsShowEdit(true)} />
                        <AcademicResults data={successRate} />
                        {isShowEdit && (
                          <ProfileEdit
                            handleClose={() => setIsShowEdit(false)}
                          />
                        )}
                      </div>
                      <div
                        className={
                          isMobile
                            ? "dashboard__section-two-mobile"
                            : "dashboard__section-column"
                        }
                      >
                        <div
                          className={"dashboard__section-two__content"}
                          style={{
                            filter: isQuestionnaireCompleted
                              ? "none"
                              : "blur(5px)",
                          }}
                        >
                          <DashboardChanceContent
                            data={successRate}
                            myEducation={myEducation}
                            selectedFilters={selectedFilters}
                            setSelectedFilters={setSelectedFilters}
                          />
                        </div>
                        {!isQuestionnaireCompleted && (
                          <div className={"dashboard__section-two__btn"}>
                            <h1 className={"dashboard__section-two__btn-title"}>
                              {t("DASHBOARD.CHANCES.TEXT_4")}
                            </h1>
                            <Button
                              className={"btn--primary btn--pill"}
                              text={t("DASHBOARD.CHANCES.TEXT_5")}
                              height={46}
                              onClick={() => setIsQuestionnaireOpen(true)}
                            />
                          </div>
                        )}
                        {isQuestionnaireOpen && (
                          <WelcomeQuestionnaire
                            setIsQuestionnaireOpen={setIsQuestionnaireOpen}
                          />
                        )}
                      </div>
                    </section>
                  </section>
                )}
              </div>
            </>
          )}
        </section>

        {selectedTab === TABS.PERFORMANCE && (
          <section className="dashboard__section-row">
            <DashboardPerformance />
          </section>
        )}

        {selectedTab === TABS.SUPPORT && (
          <section className="dashboard__section-dashboard">
            <DashboardSupport />
          </section>
        )}
      </div>
      {!myEducation && <DashboardSkeleton />}
    </>
  );
}
