import { Fragment, Suspense, useState } from "react";
import { Article, CourseSidebar, Header, Sidebar } from "@components/common";
import { useLocation } from "react-router-dom";
import { WaitListBanner } from "@components/feature";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getNavigationData } from "@utils/functions";
import getAdminNavigationData from "@utils/consts/getAdminNavigationData";
import "./Layout.scss";

export default function Layout({ children }) {
  const { pathname } = useLocation();

  const history = useHistory();
  const { profile } = useSelector((state) => state.profile);
  const isTeacher = profile?.is_teacher;

  const [isAdminMode, setIsAdminMode] = useState(
    !!localStorage.getItem("admin_mode") && isTeacher
  );

  const turnOffAdminMode = () => {
    const navRoutes = getNavigationData(
      () => "",
      0,
      () => false,
    );
    localStorage.removeItem("admin_mode");
    setIsAdminMode(false);
    history.push(navRoutes[0].route);
  };

  const turnOnAdminMode = () => {
    if (isTeacher) {
      const navRoutes = getAdminNavigationData(() => "");
      localStorage.setItem("admin_mode", true);
      setIsAdminMode(true);
      history.push(navRoutes[0].route);
    }
  };

  return (
    <Fragment>
      {!pathname.includes("ongoing") && (
        <Header
          isAdminMode={isAdminMode}
          turnOnAdminMode={turnOnAdminMode}
          turnOffAdminMode={turnOffAdminMode}
          isTeacher={isTeacher}
        />
      )}

      {!pathname.includes("ongoing") &&
        !pathname.includes("practice") &&
        !pathname.includes("courses/") && (
          <Sidebar isAdminMode={isAdminMode} isTeacher={isTeacher} />
        )}
      {pathname.includes("courses/") &&
        !pathname.includes("practice") &&
        !pathname.includes("ongoing") && <CourseSidebar />}

      {/* <UpdateModal /> */}

      <WaitListBanner />

      <Article className={"private-layout__article"}>
        <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
      </Article>
    </Fragment>
  );
}
