import { SidebarListItemNavLink, SidebarListItemIcon } from '@components/common';

const SidebarListItem = ({
    id,
    title,
    value,
    courseRoute,
    attendance,
    pathname,
    completed,
    isFirstItem, 
    isCurrentPath,
    isPathnameWorkshop,
  }) => {

  if(isFirstItem) {
    return (
      <li key="introduction-item"
        className={ pathname === courseRoute
                    ? "sidebar__dropdown-item-active" 
                    : "sidebar__dropdown-item"}
        >
          <SidebarListItemIcon
            isFirstItem={isFirstItem}
            courseRoute={courseRoute}
            pathname={pathname}
            isPathnameWorkshop={isPathnameWorkshop}
          />
          <SidebarListItemNavLink 
            route={courseRoute}
            title={title}
            isActive={pathname.includes(courseRoute)}
            pathname={pathname}
          />
        </li> 
    );
  }
  
  return (
    <li key={id} 
        className={pathname===isCurrentPath
                    ? "sidebar__dropdown-item-active" 
                    : "sidebar__dropdown-item"}
      >
        <SidebarListItemIcon
          id={id}
          value={value}
          isFirstItem={isFirstItem}
          attendance={attendance}
          completed={completed}
          isPathnameWorkshop={isPathnameWorkshop}
          isCurrentPath={isCurrentPath}
          pathname={pathname}
        />
        <SidebarListItemNavLink
          route={isCurrentPath(value, id)}
          title={title}
          isActive={pathname.includes(`sections/${id}`) || pathname.includes(`workshops/${id}`)  || pathname.includes(`mock/${id}`)}
        />
    </li>
  );
}

export default SidebarListItem;
