import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL } from "../../../configs";
import { ACCESS_TOKEN, COOKIES_DOMAIN, STP_OPEN_SIDE } from "../../../utils/consts";

export let accessToken = Cookies.get(ACCESS_TOKEN);

const axiosRequest = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "application/json",
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }), 
    },
});


axiosRequest.interceptors.request.use(
    (request) => {
        accessToken = Cookies.get(ACCESS_TOKEN);
        if (accessToken) {
            request.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return request;
    },
    (error) => Promise.reject(error)
);


axiosRequest.interceptors.response.use(
    (response) => {
        return response.data; 
    },
    (error) => {
        console.log("Error in axios response", error);
        if (error.response && error.response.status === 401) {
            Cookies.remove(ACCESS_TOKEN, { domain: COOKIES_DOMAIN });
            window.location.href = `${STP_OPEN_SIDE}/auth`; 
        }
        return Promise.reject(error);
    }
);

export default axiosRequest;
