import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProfileService } from "@api/services";
import { findError, notify } from "@utils/functions";

const fetchProfile = createAsyncThunk("profile/fetch", async (thunkAPI) => {
  try {
    return await ProfileService.fetchProfile();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const updateProfile = createAsyncThunk(
  "profile/update",
  async (data, thunkAPI) => {
    try {
      return await ProfileService.updateProfile(data);
    } catch (e) {
      notify(findError(e.response.data), "error");
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);
const updateNewUserStatus = createAsyncThunk(
  "profile/updateStatus",
  async (thunkAPI) => {
    try {
      return await ProfileService.updateNewUserStatus();
    } catch (e) {
      notify(findError(e.response.data), "error");
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);
const submitProftest = createAsyncThunk(
  "profile/submitProftest",
  async (data, thunkAPI) => {
    try {
      return await ProfileService.submitProftest(data);
    } catch (e) {
      notify(findError(e.response.data), "error");
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);
const fetchProftestResults = createAsyncThunk(
  "profile/getProftestResults",
  async (_, thunkAPI) => {
    try {
      return await ProfileService.fetchProftestResults();
    } catch (e) {
      // if (e.response?.status === 404) {
      //   notify(
      //     "Вы еще не прошли карьерный тест? Начните прямо сейчас и узнайте больше о своих сильных сторонах!",
      //     ""
      //   );
      // } else {
      //   notify(findError(e.response.data), "error");
      // }
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

const fetchFeedback = createAsyncThunk(
  "profile/fetchFeedback",
  async (thunkAPI) => {
    try {
      return await ProfileService.fetchFeedback();
    } catch (e) {
      notify(findError(e.response.data), "error");
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

const submitFeedback = createAsyncThunk(
  "profile/submitFeedback",
  async ({ id, data }, thunkAPI) => {
    try {
      return await ProfileService.submitFeedback(id, data);
    } catch (e) {
      notify(findError(e.response.data), "error");
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

const submitWaitlist = createAsyncThunk(
  "profile/submitWaitlist",
  async (data, thunkAPI) => {
    try {
      return await ProfileService.submitWaitlist(data);
    } catch (e) {
      notify(findError(e.response.data), "error");
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

const fetchPerformanceAndMockGrades = createAsyncThunk(
  "profile/fetchPerformanceAndMockGrades",
  async (_, thunkAPI) => {
    try {
      return await ProfileService.fetchPerformanceAndMockGrades();
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export {
  fetchProfile,
  updateProfile,
  updateNewUserStatus,
  submitProftest,
  fetchProftestResults,
  fetchPerformanceAndMockGrades,
  fetchFeedback,
  submitFeedback,
  submitWaitlist,
};
