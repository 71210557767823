import React from "react";
import { Button, NewModalLayout } from "@components/common";
import { useTranslate } from "@helpers/hooks";

export default function CompletedIeltsModal({ handleClosePopup }) {
  const { t } = useTranslate();
  return (
    <NewModalLayout className="level-up">
      <h1 className="level-up__title">{t("MOCK.CONGRATS")}</h1>
      <p>{t("MOCK.CONGRATS_TEXT")}</p>
      <Button
        text={t("LMS.BADGES.LETS_GO")}
        className="btn--secondary btn--pill level-up__btn"
        onClick={handleClosePopup}
      />
    </NewModalLayout>
  );
}
