import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "@helpers/hooks";
import { Icon, List } from "@components/common";
import { useIsMobile } from "@helpers/hooks";
import { STUDY_LEVELS_VALUE_TO_NAME_MAP } from "@utils/consts";
import "./ProfileShort.scss";

export default function ProfileShort({ handleEdit }) {
  const isMobile = useIsMobile();
  const { t } = useTranslate()
  const { myEducation } = useSelector((state) => state.questionnaire)
  const { profile } = useSelector((state) => state.profile)
  const PROFILE_VALUES = useMemo(
      () => [
        {
          id: 1,
          key: t("PROFILE.EDUCATIONS.CURRENT_STUDY_LEVEL_SHORT"),
          value: STUDY_LEVELS_VALUE_TO_NAME_MAP(t)[myEducation.study_level],
        },
        {
          id: 2,
          key: t("PROFILE.EDUCATIONS.STUDY_PLACE_SHORT"),
          value: t(`${myEducation.study_place}`),
          mobile: true,
        },
        {
          id: 3,
          key: t("PROFILE.EDUCATIONS.CURRENT_STUDY_YEAR_SHORT"),
          value: t(`${myEducation.current_study_year}`),
        },
      ],
      [myEducation, profile, t]
    );

  return (
    <div className={"profile_short_card"}>
      {!isMobile && (
        <div className="profile_short_card-header">
          <h2 className="dashboard__subheading">Анкета</h2>
          <div className="profile_edit_icon" onClick={handleEdit}>
            <Icon name="edit-filled" />
          </div>
        </div>
      )}
      <div className="profile_short_card_center">
        <div className="profile_avatar_edit">
          {profile.profile_photo ? (
            <img className="avatar" src={profile.profile_photo} alt="avatar" />
          ) : (
            <div className="profile_avatar_placeholder_circle">
              <p className="profile_avatar_placeholder_circle_text">
                {profile.name && profile.name[0]}
                {profile.surname && profile.surname[0]}
              </p>
            </div>
          )}
        </div>
        <p
          className={"profile_short_card_name"}
        >{`${profile.name} ${profile.surname}`}</p>
      </div>
      <div className="profile_short_card_column">
        <List
          className="profile_short_card_list"
          items={
            isMobile
              ? PROFILE_VALUES.filter((item) => item.mobile)
              : PROFILE_VALUES
          }
          renderItem={(item) => {
            return (
              <div key={item.id} className={"profile_short_card_row"}>
                {!isMobile && (
                  <p className="profile_short_card_key">{item.key}</p>
                )}
                <p key={item.id} className="profile_short_card_value">
                  {item.value}
                </p>
              </div>
            );
          }}
        />
      </div>
      {isMobile && <Icon name="arrow_next" handleClick={handleEdit} />}
    </div>
  );
}
