import { MockService } from "@api/services";
import { createAsyncThunk } from "@reduxjs/toolkit";

const fetchMockExams = createAsyncThunk("mocks/fetchAll", async (thunkAPI) => {
  try {
    const res = await MockService.fetchMockExams();
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchMockExam = createAsyncThunk(
  "mocks/fetchOne",
  async (id, thunkAPI) => {
    try {
      const res = await MockService.fetchMockExam(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const fetchCrit = createAsyncThunk("mocks/fetchCrit", async (id, thunkAPI) => {
  try {
    const res = await MockService.fetchCrit(id);
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});
const fetchMath = createAsyncThunk("mocks/fetchMath", async (id, thunkAPI) => {
  try {
    const res = await MockService.fetchMath(id);
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const submitMath = createAsyncThunk(
  "mocks/submitMath",
  async ({ id, data }, thunkAPI) => {
    try {
      const res = await MockService.submitMath(id, data);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const submitCrit = createAsyncThunk(
  "mocks/submitCrit",
  async ({ id, data }, thunkAPI) => {
    try {
      const res = await MockService.submitCrit(id, data);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const getProgress = createAsyncThunk(
  "mocks/getProgress",
  async (id, thunkAPI) => {
    try {
      const res = await MockService.getProgress(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const fetchVerbalOne = createAsyncThunk(
  "mocks/fetchVerbalOne",
  async (id, thunkAPI) => {
    try {
      const res = await MockService.fetchVerbalOne(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const fetchVerbalTwo = createAsyncThunk(
  "mocks/fetchVerbalTwo",
  async (id, thunkAPI) => {
    try {
      const res = await MockService.fetchVerbalTwo(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const fetchMathOne = createAsyncThunk(
  "mocks/fetchMathOne",
  async (id, thunkAPI) => {
    try {
      const res = await MockService.fetchMathOne(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const fetchMathTwo = createAsyncThunk(
  "mocks/fetchMathTwo",
  async (id, thunkAPI) => {
    try {
      const res = await MockService.fetchMathTwo(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const getSatProgress = createAsyncThunk(
  "mocks/getSatProgress",
  async (id, thunkAPI) => {
    try {
      const res = await MockService.getSatProgress(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const submitVerbalOne = createAsyncThunk(
  "mocks/submitVerbalOne",
  async ({ id, data }, thunkAPI) => {
    try {
      const res = await MockService.submitVerbalOne(id, data);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const submitVerbalTwo = createAsyncThunk(
  "mocks/submitVerbalTwo",
  async ({ id, data }, thunkAPI) => {
    try {
      const res = await MockService.submitVerbalTwo(id, data);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const submitMathOne = createAsyncThunk(
  "mocks/submitMathOne",
  async ({ id, data }, thunkAPI) => {
    try {
      const res = await MockService.submitMathOne(id, data);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const submitMathTwo = createAsyncThunk(
  "mocks/submitMathTwo",
  async ({ id, data }, thunkAPI) => {
    try {
      const res = await MockService.submitMathTwo(id, data);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const submitListening = createAsyncThunk(
  "mocks/submitListening",
  async ({ id, data }, thunkAPI) => {
    try {
      const res = await MockService.submitListening(id, data);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const submitWriting = createAsyncThunk(
  "mocks/submitWriting",
  async ({ id, data }, thunkAPI) => {
    try {
      const res = await MockService.submitWriting(id, data);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const submitReading = createAsyncThunk(
  "mocks/submitReading",
  async ({ id, data }, thunkAPI) => {
    try {
      const res = await MockService.submitReading(id, data);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const submitSpeaking = createAsyncThunk(
  "mocks/submitSpeaking",
  async ({ id, data }, thunkAPI) => {
    try {
      const res = await MockService.submitSpeaking(id, data);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const fetchListening = createAsyncThunk(
  "mocks/fetchListening",
  async (id, thunkAPI) => {
    try {
      const res = await MockService.fetchMockListening(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const fetchWriting = createAsyncThunk(
  "mocks/fetchWriting",
  async (id, thunkAPI) => {
    try {
      const res = await MockService.fetchMockWriting(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const fetchSpeaking = createAsyncThunk(
  "mocks/fetchSpeaking",
  async (id, thunkAPI) => {
    try {
      const res = await MockService.fetchMockSpeaking(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const fetchReading = createAsyncThunk(
  "mocks/fetchReading",
  async (id, thunkAPI) => {
    try {
      const res = await MockService.fetchMockReading(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const getIeltsProgress = createAsyncThunk(
  "mocks/getIeltsProgress",
  async (id, thunkAPI) => {
    try {
      const res = await MockService.getIeltsProgress(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const fetchIeltsMockGrades = createAsyncThunk(
  "mocks/fetchIeltsMockGrades",
  async (id, thunkAPI) => {
    try {
      const res = await MockService.fetchIeltsMockGrades(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const fetchIeltsMockResponse = createAsyncThunk(
  "mocks/fetchIeltsMockResponse",
  async ({ userId, mockId }, thunkAPI) => {
    try {
      const res = await MockService.fetchIeltsMockResponse(userId, mockId);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const updateIeltsMockGrade = createAsyncThunk(
  "mocks/updateIeltsMockGrade",
  async ({ userId, mockId, data }, thunkAPI) => {
    try {
      const res = await MockService.updateIeltsMockGrade(userId, mockId, data);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const fetchNuetMockGrades = createAsyncThunk(
  "mocks/fetchNuetMockGrades",
  async (id, thunkAPI) => {
    try {
      const res = await MockService.fetchNuetMockGrades(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const fetchSatMockGrades = createAsyncThunk(
  "mocks/fetchSatMockGrades",
  async ({ id, params }, thunkAPI) => {
    try {
      const res = await MockService.fetchSatMockGrades(id, params);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const fetchUncheckedIeltsMockResponses = createAsyncThunk(
  "mocks/fetchUncheckedIeltsMockResponses",
  async (_, thunkAPI) => {
    try {
      const res = await MockService.fetchUncheckedIeltsMockResponses();
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const createMockReview = createAsyncThunk(
  "mocks/createMockReview",
  async ({ id, data }, thunkAPI) => {
    try {
      const res = await MockService.createMockReview(id, data);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const fetchMockReviews = createAsyncThunk(
  "mocks/fetchMockReviews",
  async (id, thunkAPI) => {
    try {
      const res = await MockService.fetchMockReviews(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const fetchAllMockReviews = createAsyncThunk(
  "mocks/fetchAllMockReviews",
  async (_, thunkAPI) => {
    try {
      const res = await MockService.fetchAllMockReviews();
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const fetchMockUsers = createAsyncThunk(
  "mocks/fetchMockUsers",
  async (id, thunkAPI) => {
    try {
      const res = await MockService.fetchMockUsers(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const setMockUsersPageChange = createAsyncThunk(
  "mocks/pageChange",
  async (currentPage, thunkAPI) => {
    try {
      return currentPage;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const setMockUsersPageSizeChange = createAsyncThunk(
  "mocks/pageSizeChange",
  async (pageSize, thunkAPI) => {
    try {
      return pageSize;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const deleteMockResponse = createAsyncThunk(
  "mocks/deleteMockResponse",
  async (data, thunkAPI) => {
    try {
      const res = await MockService.deleteMockResponse(data);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const setSatGradesPageChange = createAsyncThunk(
  "mocks/satPageChange",
  async (currentPage, thunkAPI) => {
    try {
      return currentPage;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const setSatGradesPageSizeChange = createAsyncThunk(
  "mocks/satPageSizeChange",
  async (pageSize, thunkAPI) => {
    try {
      return pageSize;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export {
  fetchMockExams,
  fetchMockExam,
  fetchCrit,
  fetchMath,
  submitMath,
  submitCrit,
  getProgress,
  fetchMathOne,
  fetchMathTwo,
  fetchVerbalTwo,
  fetchVerbalOne,
  getSatProgress,
  submitVerbalOne,
  submitVerbalTwo,
  submitMathOne,
  submitMathTwo,
  submitListening,
  submitWriting,
  submitSpeaking,
  submitReading,
  fetchListening,
  fetchWriting,
  fetchReading,
  fetchSpeaking,
  getIeltsProgress,
  fetchIeltsMockGrades,
  fetchIeltsMockResponse,
  updateIeltsMockGrade,
  fetchNuetMockGrades,
  fetchSatMockGrades,
  fetchUncheckedIeltsMockResponses,
  createMockReview,
  fetchMockReviews,
  fetchAllMockReviews,
  fetchMockUsers,
  setMockUsersPageChange,
  setMockUsersPageSizeChange,
  deleteMockResponse,
  setSatGradesPageChange,
  setSatGradesPageSizeChange,
};
