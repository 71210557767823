import { env } from "../../configs";

const DASHBOARD_ROUTE = "/";
const SETTINGS_ROUTE = "/settings";
const PROFILE_ROUTE = "/";
const RECOMMENDATIONS_ROUTE = "/recommendations";
const CATALOG_ROUTE = "/catalog";
const FAVORITES_ROUTE = "/favorites";
const APPLICATION_ROUTE = "/application";
const LEARNING_PLATFORM_ROUTE = "/courses";
const COURSE_ROUTE = "/courses/:courseId";
const COURSE_LESSON_ROUTE =
  "/courses/:courseId/sections/:sectionId/lessons/:lessonId";
const COURSE_LESSON_TEST_ROUTE =
  "/courses/:courseId/sections/:sectionId/lessons/:lessonId/practice/:practiceId";
const ESSAY_ROUTE = "/essay";
const MORE_ROUTE = "/more";
const PROFILE_MOBILE = "/profile-mobile";
const LANGUAGE_MOBILE = "/language-mobile";
// const RECOMMENDATION_LETTTER_ROUTE = "/recommendation-letter"
// const UNIVERSITY_VISITS_ROUTE = "/university-visits"
// const ASSESMENTS_ROUTE = "/assesments"
const SCHOLARSHIPS_ROUTE = "/scholarships";
const SCHOLARSHIPS_PROFILE_ROUTE = `${SCHOLARSHIPS_ROUTE}/:profileId`;
const WORKSHOP_MAIN_ROUTE = `${COURSE_ROUTE}/workshops`;
const WORKSHOP_ROUTE = `${COURSE_ROUTE}/workshops/:workshopId`;
const COURSE_GRADES_ROUTE = `${COURSE_ROUTE}/grades`;
const COURSE_NOTES_ROUTE = `${COURSE_ROUTE}/notes`;
const COURSE_RANKING_ROUTE = `${COURSE_ROUTE}/ranking`;
const COURSE_REVIEWS_ROUTE = `${COURSE_ROUTE}/reviews`;
const COURSE_INFO_ROUTE = `${COURSE_ROUTE}/info`;
const COURSE_SECTION_ROUTE = `${COURSE_ROUTE}/sections/:sectionId`;
const COURSE_ONBOARDING_ROUTE = `${COURSE_ROUTE}/sections/0/onboarding`;
const MARATHON_ADMIN = `${LEARNING_PLATFORM_ROUTE}/marathon/admin`;

const MOCK_EXAMS = `/mock`;
const MOCK_EXAM = `/mock/:mockId`;
const MOCK_EXAM_ONGOING = `${MOCK_EXAM}/ongoing`;

const BLOG_ROUTE = "/blog";
const BLOG_ARTICLE_ROUTE = `${BLOG_ROUTE}/:blogId`;
const KASPI_PAYMENT_ROUTE =
  "/:type/payment-kaspi/:courseId/:courseName/:packageId/:packageType/:price";

const RESOURCES_ROUTE = "/resources";
const STP_OPEN_SIDE =
  env === "production"
    ? "https://smartestprep.com/"
    : "https://dev-smartestprep.com/";

const CHALLENGES_ROUTE = "/challenges";
const CHALLENGE_ROUTE = "/challenges/:challengeId";
const CHALLENGES_ROUTE_ONGOING = `${CHALLENGE_ROUTE}/ongoing`;

const MOCK_EXAMS_ADMIN_ROUTE = "/admin/exams";
const GRADES_ADMIN_ROUTE = "/admin/grades";
const MOCK_ACCESS_ADMIN_ROUTE = "/admin/mocks/grant-access";
const DEV_TEST_ROUTE = "/admin/dev-test";
const DOWNLOAD_FEEDBACKS_ADMIN_ROUTE = "/admin/mocks/download-feedbacks";
const USER_PERFORMANCE_ADMIN_ROUTE = "/admin/mocks/user-performance";
const USER_IELTS_GRADES_LIST_ADMIN_ROUTE = "/admin/mocks/user-grades/mocks/";
const USER_IELTS_GRADES_ADMIN_ROUTE = `/admin/mocks/user-grades/mocks/:userId/`;
const USER_DELETE_MOCK_RESPONSES_ADMIN_ROUTE =
  "/admin/mocks/delete-user-responses";

const COOKIES_DOMAIN =
  env === "production" ? "smartestprep.com" : "dev-smartestprep.com";

export {
  DASHBOARD_ROUTE,
  CATALOG_ROUTE,
  FAVORITES_ROUTE,
  RESOURCES_ROUTE,
  BLOG_ROUTE,
  BLOG_ARTICLE_ROUTE,
  APPLICATION_ROUTE,
  LEARNING_PLATFORM_ROUTE,
  COURSE_ROUTE,
  COURSE_SECTION_ROUTE,
  COURSE_LESSON_ROUTE,
  COURSE_LESSON_TEST_ROUTE,
  COURSE_INFO_ROUTE,
  COURSE_NOTES_ROUTE,
  COURSE_RANKING_ROUTE,
  SETTINGS_ROUTE,
  PROFILE_ROUTE,
  STP_OPEN_SIDE,
  RECOMMENDATIONS_ROUTE,
  ESSAY_ROUTE,
  MORE_ROUTE,
  PROFILE_MOBILE,
  LANGUAGE_MOBILE,
  KASPI_PAYMENT_ROUTE,
  // RECOMMENDATION_LETTTER_ROUTE,
  // UNIVERSITY_VISITS_ROUTE,
  // ASSESMENTS_ROUTE,
  COURSE_GRADES_ROUTE,
  COURSE_ONBOARDING_ROUTE,
  WORKSHOP_ROUTE,
  WORKSHOP_MAIN_ROUTE,
  SCHOLARSHIPS_ROUTE,
  SCHOLARSHIPS_PROFILE_ROUTE,
  MARATHON_ADMIN,
  MOCK_EXAMS,
  MOCK_EXAM,
  MOCK_EXAM_ONGOING,
  COOKIES_DOMAIN,
  CHALLENGES_ROUTE,
  CHALLENGES_ROUTE_ONGOING,
  MOCK_EXAMS_ADMIN_ROUTE,
  GRADES_ADMIN_ROUTE,
  MOCK_ACCESS_ADMIN_ROUTE,
  DEV_TEST_ROUTE,
  DOWNLOAD_FEEDBACKS_ADMIN_ROUTE,
  USER_PERFORMANCE_ADMIN_ROUTE,
  COURSE_REVIEWS_ROUTE,
  USER_IELTS_GRADES_LIST_ADMIN_ROUTE,
  USER_IELTS_GRADES_ADMIN_ROUTE,
  USER_DELETE_MOCK_RESPONSES_ADMIN_ROUTE,
};
