import { Button, NewModalLayout, Icon, PageTitle } from "@components/common";
import { UploadProfilePhoto, Education } from "@components/feature";
import { useTranslate, useIsMobile } from "@helpers/hooks";
import { Profile } from "../Profile";
import "./ProfileEdit.scss";

export default function ProfileEdit({ handleClose }) {
  const { t } = useTranslate();
  const isMobile = useIsMobile();

  return (
    <NewModalLayout
      className={`${isMobile ? `profile-edit__pop-up-mobile` : `pop-up profile-edit__pop-up`}`}
    >
      <>
        <div className="profile-edit__header">
          <PageTitle title={t("PROFILE.EDIT_PROFILE")} iconLeft={"arrow_title"} handleLeftClick={handleClose} />
          {!isMobile && (
            <Icon name="close-rounded" className="profile-edit__icon" handleClick={handleClose} />
          )}
        </div>
        <div className={isMobile ? "profile-edit__group-mobile" : "profile-edit__group"}>
          <UploadProfilePhoto />
          <div className={isMobile ? "profile-edit__inputs-mobile" : "profile-edit__inputs"}>
            <Profile />
            <Education />
          </div>
        </div>
      </>
      <Button
        text={t("BUTTON.SAVE")}
        className="btn--secondary btn--pill btn__profile"
        onClick={handleClose}
      />
    </NewModalLayout>
  );
}
