import React, { useEffect, useState, memo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useIsMobile, useWindowSize } from "@helpers/hooks";
import {
  HeaderLogo,
  HeaderMenu,
  HeaderBurger,
  LmsBadgeHeader,
} from "@components/feature";
import { Image } from "@components/common";
import "./Header.scss";
import "./Header.scss";

export default memo(function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false),
    { profile } = useSelector((state) => state.profile),
    { pathname } = useLocation(),
    isMobile = useIsMobile(),
    windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize.width > 1023) {
      setIsMenuOpen(false);
    }
  }, [windowSize]);

  if (!pathname.includes("/courses") && isMobile) {
    return <></>;
  }

  return (
    <header className={"header__main"}>
      <nav className={"header__nav"}>
        {isMobile ? (
          <>
            {profile.profile_photo ? (
              <Image src={profile.profile_photo} className="header__photo" />
            ) : (
              <div className="header__photo">
                <p className="header__photo--placeholder">
                  {profile.name && profile.name[0]}
                  {profile.surname && profile.surname[0]}
                </p>
              </div>
            )}
            {pathname.includes("courses/") && <LmsBadgeHeader />}
            {windowSize.width < 1023 ? (
              <HeaderBurger
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
              />
            ) : (
              <HeaderMenu />
            )}
          </>
        ) : (
          <div className="header__tablet">
            <HeaderLogo setIsMenuOpen={setIsMenuOpen} />
            {windowSize.width < 1023 ? (
              <>
                {pathname.includes("courses/") && <LmsBadgeHeader />}
                <HeaderBurger
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                />
              </>
            ) : (
              <HeaderMenu />
            )}
          </div>
        )}
      </nav>
    </header>
  );
});
