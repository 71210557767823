import React, { useState, useEffect } from "react";
import { Button, NewModalLayout } from "@components/common";
import { useIsMobile, useTranslate } from "@helpers/hooks";
import { useSelector } from "react-redux";

export default function MockWarningModal({ handleSubmit, closeModal }) {
  const isMobile = useIsMobile();
  const { t } = useTranslate();
  const { stage } = useSelector((state) => state.mocks);
  // We cannot use isLoading from the store, because it toggles multiple times due to
  // multiple requests sent during handle submit, so use isSubmitting
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClick = () => {
    setIsSubmitting(true);
  };

  useEffect(() => {
    setIsSubmitting(false);
  }, [stage])

  useEffect(() => {
    if (isSubmitting) {
      // This can fail, if auto submission starts happening at the same time and is processed by the server first.
      // That's why we don't display any messages and simply close modal regardless of the result,
      // hoping that even if this fails, the auto submission will succeed.
      handleSubmit().finally(() => {
        setIsSubmitting(false);
        closeModal();
      })
    }
  }, [isSubmitting]);

  return (
    <NewModalLayout
      className={`pop-up ${
        isMobile ? `tariff__pop-up-mobile` : `tariff__pop-up`
      }`}
    >
      <div className="tariff__title-button">
        <h3 className="">{t("MOCK.LATER_TITLE")}</h3>
      </div>

      <p>{t("MOCK.LATER_WARNING")}</p>

      <div className="popup__buttons">
        <Button
          className="button-secondary popup__button"
          text={t("MOCK.CANCEL")}
          onClick={closeModal}
          disabled={isSubmitting}
        />

        <Button
          className="button-primary popup__button"
          text={t("MOCK.SUBMIT_ANYWAY")}
          onClick={handleClick}
          loading={isSubmitting}
          disabled={isSubmitting}
        />
      </div>
    </NewModalLayout>
  );
}
