import { AdminService } from "@api/services";
import { createAsyncThunk } from "@reduxjs/toolkit";

const postUserPerformance = createAsyncThunk(
  "userPerformance/post",
  async (data, thunkAPI) => {
    try {
      const res = await AdminService.postUserPerformance(data);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const getUsers = createAsyncThunk(
  "users/fetchAll",
  async (params, thunkAPI) => {
    try {
      const res = await AdminService.getUsers(params);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const getUser = createAsyncThunk(
  "users/fetchOne",
  async (id, thunkAPI) => {
    try {
      const res = await AdminService.getUser(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const getUsersIeltsGrades = createAsyncThunk(
  "usersIeltsGrades/fetchAll",
  async (id, thunkAPI) => {
    try {
      const res = await AdminService.getUsersIeltsGrades(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const getUsersNuetGrades = createAsyncThunk(
  "usersNuetGrades/fetchAll",
  async (id, thunkAPI) => {
    try {
      const res = await AdminService.getUsersNuetGrades(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const getUsersSatGrades = createAsyncThunk(
  "usersSatGrades/fetchAll",
  async (id, thunkAPI) => {
    try {
      const res = await AdminService.getUsersSatGrades(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const setUsersByPageChange = createAsyncThunk(
  "users/pageChange",
  async (currentPage, thunkAPI) => {
    try {
      return currentPage;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const setUsersByPageSizeChange = createAsyncThunk(
  "users/pageSizeChange",
  async (pageSize, thunkAPI) => {
    try {
      return pageSize;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export {
  postUserPerformance,
  getUsers,
  getUser,
  getUsersIeltsGrades,
  getUsersNuetGrades,
  getUsersSatGrades,
  setUsersByPageChange,
  setUsersByPageSizeChange,
};
