import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  isAdminMode: !!localStorage.getItem("admin_mode")
};

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setAdminMode: (state, action) => {
      state.isAdminMode = action.payload;
      if (action.payload) {
        localStorage.setItem("admin_mode", true);
      } else {
        localStorage.removeItem("admin_mode");
      }
    }
  }
});

export const { setAdminMode } = clientSlice.actions;

export default clientSlice.reducer;
