import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAction } from "@helpers/hooks";
import Cookies from "js-cookie";
import {
  ACCESS_LANG,
  ACCESS_LOCALE,
  ACCESS_REDIRECT,
  ACCESS_TOKEN,
  CATALOG_ROUTE,
  PROFILE_ROUTE,
  STP_OPEN_SIDE,
  COOKIES_DOMAIN,
} from "@utils/consts";
import { AppLoader } from "@components/common";

export default function WithAuth({ children }) {
  const { pathname } = useLocation();
  const { profile } = useSelector((state) => state.profile);
  const { fetchProfile } = useAction();

  const isLogged = useMemo(() => !!Cookies.get(ACCESS_TOKEN), []);
  const [isAuth, setIsAuth] = useState(isLogged);

  useEffect(() => {
    const locationArr = pathname.split("/");
    const findTokenIndex = locationArr.findIndex(
      (item) => item === ACCESS_TOKEN
    );
    const findRedirectIndex = locationArr.findIndex(
      (item) => item === ACCESS_REDIRECT
    );
    const findLocaleIndex = locationArr.findIndex(
      (item) => item === ACCESS_LOCALE
    );

    if (locationArr.includes(ACCESS_TOKEN)) {
      const accessToken = locationArr[findTokenIndex + 1];
      const accessLang = locationArr[findLocaleIndex + 1];
      const accessRedirect = locationArr[findRedirectIndex + 1];

      Cookies.set(ACCESS_TOKEN, accessToken, {
        domain: COOKIES_DOMAIN,
        secure: true,
        sameSite: "Lax",
        expires: 7,
      });

      Cookies.set(ACCESS_LANG, accessLang, {
        domain: COOKIES_DOMAIN,
        secure: true,
        sameSite: "Lax",
        expires: 7,
      });

      setIsAuth(true);

      if (accessRedirect === "true") {
        window.location.href = CATALOG_ROUTE;
      } else {
        window.location.href = PROFILE_ROUTE;
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (isAuth) {
      try {
        fetchProfile();  // This requires ACCESS_TOKEN in Cookies
      } catch (e) {
        Cookies.remove(ACCESS_TOKEN, { domain: COOKIES_DOMAIN });
        window.location.href = `${STP_OPEN_SIDE}/auth`;
      }
    }
  }, [isAuth]);

  if (!isAuth) {
    Cookies.remove(ACCESS_TOKEN, { domain: COOKIES_DOMAIN });

    if (pathname !== "/") {
      window.location.href = `${STP_OPEN_SIDE}/auth?callbackUrl=${encodeURIComponent(
        pathname
      )}`;
    } else {
      window.location.href = `${STP_OPEN_SIDE}/auth`;
    }

    return null;
  }

  return profile ? children : <AppLoader />;
}
