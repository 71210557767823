import { useState, useRef, useEffect } from "react";
import { Icon, Label } from "@components/common";
import classNames from "classnames";
import "./Input.scss";

export default function Input(props) {
  const {
    className,
    label,
    type = "text",
    name,
    labelClassName,
    inputClassName,
    readOnly,
    symbol,
    validateRange,
    isTextArea,
    isLongText,
    definition,
    ...otherProps
  } = props;
  const [show, setShow] = useState(false);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (isTextArea && textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [otherProps.value]);

  return (
    <div
      className={classNames("input-wrapper", className, {
        "read-only": readOnly,
      })}
    >
      <Label
        className={labelClassName}
        htmlFor={name}
        label={label}
        definition={definition}
      />
      <div className={`input ${className}`}>
        {isTextArea ? (
          isLongText ? (
            <textarea
              ref={textareaRef}
              className={classNames(inputClassName)}
              name={name}
              rows={1}
              type={show ? "text" : type}
              readOnly={readOnly}
              {...otherProps}
            />
          ) : (
            <textarea
              className={classNames(inputClassName, "input__field")}
              name={name}
              type={show ? "text" : type}
              readOnly={readOnly}
              {...otherProps}
            />
          )
        ) : (
          <input
            className={classNames(inputClassName, "input__field")}
            name={name}
            type={show ? "text" : type}
            readOnly={readOnly}
            {...otherProps}
          />
        )}
        {validateRange && (
          <p className="input__range">
            {`${otherProps.min} - ${otherProps.max}`}
          </p>
        )}
        {symbol !== null && symbol > 0 && (
          <p className="input__symbol">{symbol}</p>
        )}
        {!readOnly && type === "password" && (
          <Icon
            className={"input__show icon--pointer"}
            name={show ? "hide" : "show"}
            handleClick={() => setShow((show) => !show)}
          />
        )}
      </div>
    </div>
  );
}
