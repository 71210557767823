import React from "react";
import "./CourseReviewsCard.scss";
import { Icon, Image } from "@components/common";

export default function CourseReviewsCard({ review }) {
  const {
    comment,
    rating,
    user_name,
    user_image,
    mock_title,
  } = review;
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <Icon
          key={i}
          name="rating_star"
          className={`star ${i <= rating ? "filled" : ""}`}
        />
      );
    }
    return stars;
  };

  return (
    <div className="reviews__card">
      <div className="reviews__start">
        <div className="reviews__stars">{renderStars(rating)}</div>
        <p className="reviews__comment">{comment}</p>
      </div>
      <div className="reviews__user">
        <Image
          className="reviews__user-image"
          src={user_image}
          alt={user_name}
        />
        <div className="reviews__user-info">
          <p className="reviews__user-name">{user_name}</p>
          {mock_title && <p className="reviews__user-email">{mock_title}</p>}
        </div>
      </div>
    </div>
  );
}
