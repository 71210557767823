// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coins {
  display: flex;
  align-items: center;
  gap: 8px;
}
.coins__icon > svg {
  width: 18px;
  height: 18px;
}
.coins__img {
  height: 26px;
}
.coins__text {
  font-size: 14px;
  font-weight: 500;
}
.coins__title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.coins__modal {
  width: 800px;
  border-radius: 24px !important;
  max-height: 80vh;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Header/HeaderCoins/HeaderCoins.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACF;AAEI;EACE,WAAA;EACA,YAAA;AAAN;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,eAAA;EACA,gBAAA;AAHJ;AAME;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAJJ;AAOE;EACE,YAAA;EACA,8BAAA;EACA,gBAAA;EACA,cAAA;AALJ","sourcesContent":[".coins {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n\n  &__icon {\n    & > svg {\n      width: 18px;\n      height: 18px;\n    }\n  }\n\n  &__img {\n    height: 26px;\n  }\n\n  &__text {\n    font-size: 14px;\n    font-weight: 500;\n  }\n\n  &__title {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 20px;\n  }\n\n  &__modal {\n    width: 800px;\n    border-radius: 24px !important;\n    max-height: 80vh;\n    overflow: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
