import {
  MOCK_EXAMS_ADMIN_ROUTE,
  GRADES_ADMIN_ROUTE,
  MOCK_ACCESS_ADMIN_ROUTE,
  DOWNLOAD_FEEDBACKS_ADMIN_ROUTE,
  USER_PERFORMANCE_ADMIN_ROUTE,
  USER_IELTS_GRADES_LIST_ADMIN_ROUTE,
  USER_DELETE_MOCK_RESPONSES_ADMIN_ROUTE,
} from "@utils/consts";

const getAdminNavigationData = (t) => [
  {
    id: 18,
    route: MOCK_EXAMS_ADMIN_ROUTE,
    icon: "admin",
    name: t("ADMIN.MOCK_EXAMS"),
  },
  {
    id: 19,
    route: GRADES_ADMIN_ROUTE,
    icon: "admin",
    name: t("ADMIN.GRADES"),
  },
  {
    id: 20,
    route: MOCK_ACCESS_ADMIN_ROUTE,
    icon: "admin",
    name: t("ADMIN.MOCK_ACCESS"),
  },
  {
    id: 21,
    route: DOWNLOAD_FEEDBACKS_ADMIN_ROUTE,
    icon: "admin",
    name: t("ADMIN.DOWNLOAD_FEEDBACK"),
  },
  {
    id: 22,
    route: USER_PERFORMANCE_ADMIN_ROUTE,
    icon: "admin",
    name: t("ADMIN.USER_PERFORMANCE"),
  },
  {
    id: 23,
    route: USER_IELTS_GRADES_LIST_ADMIN_ROUTE,
    icon: "admin",
    name: t("ADMIN.USER_MOCK_GRADES"),
  },
  {
    id: 24,
    route: USER_DELETE_MOCK_RESPONSES_ADMIN_ROUTE,
    icon: "admin",
    name: t("ADMIN.USER_DELETE_MOCK_RESPONSES"),
  },
];

export default getAdminNavigationData;
