// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coin__history {
  display: flex;
}
.coin__history-list {
  border: 1px solid #eaecf0;
  border-radius: 12px;
  overflow-y: auto;
}
.coin__history-item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eaecf0;
}
.coin__history-item:last-child {
  border-bottom: none;
}
.coin__history-item > h4 {
  font-size: 16px;
  font-weight: 500;
  border-right: 1px solid #eaecf0;
  padding: 12px;
  width: 70%;
  line-height: normal;
}
.coin__history-item > p {
  padding: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Gamification/CoinHistory/CoinHistory.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;AADF;AAGE;EACE,yBAAA;EACA,mBAAA;EACA,gBAAA;AADJ;AAIE;EACE,aAAA;EACA,8BAAA;EACA,gCAAA;AAFJ;AAII;EACE,mBAAA;AAFN;AAKI;EACE,eAAA;EACA,gBAAA;EACA,+BAAA;EACA,aAAA;EACA,UAAA;EACA,mBAAA;AAHN;AAMI;EACE,aAAA;AAJN","sourcesContent":["@import \"@styles/variables\";\n\n.coin__history {\n  display: flex;\n\n  &-list {\n    border: 1px solid $gray_border;\n    border-radius: 12px;\n    overflow-y: auto;\n  }\n\n  &-item {\n    display: flex;\n    justify-content: space-between;\n    border-bottom: 1px solid $gray_border;\n\n    &:last-child {\n      border-bottom: none;\n    }\n\n    & > h4 {\n      font-size: 16px;\n      font-weight: 500;\n      border-right: 1px solid $gray_border;\n      padding: 12px;\n      width: 70%;\n      line-height: normal;\n    }\n\n    & > p {\n      padding: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
