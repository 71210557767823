import { DashboardProgram } from "./DashboardProgram";
import { DashboardSkeleton } from "./DashboardSkeleton";
import { DashboardList } from "./DashboardList";
import { DashboardChanceContent } from "./DashboardChanceContent";
import { DashboardFilters } from "./DashboardFilters";
import { DashboardGraphContent } from "./DashboardGraphContent";
import { DashboardChancesStats } from "./DashboardChancesStats";
import { DashboardStats } from "./DashboardStats";
import { DashboardAssessmentContent } from "./DashboardAssessmentContent";
import { DashboardIncreaseChances } from "./DashboardIncreaseChances";
import { DashboardBarsContent } from "./DashboardBarsContent";
import { DashboardAutocomplete } from "./DashboardAutocomplete";
import { DashboardBar } from "./DashboardBar";
import { DashboardMobile } from "./DashboardMobile";
import { DashboardProftest } from "./DashboardProftest";
import { DashboardCourses } from "./DashboardCourses";
import { DashboardCourseCard } from "./DashboardCourseCard";
import { DashboardContentV2 } from "./DashboardContentV2";
import { DashboardContentV1 } from "./DashboardContentV1";
import { DashboardWritingBuilder } from "./DashboardWritingBuilder";
import { DashboardProftestV2 } from "./DashboardProftestV2";
import { DashboardMockSecton } from "./DashboardMockSecton";
import { DashboardProfileInfoModal } from "./DashboardProfileInfoModal";
import SentryFeedback from "./SentryFeedback";
import { DashboardPerformance } from "./DashboardPerformance";
import { DashboardCollectInfoModal } from "./DashboardCollectInfoModal";
import UniAgentBanner from "./UniAgentBanner";

export {
  DashboardProgram,
  DashboardList,
  DashboardSkeleton,
  DashboardChanceContent,
  DashboardFilters,
  DashboardGraphContent,
  DashboardChancesStats,
  DashboardStats,
  DashboardAssessmentContent,
  DashboardIncreaseChances,
  DashboardBarsContent,
  DashboardBar,
  DashboardAutocomplete,
  DashboardMobile,
  DashboardProftest,
  DashboardCourses,
  DashboardCourseCard,
  DashboardContentV2,
  DashboardContentV1,
  DashboardWritingBuilder,
  DashboardProftestV2,
  DashboardMockSecton,
  DashboardProfileInfoModal,
  SentryFeedback,
  DashboardPerformance,
  DashboardCollectInfoModal,
  UniAgentBanner,
  
};
