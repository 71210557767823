import { useState, useEffect } from "react";
import {
  Button,
  FormItem,
  Icon,
  List,
  NewModalLayout,
} from "@components/common";
import { CourseReviewsCard } from "@components/feature";
import { useAction, useTranslate } from "@helpers/hooks";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { RatingQuestion } from "@components/feature/Feedback";
import "./CourseReviewsContent.scss";

export default function CourseReviewsContent() {
  const { t } = useTranslate();
  const { courseId } = useParams();
  const { fetchCourseReviews, createCourseReview, fetchCourse } = useAction();
  const { reviews, course } = useSelector((state) => state.courses);
  const [isCreateReviewModalOpen, setIsCreateReviewModalOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);

  const handleSubmitReview = () => {
    const reviewData = {
      comment,
      rating,
    };
    createCourseReview({ courseId: courseId, data: reviewData });
    setIsCreateReviewModalOpen(false);
  };

  const handleCreateReview = () => {
    setIsCreateReviewModalOpen(true);
  };

  useEffect(() => {
    fetchCourseReviews(courseId);
    fetchCourse(courseId);
  }, [courseId]);

  if (!reviews) {
    return null;
  }

  return (
    <div>
      <div className="reviews__header">
        <h1 className="grade__title">{t("LMS.REVIEW.TITLE")}</h1>
        {!reviews.is_sent && course?.enrolled && (
          <Button
            className="btn--primary btn--pill"
            onClick={handleCreateReview}
            text={t("LMS.REVIEW.CREATE")}
          />
        )}
      </div>
      <div>
        {reviews?.reviews?.length > 0 ? (
          <List
            className="reviews__list"
            items={reviews?.reviews}
            renderItem={(review) => (
              <CourseReviewsCard key={review.id} review={review} />
            )}
          />
        ) : (
          <div>No reviews yet</div>
        )}
      </div>
      {isCreateReviewModalOpen && (
        <NewModalLayout>
          <div className="reviews__modal-container">
            <div className="reviews__modal-header">
              <h1 className="reviews__modal-title">Create review</h1>
              <Icon
                name="close-rounded"
                handleClick={() => setIsCreateReviewModalOpen(false)}
              />
            </div>
            <div className="reviews__modal-content">
              <RatingQuestion userAnswer={rating} onAnswerChange={setRating} />
              <FormItem
                item={{
                  id: 2,
                  inputClassName: "reviews__modal-input",
                  placeholder: t("LMS.REVIEW.COMMENT"),
                  field: "input",
                  name: "comment",
                  value: comment,
                  type: "text",
                  isTextArea: true,
                }}
                handleChange={(e) => setComment(e.target.value)}
              />

              <Button
                className="btn--primary btn--pill"
                text={t("LMS.REVIEW.CREATE")}
                onClick={handleSubmitReview}
              />
            </div>
          </div>
        </NewModalLayout>
      )}
    </div>
  );
}
