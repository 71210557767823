import React from "react";
import "./DashboardMockSecton.scss";
import { useAction, useTranslate } from "@helpers/hooks";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Button, Icon, Image, List } from "@components/common";
import { useHistory } from "react-router-dom";
import { MOCK_EXAMS } from "@utils/consts";

export default function DashboardMockSecton() {
  const { fetchMockExams } = useAction();
  const { mocks } = useSelector((state) => state.mocks);

  const history = useHistory();


  useEffect(() => {
    fetchMockExams();
  }, []);

  const handleRedirect = () => {
    history.push(MOCK_EXAMS);
  };

  const hasCompletedMocks = mocks?.some(
    (mock) =>
      (mock.nuet_mock_exam && mock.nuet_mock_exam.completed) ||
      (mock.sat_mock_exam && mock.sat_mock_exam.completed) ||
      (mock.ielts_mock_exam && mock.ielts_mock_exam.completed)
  );

  const uniqueTypeMocks = [
    mocks?.find((mock) => mock.nuet_mock_exam),
    mocks?.find((mock) => mock.sat_mock_exam),
    mocks?.find((mock) => mock.ielts_mock_exam),
  ].filter(Boolean);

  const { t } = useTranslate();

  const getDisplayType = (mock) => {
    return mock.type ? mock.type.replace("_mock", "") : "";
  };

  const completedMocks = mocks?.filter(
    (mock) =>
      (mock.nuet_mock_exam && mock.nuet_mock_exam.completed) ||
      (mock.sat_mock_exam && mock.sat_mock_exam.completed) ||
      (mock.ielts_mock_exam && mock.ielts_mock_exam.completed)
  );

  const getMaxScore = (mock) => {
    if (mock.nuet_mock_exam) return 240;
    if (mock.sat_mock_exam) return 1600;
    if (mock.ielts_mock_exam) return 9.0;
    return null;
  };

  const completedMockDetails = completedMocks
    .map((mock) => {
      let score = null;
      if (mock.nuet_mock_exam && mock.nuet_mock_exam.completed) {
        score = mock.nuet_mock_exam.score;
      } else if (mock.sat_mock_exam && mock.sat_mock_exam.completed) {
        score = mock.sat_mock_exam.score;
      } else if (mock.ielts_mock_exam && mock.ielts_mock_exam.completed) {
        score = mock.ielts_mock_exam.score;
      }
      const maxScore = getMaxScore(mock);
      return score !== null ? { title: mock.title, score, maxScore } : null;
    })
    .filter((detail) => detail !== null);

  return (
    <div className="dashboard__mock">
      <h2 className="dashboard__subheading">{t("MOCK.TITLE")}</h2>
      {completedMockDetails.length > 0 ? (
        <ul className="dashboard__mock-list">
          {completedMockDetails.map((detail, index) => (
            <div
              key={detail.title}
              className="dashboard__mock-list-item"
              onClick={() => handleRedirect()}
            >
              <div className="dashboard__mock-list-item-details">
                <p className="dashboard__mock-list-item-title">{detail.title}</p>
                <h5 className="dashboard__mock-list-item-score">
                  {detail.score} / {detail.maxScore}
                </h5>
              </div>
              <Icon name="pagination-arrow-right" />
            </div>
          ))}
        </ul>
      ) : (
        <div className="dashboard__mock-title">
          <div className="dashboard__mock-placeholder-container">
            <p className="dashboard__mock-placeholder-message">
              {!hasCompletedMocks
                ? t("DASHBOARD.MOCK.MESSAGE_ONE")
                : t("DASHBOARD.MOCK.MESSAGE_TWO")}
            </p>
          </div>

          <List
            className="dashboard__mock-items"
            items={uniqueTypeMocks}
            renderItem={(item) => {
              const displayType = getDisplayType(item);
              return (
                <div
                  key={item.id}
                  className="dashboard__mock-item"
                  onClick={() => handleRedirect()}
                >
                  <>
                    <p className="dashboard__mock-item-type">{displayType}</p>
                    <h5 className="dashboard__mock-item-title">{item.title}</h5>
                    <Button
                      className="dashboard__mock-item-button"
                      text={t("DASHBOARD.MOCK.START")}
                    ></Button>
                  </>
                </div>
              );
            }}
          />

          <Image
            name="exams_placeholder.png"
            className="dashboard__mock-placeholder"
          />
        </div>
      )}
    </div>
  );
}
