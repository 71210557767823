import { useMemo, useState } from "react";
import { useAction, useTranslate, useIsMobile } from "@helpers/hooks";
import { useSelector } from "react-redux";
import { CURRENT_STUDY_YEARS, STUDY_LEVELS } from "@utils/consts";
import { debounce } from "@utils/functions";
import { FormItem, List } from "@components/common";
import "./Education.scss";

export default function Education({}) {
  const isMobile = useIsMobile();
  const { t } = useTranslate();
  const { myEducation } = useSelector((state) => state.questionnaire);
  const { updateEducation } = useAction();
  const initialValues = useMemo(() => ({
    study_level: myEducation.study_level,
    study_place: myEducation.study_place,
    current_study_year: myEducation.current_study_year,
  }), [myEducation]);
  // NOTE: values will not be updated when myEducation is updated, because useMemo only affects initialValues, but not values
  // TODO: Refactor this
  const [values, setValues] = useState(initialValues);
  // TODO: Set default values for study_level and current_study_year
  const EDUCATION_ITEMS = useMemo(() => [
    {
      id: 1,
      label: t("PROFILE.EDUCATIONS.CURRENT_STUDY_LEVEL_SHORT"),
      placeholder: "FIELD.NOT_SELECTED",
      field: "dropdown",
      name: "study_level",
      // defaultValue: {
      //   // TODO: Refactor this, unsafe code, e.g. al_studies does not map to DIFFERENT
      //   name: t(`EDUCATIONS_STATUS.${values.study_level.toUpperCase()}`),
      //   value: values.study_level,
      // },
      value: values.study_level,
      options: STUDY_LEVELS(t),
    },
    {
      id: 2,
      label: t("PROFILE.EDUCATIONS.STUDY_PLACE_SHORT"),
      placeholder: "DASHBOARD.POPUP.LABEL_3",
      field: "input",
      name: "study_place",
      type: "text",
      value: values.study_place,
    },
    {
      id: 3,
      label: t("PROFILE.EDUCATIONS.CURRENT_STUDY_YEAR_SHORT"),
      placeholder: "1-12",
      field: "dropdown",
      name: "current_study_year",
      value: values.current_study_year,
      options: CURRENT_STUDY_YEARS,
    },
    ], [values, t]);

  // TODO: Remove handling by debounce, save only when user presses save button
  const handleDebounce = useMemo(
    () =>
      debounce(async (data) => {
        await updateEducation(data);
        // eslint-disable-next-line
      }),
    []
  );

  const handleChange = async (e, field, name) => {
    if (name === "study_place") {
      setValues((prev) => ({ ...prev, [name]: e.target.value }));
      await handleDebounce({ [name]: e.target.value });
      return;
    }
    if (field === "dropdown") {
      setValues((prev) => ({ ...prev, [name]: e.value }));
      await handleDebounce({ [name]: e.value });
      return;
    }
  };

  return (
    <div className={"education"}>
      <List
        className={isMobile ? "education__list-mobile" : "education__list"}
        items={EDUCATION_ITEMS}
        renderItem={(item) => (
          <FormItem key={item.id} item={item} handleChange={handleChange} />
        )}
      />
    </div>
  );
}
