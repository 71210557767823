import { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { useAction, useTranslate } from "@helpers/hooks";
import { Divider, Icon, List, NewFeature } from "@components/common";
import {
  getNavigationData,
  isNewFeature,
  trackAmplitude,
} from "@utils/functions";
import classNames from "classnames";
import { FixedBanner } from "@components/feature";
import getAdminNavigationData from "@utils/consts/getAdminNavigationData";
import { matchPath } from "react-router-dom/cjs/react-router-dom";
import "./Sidebar.scss";

export default function Sidebar() {
  const [openAccordions, setOpenAccordions] = useState({});
  const { t } = useTranslate(),
    { pathname } = useLocation(),
    { totalCountFavorites } = useSelector((state) => state.program),
    { profile } = useSelector((state) => state.profile),
    { fetchFavoritesTotalCount, fetchApplicationsTotalCount } = useAction();
  const prevIsAdminModeRef = useRef();
  const history = useHistory();
  const isTeacher = profile?.is_teacher;
  const { isAdminMode } = useSelector((state) => state.client);

  const navRoutes = useMemo(() => {
    return isAdminMode && isTeacher
      ? getAdminNavigationData(t)
      : getNavigationData(t, totalCountFavorites, isNewFeature);
  }, [isAdminMode, t, totalCountFavorites, isTeacher]);

  const isRouteActive = (route) => {
    const match = matchPath(pathname, {
      path: route,
      exact: true,
      strict: false,
    });
    return match !== null;
  };

  const handleSidebarItemClick = (item) => {
    trackAmplitude("sidebar_item_click", {
      description: "User clicked on a menu item",
      item_name: item.name,
      item_route: item.route,
    });
  };

  useEffect(() => {
    fetchFavoritesTotalCount({ is_shortlisted: true });
    fetchApplicationsTotalCount({ is_applying: true });
  }, []);

  useEffect(() => {
    if (
      prevIsAdminModeRef.current !== undefined &&
      prevIsAdminModeRef.current !== isAdminMode
    ) {
      if (navRoutes.length > 0) {
        history.push(navRoutes[0].route);
      }
    }
    prevIsAdminModeRef.current = isAdminMode;
  }, [isAdminMode, navRoutes]);

  return (
    <aside className={"sidebar__wrapper"}>
      <div className={"sidebar"}>
        <nav className={"sidebar__nav"}>
          <List
            className={"sidebar__list"}
            items={navRoutes}
            renderItem={(item) => {
              const { id, route, icon, name, isNew, isDivider, routes } = item;

              if (routes && routes.length > 0) {
                // Item is an accordion
                const isOpen = openAccordions[id];
                const isAnyNestedRouteActive = routes.some((nestedItem) =>
                  isRouteActive(nestedItem.route)
                );
                const shouldOpen = isOpen || isAnyNestedRouteActive;

                const handleAccordionClick = () => {
                  setOpenAccordions((prev) => ({
                    ...prev,
                    [id]: !prev[id],
                  }));
                };

                return (
                  <li key={id} className={"sidebar__item"}>
                    <div
                      className="sidebar__accordion-header"
                      onClick={handleAccordionClick}
                    >
                      <div className="sidebar__accordion-start">
                        {icon && (
                          <div className="sidebar__icon">
                            <Icon name={icon} />
                          </div>
                        )}
                        <p
                          className={classNames("sidebar__text", {
                            "sidebar__text--selected": shouldOpen,
                          })}
                        >
                          {name}
                        </p>
                      </div>
                      <div className="sidebar__accordion-arrow">
                        <Icon name={shouldOpen ? "arrow-up" : "arrow-down"} />
                      </div>
                    </div>
                    {shouldOpen && (
                      <ul className="sidebar__accordion-content">
                        {routes.map((nestedItem) => {
                          const {
                            id: nestedId,
                            route: nestedRoute,
                            icon: nestedIcon,
                            name: nestedName,
                            isNew: nestedIsNew,
                            isDivider: nestedIsDivider,
                          } = nestedItem;
                          const shouldHighlight = isRouteActive(nestedRoute);
                          return (
                            <li key={nestedId} className={"sidebar__item"}>
                              <NavLink
                                to={nestedRoute}
                                className={`sidebar__link ${nestedIcon}`}
                                activeClassName={classNames({
                                  "sidebar__link--selected": shouldHighlight,
                                })}
                                onClick={() =>
                                  handleSidebarItemClick(nestedItem)
                                }
                              >
                                <p
                                  className={classNames("sidebar__text", {
                                    "sidebar__text--selected": shouldHighlight,
                                  })}
                                >
                                  {nestedName}
                                </p>
                                {nestedIsNew && (
                                  <NewFeature isSelected={shouldHighlight} />
                                )}
                              </NavLink>
                              {nestedIsDivider && (
                                <Divider className={"sidebar__divider"} />
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                    {isDivider && <Divider className={"sidebar__divider"} />}
                  </li>
                );
              } else {
                // Regular link
                const shouldHighlight = isRouteActive(route);
                return (
                  <li key={id} className={"sidebar__item"}>
                    <NavLink
                      to={route}
                      className={`sidebar__link ${icon}`}
                      activeClassName={classNames({
                        "sidebar__link--selected": shouldHighlight,
                      })}
                      onClick={() => handleSidebarItemClick(item)}
                    >
                      <div className="sidebar__icon">
                        <Icon name={icon} />
                      </div>
                      <p
                        className={classNames("sidebar__text", {
                          "sidebar__text--selected": shouldHighlight,
                        })}
                      >
                        {name}
                      </p>
                      {isNew && <NewFeature isSelected={shouldHighlight} />}
                    </NavLink>
                    {isDivider && <Divider className={"sidebar__divider"} />}
                  </li>
                );
              }
            }}
          />
        </nav>
      </div>
      <FixedBanner />
    </aside>
  );
}
