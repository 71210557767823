import {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {useAction, useTranslate, useIsMobile} from "@helpers/hooks";
import {debounce} from "@utils/functions";
import { FormItem, List} from "@components/common";
import "./Profile.scss";


export default function Profile({}) {
    const isMobile = useIsMobile();
    const { profile } = useSelector((state) => state.profile);
    const { updateProfile } = useAction();
    const {t} = useTranslate();
    const initialValues = useMemo(() => ({
      name: profile.name,
      surname: profile.surname,
    }), [profile]);
    const [values, setValues] = useState(initialValues);
    const PROFILE_ITEMS = useMemo(() => [
      {
      id: 1,
      label: "PROFILE.NAME",
      placeholder: "Name",
      field: "input",
      name: "name",
      type: "text",
      value: values.name,
      },

      {
      id: 2,
      label: "PROFILE.SURNAME",
      placeholder: "Surname",
      field: "input",
      name: "surname",
      type: "text",
      value: values.surname,
      },
    ], [values, t]);

    // TODO: Remove handling by debounce, save only when user presses save button
    const handleDebounce = useMemo(() => debounce(async (data) => {
      // await updateEducation({id: myEducation.id, data});
      await updateProfile(data);
      // eslint-disable-next-line
    }), []);

    // TODO: Refactor this by either making it simpler or using useFormik
    const handleChange = async (e, field, name) => {
      let inputValue;
    
      if (name === 'name' || name === 'surname') {
          if (e.target) {
              inputValue = e.target.value;
              // Regular expression for validating name and surname
              const validNameRegex = /^[A-Za-z-]+$/;
              if (inputValue === "" || validNameRegex.test(inputValue)) {
                  setValues(prev => ({ ...prev, [name]: inputValue }));
                  await handleDebounce({ [name]: inputValue });
              }
          }
      }
    };
  

    return (
        <div className={"profile"}>
            <List
                className={isMobile ? "profile__list-mobile" :"profile__list"}
                items={PROFILE_ITEMS}
                renderItem={(item) => (
                    <FormItem
                        key={item.id}
                        item={item}
                        handleChange={handleChange}
                    />
                )}
            />
        </div>
    );
};