import { useEffect, useState } from "react";
import { Icon } from "@components/common";
import { useAction } from "@helpers/hooks";
import { useSelector } from "react-redux";
import "./HeaderCoins.scss";

export default function HeaderCoins() {
  const { fetchCoins } = useAction();
  const { coins } = useSelector((state) => state.coins);


  useEffect(() => {
    fetchCoins();
  }, []);

  return (
    <>
      <div className="coins">
        <Icon className="coins__icon" name="coin-menu" />
        <p className="coins__text">{coins || 0}</p>
      </div>
    </>
  );
}
