import * as yup from "yup";
import { STUDY_LEVELS, GENDERS, GRADUATION_YEARS, CURRENT_STUDY_YEARS } from "@utils/consts";

const collectInfoSchema = (t) => yup.object({
    name: yup.string()
        .required(t("VALIDATION.EMPTY_FIELD_ERROR")),
    surname: yup.string()
        .required(t("VALIDATION.EMPTY_FIELD_ERROR")),
    gender: yup.string()
        .oneOf(GENDERS(t).map(g => g.value), t("VALIDATION.INVALID_VALUE"))
        .required(t("VALIDATION.EMPTY_FIELD_ERROR")),
    study_level: yup.string()
        .oneOf(STUDY_LEVELS(t).map(s => s.value), t("VALIDATION.INVALID_VALUE"))
        .required(t("VALIDATION.EMPTY_FIELD_ERROR")),
    study_place: yup.string().required(t("VALIDATION.EMPTY_FIELD_ERROR")),
    current_study_year: yup.number().oneOf(CURRENT_STUDY_YEARS.map(c => c.value), t("VALIDATION.INVALID_VALUE")).required(t("VALIDATION.EMPTY_FIELD_ERROR")),
    graduation_year: yup.number().oneOf(GRADUATION_YEARS.map(g => g.value), t("VALIDATION.INVALID_VALUE")).required(t("VALIDATION.EMPTY_FIELD_ERROR")),
    // sat_math_score: yup.number().min(0, `${t("VALIDATION.MIN_VALUE")}: 0`).max(800, `${t("VALIDATION.MAX_VALUE")}: 800`),
    // sat_verbal_score: yup.number().min(0, `${t("VALIDATION.MIN_VALUE")}: 0`).max(800, `${t("VALIDATION.MAX_VALUE")}: 800`),
    // ielts_score: yup.number().oneOf(IELTS_SCORES.map(i => i.value), t("VALIDATION.INVALID_VALUE")),
});

export default collectInfoSchema;
