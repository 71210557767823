import React from 'react'
import { Button, Image, NewModalLayout } from '@components/common'
import { useTranslate } from '@helpers/hooks'

export default function MockTimeUpModal({handleCloseTimeUpBanner, setIsTimeUpBannerOpen}) {

    const {t} = useTranslate();

  return (
    <NewModalLayout>
          <div className="mock__time-banner">
            <Image className="mock__time-image" name="time_up.png" />
            <div className="mock__time-content">
              <h3>{t("MOCK.TIME_UP_TITLE")}</h3>
              <p>{t("MOCK.TIME_UP_TEXT")}</p>
            </div>
            <div className="mock__time-buttons">
              <Button
                className="btn--primary btn--pill"
                text={t("MOCK.TIME_UP_SUBMIT")}
                onClick={handleCloseTimeUpBanner}
              />
              <Button
                className="btn--border btn--pill"
                text={t("MOCK.TIME_UP_CONTINUE")}
                onClick={() => setIsTimeUpBannerOpen(false)}
              />
            </div>
          </div>
        </NewModalLayout>
  )
}
