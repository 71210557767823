const getAvailableTabs = (courses, TABS) => {
  const availableTabs = [TABS.MY, TABS.ALL];

  courses.forEach((course) => {
    switch (course.type) {
      case "ielts":
        if (!availableTabs.includes(TABS.IELTS)) availableTabs.push(TABS.IELTS);
        break;
      case "sat":
        if (!availableTabs.includes(TABS.SAT)) availableTabs.push(TABS.SAT);
        break;
      case "nuet":
        if (!availableTabs.includes(TABS.NUET)) availableTabs.push(TABS.NUET);
        break;
      case "english":
        if (!availableTabs.includes(TABS.ENGLISH))
          availableTabs.push(TABS.ENGLISH);
        break;
      case "prof_orient":
        if (!availableTabs.includes(TABS.PROF_ORIENT))
          availableTabs.push(TABS.PROF_ORIENT);
        break;
      case "univer_admission":
        if (!availableTabs.includes(TABS.UNIVER_ADMISSION))
          availableTabs.push(TABS.UNIVER_ADMISSION);
        break;
      default:
        break;
    }
  });

  return availableTabs;
};

export default getAvailableTabs;
