import { createSlice } from "@reduxjs/toolkit";
import { fetchStudentProfile } from "@store/actions/profileAction";

const initialState = {
  isLoading: false,
  error: null,
  studentProfile: null,
};

export const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchStudentProfile.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchStudentProfile.fulfilled.type]: (state, action) => {
      state.error = null;
      state.studentProfile = action.payload;
    },
    [fetchStudentProfile.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default studentSlice.reducer;
