import React, { useState, useEffect } from "react";
import { Icon } from "@components/common";
import { useIsMobile } from "@helpers/hooks";
import "./MockNavNumbers.scss";

export default function MockNavNumbers({
  questionsCount,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  isQuestionAnswered,
  stage,
  isFinished,
  markedForLater,
}) {
  const isMobile = useIsMobile();

  const [currentPage, setCurrentPage] = useState(0);
  const questionsPerPage = isMobile ? 7 : 10;

  const totalPages = Math.ceil(questionsCount / questionsPerPage);
  const isLastPage = currentPage === totalPages - 1;
  const isFirstPage = currentPage === 0;

  const startIndex = currentPage * questionsPerPage;
  const endIndex = startIndex + questionsPerPage;
  const visibleQuestions = Array.from({ length: questionsCount })
    .slice(startIndex, endIndex)
    .map((_, index) => (
      <div key={startIndex + index} className="mock__nav-box">
        <button
          className={`mock__nav-button ${
            startIndex + index === currentQuestionIndex ? "active" : ""
          } ${isQuestionAnswered(startIndex + index) ? "answered" : ""}`}
          onClick={() => setCurrentQuestionIndex(startIndex + index)}
        >
          {startIndex + index + 1}
        </button>
        {!isFinished && markedForLater[stage] && markedForLater[stage][startIndex + index] && (
          <div className="mock__nav-circle"></div>
        )}
      </div>
    ));

  useEffect(() => {
    const newPage = Math.floor(currentQuestionIndex / questionsPerPage);
    setCurrentPage(newPage);
  }, [currentQuestionIndex, questionsPerPage]);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  return (
    <div className="mock__numbers">
      <div className="mock__numbers-container">
        <Icon
          className={`icon__prev ${isFirstPage ? "disabled" : "mock__numbers-button "}`}
          name={"arrow_next"}
          handleClick={goToPreviousPage}
        />
        <div className="mock__nav-numbers">{visibleQuestions}</div>
        <Icon
          className={`${isLastPage ? "disabled" : "mock__numbers-button "}`}
          name={"arrow_next"}
          handleClick={goToNextPage}
        />
      </div>
    </div>
  );
}
